import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core";
import {Helmet} from "react-helmet";
import {
    CROSSTOWN_TITLE,
    TRAFFIC_MAP_DESCRIPTION,
    TRAFFIC_MAP_KEYWORDS,
    TRAFFIC_MAP_TITLE,
    TRAFFIC_MAP_URL
} from "../config/settings";
import NavBar from "../components/tracker/NavBar";
import Iframe from "react-iframe";


const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    placeholder: {
        position: 'absolute',
        zIndex: 1000,
        height: 10,
        width: '100%'
    },
    thisNav: {
        padding: 100,
    }
});

const TrafficMapContainer = (props) => {
    const {classes} = props;

    return (

        <div>
            <Helmet>
                <title>{TRAFFIC_MAP_TITLE}</title>
                <meta name="description" content={TRAFFIC_MAP_DESCRIPTION}/>
                <meta name="keywords" content={TRAFFIC_MAP_KEYWORDS}/>
                <meta property="og:title" content={TRAFFIC_MAP_TITLE}/>
                <meta property="og:site_name" content={CROSSTOWN_TITLE}/>
                <meta property="og:description" content={TRAFFIC_MAP_DESCRIPTION}/>
                <meta property="og:type" content="website"/>
                <meta property="og:url" content={TRAFFIC_MAP_URL}/>
                <meta property="og:image" content="%PUBLIC_URL%/coronavirus-map.png"/>
            </Helmet>

            <div className={classes.root}>

                <NavBar mode={'traffic-map'}/>

                <Iframe src={"https://adms.usc.edu/app/coronavirus?utm_source=crosstown&utm_medium=iframe&utm_campaign=coronatraffic"}
                        width={window.innerWidth}
                        height={window.innerHeight - 30}/>
            </div>
        </div>
    );
};

TrafficMapContainer.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TrafficMapContainer);