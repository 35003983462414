// A theme with custom primary and secondary color.
// It's optional.
import {createMuiTheme} from "@material-ui/core/styles";
import orange from "@material-ui/core/colors/orange";
import indigo from "@material-ui/core/colors/indigo";
import red from "@material-ui/core/colors/red";

const theme = createMuiTheme({
    palette: {
        type: 'light',
        primary: {
            light: orange[300],
            main: orange[500],
            dark: orange[700],
            contrastText: '#fff',
        },
        secondary: {
            light: indigo[300],
            main: indigo[500],
            dark: indigo[700],
        },
        error: red
    },
    typography: {
        useNextVariants: true,
        fontFamily: [
            'Roboto',
            'Arial',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            '"Helvetica Neue"',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },
});

export default theme;