import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import {withStyles} from "@material-ui/core/styles/index";
import Typography from '@material-ui/core/Typography';
import PubSub from "pubsub-js";
import {SELECTED_PLACE_SUGGESTION} from "../../config/settings";
import Log from "../../utils/Log";
import {IMAGE_PATH, API_URL} from "../constants";
const aqiRankColor = {
    'good':'#22cc99',
    'moderate':'#ffd63f',
    'usg':'#fd752b',
    'unhealthy':'#ff2727',
    'veryunhealthy':'#d3005c',
    'hazardous':'#8a0035',
}
const styles = theme => ({
    container:{
        [theme.breakpoints.up('md')]:{
            margin: '2rem 0rem',
        }
    },
    aqigood:{
        backgroundColor: '#22cc99',
    },
    aqimoderate:{
        backgroundColor: '#ffd63f',
    },
    rankNumber:{
        // color: '#FF9700',
        fontSize: '100px',
        fontFamily: '\'Poppins\' sans-serif',
        fontWeight: 'bold',
        width: '100%',
        height: 'auto',
        margin: '0 auto',
        position: 'relative'
    }, 
    good:{
        color:'red',
    },
    totalNumber:{
        fontSize: '30px',
        color:  '#d3d3d3',
        width: '100%',
        height: 'auto',
        margin: '0 auto',
        position: 'relative'
    },
    introContainer:{
        marginTop: '20px'
    },
    introRoot:{
        fontFamily: 'helvetica',
        fontSize: '14px',
        fontWeight: '400',
        color: '#383838',
        letterSpacing: '0.8px',
        lineHeight: '18px',
        marginBottom:'6px',
        whiteSpace: 'pre-wrap'
    }

});

class AQIRanking extends Component{

    constructor(props){
        super(props);
        this.state = {
            hasData: false,
            emptyData: false,
            areaName: null,
            center: null,
            aqi: null,
            datetime: null,
            default_place: false,
            aqistatus: null,
        }
    }

    componentDidMount(){
        PubSub.subscribe(SELECTED_PLACE_SUGGESTION, this.subscribe);
    }

    componentWillUnmount(){
        PubSub.clearAllSubscriptions();
    }

    _fetchAQIData() {
        const {center, default_place} = this.state;

        const lat = center[1];
        const long = center[0];
        // let date = new Date();
        // date.setFullYear( date.getFullYear() - 1 );
        // date = date.toISOString().split(".")[0];
        // let datetime = date.split("T")[0] + " " + date.split("T")[1];
        let sensors = default_place ? 5000 : 4; // total number of sensors available in the database

        let url = API_URL + '/api/air-quality/aqi?lat=' + lat +'&long=' + long + '&sensors=' + sensors;
        fetch(url)
            .then(response => response.json())
            .then(json => {
                let aqi = parseFloat(json.result.data[0].aqi).toFixed(1);
                let datetime = json.result.data[0].datetime;
                let pmmin=0, pmmax = 0, aqimin = 0, aqimax = 0;
                let aqistatus ='';
                if(aqi>=0 && aqi<15.5){
                     pmmin = 0;
                     pmmax = 15.5;
                     aqimin = 0;
                     aqimax = 51;
                     aqistatus = 'good';
                 } else if (aqi >= 15.5 && aqi < 40.5){
                     pmmin = 15.5;
                     pmmax = 40.5;
                     aqimin = 51;
                     aqimax = 101;
                     aqistatus = 'moderate';
                 } else if (aqi >= 40.5 && aqi <65.5){
                     pmmin = 40.5;
                     pmmax = 65.5;
                     aqimin = 101;
                     aqimax = 151;
                     aqistatus = 'usg';
                 } else if (aqi >= 65.5 && aqi < 150.5){
                     pmmin = 65.5;
                     pmmax = 150.5;
                     aqimin = 151;
                     aqimax = 201;
                     aqistatus = 'unhealthy';
                 } else if (aqi >= 150.5 && aqi <= 250.4){
                    pmmin = 150.5;
                    pmmax = 250.4;
                    aqimin = 201;
                    aqimax = 300;
                    aqistatus = 'veryunhealthy';
                } else if (aqi >= 250.5 && aqi < 500.4){
                    pmmin = 250.5;
                    pmmax = 500.4;
                    aqimin = 301;
                    aqimax = 500;
                    aqistatus = 'hazardous';
                }
                 aqi = parseFloat(aqi);
                 let aqiValue = 0;
                 aqiValue = Math.round((aqi - pmmin)*(aqimax - aqimin)/(pmmax-pmmin) + aqimin);
                this.setState({
                    aqi: aqiValue,
                    datetime: datetime,
                    aqistatus: aqistatus,
                })
            });

    }

    subscribe = (msg, data)=>{
        switch(msg){
            case SELECTED_PLACE_SUGGESTION:
                if(data.label === undefined){
                    this.setState({
                        hasData: true,
                        emptyData: true,
                        areaName: data.areaName
                    })
                }
                else{
                    this.setState({
                        hasData: true,
                        emptyData: false,
                        areaName: data.label,
                        center: data.center,
                        default_place: data.default_place
                    }, function(){
                        this._fetchAQIData()
                    })
                }
                break;
            default:
                Log.warn(msg);
        }
    };

    render(){
        const {classes} = this.props;
        let info = 'loading...';
        if(this.state.hasData) {
            if(this.state.emptyData){
                info = 'We do not have Average AQI data for this location. Once we add the missing data, we will load it into this page and send an email to everyone subscribed to email updates.';
            }
            else {
                let date = new Date(this.state.datetime);
                info = "in " + this.state.areaName + " at " + date.toLocaleString('en-US', { hour: 'numeric', hour12: true });
            }
        }
        return(
            <Grid container spacing={0} className={classes.container}>
                <Grid item xs={12}>
                    <Typography variant={'h1'} align = {'center'} className={classes.rankNumber} style={{'color': aqiRankColor[this.state.aqistatus]}}>
                        {this.state.hasData&&!this.state.emptyData?this.state.aqi:'N/A'}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    {/*<Typography variant={'h1'} align = {'center'} className={classes.totalNumber}>*/}
                    {/*    {this.state.hasData?'of ' + this.state.totalNumber:null}*/}
                    {/*</Typography>*/}
                </Grid>
                <Grid item xs={12}>
                    <Typography variant={'body1'} className={classes.introContainer} classes={{root:classes.introRoot}}>
                        {info}
                    </Typography>
                </Grid>
                <Grid>
                    <img src= {IMAGE_PATH+ "/AQILegendBubbles.png"} height="40px" width="260px" alt={'logo '}/>
                </Grid>
            </Grid>
        );

    }


}


export default withStyles(styles, {withTheme: true})(AQIRanking)