import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import ZoomOutMapIcon from "@material-ui/icons/ZoomOutMap";
import { withStyles } from "@material-ui/core/styles/index";
import {
  CORONAVIRUS_MAP_URL,
  TRAFFIC_MAP_URL,
  CRIME_TRACKER_URL,
  IMAGE_PATH,
  AIR_QUALITY_MAP_URL,
  MAP_URL,
  NEIGHBORHOOD_URL,
  SUBSCRIBE_USER,
  TO_CRIMETRACKER,
  TO_NEIGHBORHOODTRACKER,
} from "../constants";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import InputBar from "./InputBar";
import PubSub from "pubsub-js";
import Link from "@material-ui/core/Link";
import { Tooltip } from "@material-ui/core";
import MenuLeftDrawer from "components/MenuLeftDrawer";

const styles = (theme) => ({
  navBar: {
    backgroundColor: "#ffffff",
    height: "50px",
    borderBottom: "1px solid #DEDFE7",
  },

  navBarMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  imageBox: {
    backgroundImage: `url(${IMAGE_PATH}/Crosstown_ColorLarge.png)`,
    backgroundSize: "cover",
    height: "25px",
    width: "134px",
    [theme.breakpoints.up("md")]: {
      height: "40px",
      width: "182px",
    },
  },
  navLogo: {
    fontSize: "26px",
    display: "inline-block",
    marginRight: 10,
  },
  navButton: {
    margin: theme.spacing(1),
    fontFamily: "Rambla",
    fontSize: "16px",
    fontWeight: "700",
    color: "#383838",
    letterSpacing: "1px",
    borderRadius: "10px",
    borderColor: "#383838",
    borderWidth: "1px",
    textTransform: "none",
    "&:hover": {
      color: "#fff",
      backgroundColor: "#ff9700",
      borderColor: "#fff",
    },
  },
  navSolidButton: {
    boxShadow: "none",
    margin: theme.spacing(1),
    fontFamily: "Rambla",
    fontSize: "16px",
    letterSpacing: "1px",
    backgroundColor: "#ff9700",
    color: "#fff",
    fontWeight: "700",
    borderRadius: "10px",
    borderColor: "#383838",
    borderWidth: "1px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#FFB019",
      boxShadow: "none",
    },
  },
  navMobileSelectedButton: {
    margin: theme.spacing(1),
    fontFamily: "Rambla",
    fontSize: "16px",
    letterSpacing: "1px",
    color: "#ff9700",
    fontWeight: "700",
    borderRadius: "10px",
    borderColor: "#383838",
    borderWidth: "1px",
    textTransform: "none",
    "&:hover": {
      color: "#FFB019",
    },
  },
  navDesktopButtonContainer: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  logoContainer: {
    display: "inline-block",
  },
  logoTag: {
    borderLeftWidth: 2 + "px",
    borderLeftColor: "#d1d5da",
    borderLeftStyle: "solid",
    display: "inline-block",
    marginLeft: 10 + "px",
    paddingLeft: 10 + "px",
    fontSize: "10pt",
    [theme.breakpoints.up("md")]: {
      fontSize: "20pt",
    },
  },
  buttonMargin: {
    marginLeft: "10px",
    marginRight: "10px",
  },
  menuPaperRoot: {
    position: "absolute",
    zIndex: "1000",
    top: "55px",
    left: "0",
    width: "100%",
  },
  mobileMenuButtonRoot: {
    fontSize: "12px",
    margin: "0",
  },
  columnTitle: {
    fontFamily: "rambla",
    fontSize: "14px",
    fontWeight: "700",
    color: "#404040",
    letterSpacing: "0.88px",
    lineHeight: "20px",
  },
  columnBody: {
    color: "#404040",
    fontFamily: "helvetica",
    fontSize: "12px",
    fontWeight: "400",
    letterSpacing: "0.69px",
    lineHeight: "15px",
    textDecoration: "none",
    position: "relative",
    [theme.breakpoints.up("md")]: {
      fontSize: "14px",
      lineHeight: "25px",
    },
  },
  footerTitle: {
    fontFamily: "Rambla",
    fontSize: "18px",
    fontWeight: "700",
    lineHeight: "20px",
    letterSpacing: "1.1px",
    marginBottom: "8px",
  },
  footerBody: {
    whiteSpace: "pre-wrap",
    fontFamily: "helvetica",
    fontSize: "12px",
    fontWeight: "400",
    letterSpacing: "0.69px",
    lineHeight: "15px",
    marginBottom: "10px",
    [theme.breakpoints.up("md")]: {
      fontSize: "14px",
      letterSpacing: "0.8px",
      lineHeight: "25px",
    },
  },
  menuContainerRoot: {
    width: "100%",
  },
  signUpButton: {
    backgroundColor: "#ff9700",
    width: "70px",
    height: "30px",
    padding: "0",
    borderRadius: "10px",
    marginLeft: "20px",
    "&:hover": {
      backgroundColor: "#FFB019",
      boxShadow: "none",
    },
  },
  signUpButtonLabel: {
    fontFamily: "rambla",
    fontSize: "14px",
    fontWeight: "300",
    color: "#fff",
    letterSpacing: "0.9px",
    textTransform: "none",
    lineHeight: "30px",
  },
  contentBody: {
    padding: "0 15px",
    [theme.breakpoints.up("sm")]: {
      width: "80%",
    },
  },
  contentBlockRoot: {
    marginBottom: "10px",
    width: "100%",
  },
  buttonLogo: {
    marginLeft: "auto",
  },
  logo: {
    marginLeft: "auto",
    height: "20px",
    width: "20px",
  },
  titleBorder: {
    marginLeft: "10px",
    borderLeft: "0.4px solid #B1B9C2",
  },
  reportTitleContainer: {
    paddingLeft: "10px",
    paddingRight: "20px",
  },
  reportTitle: {
    fontSize: "16px",
    fontWeight: 700,
    fontFamily: "Open Sans",
    color: "#B1B9C2",
  },
  crosstownTitle: {
    fontSize: "10px",
    paddingLeft: "10px",
    fontFamily: "Open Sans",
    color: "#B1B9C2",
  },
});

class NavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      trackerType: "Crime",
      mobileMenuOpen: false,
      menuAnchor: null,
      inFrame: window !== window.top,
    };
  }

  componentDidMount() {
    window.addEventListener("click", this.onClickHandler);
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.onClickHandler);
  }

  onClickHandler = (e) => {
    let yPosition = e.clientY;
    if (yPosition > 510) {
      this.handleMobileMenuClose();
    }
  };

  handleMobileMenuOpen = (event) => {
    // temp help to locally store the event object, which is the SyntheticEvent
    this.setState((prev) => ({
      ...prev,
      mobileMenuOpen: !prev.mobileMenuOpen,
    }));
  };

  handleMobileMenuClose = () => {
    this.setState((prev) => ({
      ...prev,
      mobileMenuOpen: false,
    }));
  };

  updateUseEmail = (email) => {
    this.setState({
      userEmail: email,
    });
  };

  subscribeUser = () => {
    let data = {
      class: SUBSCRIBE_USER,
      userEmail: this.state.userEmail,
    };
    PubSub.publish(TO_NEIGHBORHOODTRACKER, data);
    PubSub.publish(TO_CRIMETRACKER, data);
  };

  render() {
    const { classes, mode } = this.props;
    const reportTitle = mode != null ? mode.split("-")[0].toUpperCase() : "";
    const content =
      "Tell us what do you want to know about crime, traffic and air quality. \nWant to know more? Sign up for our newsletter.";

    // Navbar when at mobile version
    const mobilePaper = (
      <Paper classes={{ root: classes.menuPaperRoot }} elevation={0}>
        <Grid
          container
          direction="column"
          justify="space-between"
          alignItems="flex-start"
          classes={{ container: classes.menuContainerRoot }}
        >
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Button
              className={
                mode === "coronavirus-map"
                  ? classes.navMobileSelectedButton
                  : classes.navButton
              }
              classes={{
                root: `${classes.buttonMargin} ${classes.mobileMenuButtonRoot}`,
              }}
              href={CORONAVIRUS_MAP_URL}
            >
              Coronavirus
            </Button>
            <Button
              className={
                mode === "traffic-map"
                  ? classes.navMobileSelectedButton
                  : classes.navButton
              }
              classes={{
                root: `${classes.buttonMargin} ${classes.mobileMenuButtonRoot}`,
              }}
              href={TRAFFIC_MAP_URL}
            >
              CoronaTraffic Map
            </Button>
            <Button
              className={
                mode === "aqi-map"
                  ? classes.navMobileSelectedButton
                  : classes.navButton
              }
              classes={{
                root: `${classes.buttonMargin} ${classes.mobileMenuButtonRoot}`,
              }}
              href={AIR_QUALITY_MAP_URL}
            >
              Air Quality
            </Button>
            <Button
              className={
                mode === "map"
                  ? classes.navMobileSelectedButton
                  : classes.navButton
              }
              classes={{
                root: `${classes.buttonMargin} ${classes.mobileMenuButtonRoot}`,
              }}
              href={MAP_URL}
            >
              Crime Map
            </Button>
            <Button
              className={
                mode === "crime"
                  ? classes.navMobileSelectedButton
                  : classes.navButton
              }
              classes={{
                root: `${classes.buttonMargin} ${classes.mobileMenuButtonRoot}`,
              }}
              href={CRIME_TRACKER_URL}
            >
              Crime Tracker
            </Button>
            <Button
              className={
                mode === "neighborhood"
                  ? classes.navMobileSelectedButton
                  : classes.navButton
              }
              classes={{
                root: `${classes.buttonMargin} ${classes.mobileMenuButtonRoot}`,
              }}
              href={NEIGHBORHOOD_URL}
            >
              Neighborhood Tracker
            </Button>
            {/* <Button
                            className={mode === 'aqi' ? classes.navMobileSelectedButton : classes.navButton}
                            classes={{root: `${classes.buttonMargin} ${classes.mobileMenuButtonRoot}`}}
                            href={AQI_URL}>Air Quality Tracker</Button> */}
          </Grid>

          {/*Our product*/}
          <div className={classes.contentBody}>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="flex-start"
              classes={{ container: classes.contentBlockRoot }}
            >
              <Typography
                variant={"subtitle2"}
                color={"inherit"}
                className={classes.columnTitle}
                gutterBottom
              >
                Our Products
              </Typography>
              <Typography
                variant={"body1"}
                color={"inherit"}
                className={classes.columnBody}
                gutterBottom
              >
                <a className={classes.columnBody} href={CORONAVIRUS_MAP_URL}>
                  Coronavirus
                </a>
              </Typography>
              <Typography
                variant={"body1"}
                color={"inherit"}
                className={classes.columnBody}
                gutterBottom
              >
                <a className={classes.columnBody} href={TRAFFIC_MAP_URL}>
                  CoronaTraffic Map
                </a>
              </Typography>
              <Typography
                variant={"body1"}
                color={"inherit"}
                className={classes.columnBody}
                gutterBottom
              >
                <a className={classes.columnBody} href={MAP_URL}>
                  Crime Map
                </a>
              </Typography>
              <Typography
                variant={"body1"}
                color={"inherit"}
                className={classes.columnBody}
                gutterBottom
              >
                <a className={classes.columnBody} href={NEIGHBORHOOD_URL}>
                  Neighborhood Tracker
                </a>
              </Typography>
              <Typography
                variant={"body1"}
                color={"inherit"}
                className={classes.columnBody}
                gutterBottom
              >
                <a className={classes.columnBody} href={CRIME_TRACKER_URL}>
                  Crime Tracker
                </a>
              </Typography>
            </Grid>

            {/*Our stories*/}
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="flex-start"
              classes={{ container: classes.contentBlockRoot }}
            >
              <Typography
                variant={"subtitle2"}
                color={"inherit"}
                className={classes.columnTitle}
              >
                Our Stories
              </Typography>
              <Typography
                variant={"body1"}
                color={"inherit"}
                className={classes.columnBody}
                gutterBottom
              >
                <a
                  className={classes.columnBody}
                  href={"https://xtown.la/category/crimes"}
                >
                  Crime
                </a>
              </Typography>
              <Typography
                variant={"body1"}
                color={"inherit"}
                className={classes.columnBody}
                gutterBottom
              >
                <a
                  className={classes.columnBody}
                  href={"https://xtown.la/category/traffic"}
                >
                  Traffic
                </a>
              </Typography>
              <Typography
                variant={"body1"}
                color={"inherit"}
                className={classes.columnBody}
                gutterBottom
              >
                <a
                  className={classes.columnBody}
                  href={"https://xtown.la/category/health"}
                >
                  Health
                </a>
              </Typography>
            </Grid>
            {/* About Us */}
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="flex-start"
              classes={{ container: classes.contentBlockRoot }}
            >
              <Typography
                variant={"subtitle2"}
                color={"inherit"}
                className={classes.columnTitle}
              >
                About Us
              </Typography>
              <Typography
                variant={"body1"}
                color={"inherit"}
                className={classes.columnBody}
                gutterBottom
              >
                <a
                  className={classes.columnBody}
                  href={"https://xtown.la/about-us"}
                >
                  About CrossTown
                </a>
              </Typography>
              <Typography
                variant={"body1"}
                color={"inherit"}
                className={classes.columnBody}
                gutterBottom
              >
                <a
                  className={classes.columnBody}
                  href={"https://xtown.la/team-bios"}
                >
                  Team Bios
                </a>
              </Typography>
              <Typography
                variant={"body1"}
                color={"inherit"}
                className={classes.columnBody}
                gutterBottom
              >
                <a
                  className={classes.columnBody}
                  href={"https://xtown.la/our-data/"}
                >
                  Our Data
                </a>
              </Typography>
            </Grid>

            <Grid
              container
              direction="column"
              justify="center"
              alignItems="flex-start"
              classes={{ container: classes.contentBlockRoot }}
            >
              <Typography
                variant={"h5"}
                color={"inherit"}
                classes={{ root: classes.footerTitle }}
              >
                <strong> You are here.</strong>
              </Typography>
              <Typography
                variant={"body1"}
                color={"inherit"}
                classes={{ root: classes.footerBody }}
                paragraph
              >
                {content}
              </Typography>

              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
              >
                <InputBar width={"200px"} updateHandler={this.updateUseEmail} />
                <Button
                  variant="contained"
                  classes={{
                    root: classes.signUpButton,
                    label: classes.signUpButtonLabel,
                  }}
                  onClick={this.subscribeUser}
                >
                  Sign Up
                </Button>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Paper>
    );

    let url = "";
    switch (mode) {
      case "coronavirus-map":
        url = CORONAVIRUS_MAP_URL;
        break;
      case "traffic-map":
        url = TRAFFIC_MAP_URL;
        break;
      case "aqi-map":
        url = AIR_QUALITY_MAP_URL;
        break;
      case "map":
        url = MAP_URL;
        break;
      case "crime":
        url = CRIME_TRACKER_URL;
        break;
      case "neighborhood":
        url = NEIGHBORHOOD_URL;
        break;
      default:
        url = CORONAVIRUS_MAP_URL;
    }

    const expandZoom = (
      <Tooltip title="Expand Map" placement="bottom" arrow>
        <Link href={url} target="_blank" rel="noopener noreferrer">
          <ZoomOutMapIcon />
        </Link>
      </Tooltip>
    );

    return (
      <Grid
        id="topbar"
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        className={classes.navBar}
      >
        {this.state.mobileMenuOpen && mobilePaper}

        <div className={classes.logoContainer}>
          {this.state.inFrame && expandZoom}

          <MenuLeftDrawer />
        </div>

        <div className={classes.navDesktopButtonContainer} id="topBar"></div>

        {/* Report title and crosstown logo */}
        <Button href="https://xtown.la" className={classes.buttonLogo}>
          <img
            alt="crosstown logo"
            src="/images/crosstown-logo.png"
            className={classes.logo}
          />
        </Button>

        <Grid className={classes.titleBorder}>
          <div className={classes.reportTitleContainer}>
            <span className={classes.reportTitle}>{reportTitle}</span>
            <span className={classes.reportTitle} style={{ fontWeight: 100 }}>
              Report
            </span>
          </div>
          <div className={classes.crosstownTitle}>CrosstownLA</div>
        </Grid>

        {/* <div className={classes.navBarMobile}>
          <IconButton aria-haspopup="true" onClick={this.handleMobileMenuOpen}>
            <MenuIcon />
          </IconButton>
        </div> */}
      </Grid>
    );
  }
}

export default withStyles(styles, { withTheme: true })(NavBar);
