import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import EnhancedTableHead from "./EnhancedTableHead";
import EnhancedTableToolbar from "./EnhancedTableToolbar";
import IconButton from "@material-ui/core/IconButton";
import RefreshIcon from '@material-ui/icons/Refresh';
import PubSub from "pubsub-js";
import {TO_BUNDLES, TO_NOTIFICATION_MESSAGE} from "../../config/settings";
import update from "immutability-helper";
import FormControl from "@material-ui/core/FormControl/FormControl";
import {API_URL} from "../constants"
import Log from "../../utils/Log";

const rows = [
    {id: 'name', numeric: false, disablePadding: true, label: 'Big Bundle Name'},
];

function createData(index, name) {
    return {id: index, name: name, modified: false};
}

function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
    },
    table: {
        minWidth: 1020,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        margin: theme.spacing(1),
    },
    button: {
        marginLeft: theme.spacing(1),
    },
    formControl: {
        marginLeft: theme.spacing(1),
        minWidth: 500,
        maxWidth: 700,
    },
});


class BigBundles extends Component {

    state = {
        order: 'asc',
        orderBy: 'name',
        selected: [],
        loading: false,
        data: [],
        page: 0,
        rowsPerPage: 10,
        newBigBundleValue: '',
    };

    componentDidMount() {
        this._fetchBigBundlesData();
    }

    /**
     * Request big bundles from API
     * @private
     */
    _fetchBigBundlesData() {

        let url = API_URL +"/api/bundles/big";

        this.setState({
            loading: true,
            selected: []
        });

        fetch(url)
            .then(res => res.json())
            .then(
                (result) => {
                    this._handleFetchedData(result);
                },
                (error) => {

                }
            )
    }

    _handleFetchedData(result) {
        this.setState({
            loading: false,
            data: result.map((el, index) => createData(el.name, el.name))
        });
    }

    _handleTextFieldChange = (event) => {
        this.setState({
            newBigBundleValue: event.target.value
        });
    };

    _handleAddBigBundleClick = (event) => {
        let url = API_URL + "/api/bundles/big";

        fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                name: this.state.newBigBundleValue.trim(),
            })

        }).then(response => {
            return response.json()
        }).then(json => {
            this.setState({
                newBigBundleValue: ''
            });
            PubSub.publish(TO_BUNDLES, null);
            PubSub.publish(TO_NOTIFICATION_MESSAGE, {
                message: json.message,
                variant: json.error ? "error" : "success"
            });
            this._fetchBigBundlesData();
        }).catch(err => {
            Log.error("Failed to create big bundle", err);
        });
    };

    _handleRefreshClick = (event) => {
        this._fetchBigBundlesData();
    };

    _handleDeleteSelected = (event) => {
        const {selected} = this.state;

        let url = API_URL + "/api/bundles/big";

        selected.forEach(function (element) {
            fetch(url, {
                method: 'DELETE',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    name: element,
                })

            }).then(response => {
                return response.json()
            }).then(json => {
                PubSub.publish(TO_BUNDLES, null);
                PubSub.publish(TO_NOTIFICATION_MESSAGE, {
                    message: json.message,
                    variant: json.error ? "error" : "info"
                });
            }).catch(err => {
                Log.error("Failed to delete big bundle", err);
            });
        });

        this._fetchBigBundlesData()
    };

    /**
     * Handle changes
     * @private
     */
    _handleFieldChange = (event, field, n) => {
        const {data} = this.state;

        let index = data.findIndex(d => d.id === n.id);

        let new_value = event.target.value;
        if (new_value === "") {
            new_value = null;
        }

        let updated = update(data[index], {[field]: {$set: new_value}, modified: {$set: true}});
        let newData = update(data, {
            $splice: [[index, 1, updated]]
        });

        this.setState({data: newData});
    };

    /**
     * Save changes
     * @private
     */
    _handleSaveChanges = event => {

        const {data} = this.state;
        let self = this;
        data.forEach(function (n) {

            if (n.modified === true) {

                let url = API_URL + "/api/bundles/big/" + encodeURIComponent(n.id);

                fetch(url, {
                    method: 'PUT',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        new_name: n.name.trim()
                    })

                }).then(response => {
                    return response.json()
                }).then(json => {
                    self._setModified(n, false);
                    PubSub.publish(TO_BUNDLES, null);
                    PubSub.publish(TO_NOTIFICATION_MESSAGE, {
                        message: json.message,
                        variant: json.error ? "error" : "info"
                    });
                }).catch(err => {
                    Log.error("Failed to update big bundle", err);
                });
            }
        });
    };

    /**
     * Set modified
     * @param n the object to modify
     * @param modified the state to set
     * @private
     */
    _setModified = (n, modified) => {
        const {data} = this.state;

        let index = data.findIndex(d => d.id === n.id);
        let updated = update(data[index], {modified: {$set: modified}});
        let newData = update(data, {
            $splice: [[index, 1, updated]]
        });
        this.setState({data: newData});
    };

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = 'desc';

        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }

        this.setState({order, orderBy});
    };

    handleSelectAllClick = event => {
        if (event.target.checked) {
            this.setState(state => ({selected: state.data.map(n => n.id)}));
            return;
        }
        this.setState({selected: []});
    };

    handleClick = (event, id) => {
        const {selected} = this.state;
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        this.setState({selected: newSelected});
    };

    handleChangePage = (event, page) => {
        this.setState({page});
    };

    handleChangeRowsPerPage = event => {
        this.setState({rowsPerPage: event.target.value});
    };

    isSelected = id => this.state.selected.indexOf(id) !== -1;

    render() {
        const {classes} = this.props;
        const {data, order, orderBy, selected, rowsPerPage, page} = this.state;
        const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
        const numModified = data.filter(n => n.modified === true).length;

        return (

            <div className={classes.root}>

                <form className={classes.container} noValidate autoComplete="off">
                    <TextField
                        onChange={this._handleTextFieldChange}
                        value={this.state.newBigBundleValue}
                        label="Name"
                        placeholder="Enter a big bundle name"
                        variant="outlined"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <Button variant="contained" color="primary" className={classes.button}
                            onClick={this._handleAddBigBundleClick}>
                        Add
                    </Button>

                    <IconButton
                        onClick={this._handleRefreshClick}
                        color="secondary"
                        className={classes.button}
                        aria-label="Refresh">
                        <RefreshIcon/>
                    </IconButton>
                </form>


                <Paper>
                    <EnhancedTableToolbar
                        selected={selected}
                        numSelected={selected.length}
                        numModified={numModified}
                        onDelete={this._handleDeleteSelected}
                        onSave={this._handleSaveChanges}
                        title="Big Bundles"
                    />
                    <div className={classes.tableWrapper}>
                        <Table className={classes.table} aria-labelledby="tableTitle">
                            <EnhancedTableHead
                                numSelected={selected.length}
                                order={order}
                                orderBy={orderBy}
                                onSelectAllClick={this.handleSelectAllClick}
                                onRequestSort={this.handleRequestSort}
                                rowCount={data.length}
                                rows={rows}
                            />
                            <TableBody>
                                {stableSort(data, getSorting(order, orderBy))
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map(n => {
                                        const isSelected = this.isSelected(n.id);
                                        return (
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                aria-checked={isSelected}
                                                tabIndex={-1}
                                                key={n.id}
                                                selected={isSelected}
                                            >
                                                <TableCell padding="checkbox">
                                                    <Checkbox onClick={event => this.handleClick(event, n.id)}
                                                              checked={isSelected}/>
                                                </TableCell>
                                                <TableCell component="th" scope="row" padding="none">
                                                    <FormControl className={classes.formControl}>
                                                        <TextField
                                                            onChange={event => this._handleFieldChange(event, "name", n)}
                                                            value={n.name}
                                                            placeholder="Enter big bundle name"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                    </FormControl>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                {emptyRows > 0 && (
                                    <TableRow style={{height: 49 * emptyRows}}>
                                        <TableCell colSpan={6}/>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </div>
                    <TablePagination
                        component="div"
                        count={data.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        rowsPerPageOptions={[5, 10, 25]}
                        backIconButtonProps={{
                            'aria-label': 'Previous Page',
                        }}
                        nextIconButtonProps={{
                            'aria-label': 'Next Page',
                        }}
                        onChangePage={this.handleChangePage}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    />
                </Paper>
            </div>
        );
    }
}


BigBundles.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(BigBundles);