import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Header from '../components/Header'
import SwipeableViews from 'react-swipeable-views';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import BigBundles from "../components/bundles/BigBundles";
import SmallBundles from "../components/bundles/SmallBundles";
import NicknamesBundles from "../components/bundles/NicknamesBundles";
import CrimeDescriptionMapping from "../components/bundles/CrimeDescriptionMapping";
import NotificationMessage from "../components/NotificationMessage";
import {Helmet} from "react-helmet";
import {BUNDLES_TITLE} from "../config/settings";

const styles = theme => ({
    root: {
        width: '100%',
    },

});

class Bundles extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value: 0,
        };
    }

    componentDidMount() {

    }

    componentWillUnmount() {

    }

    componentDidUpdate(prevProps, prevStates) {

    }

    handleChange = (event, value) => {
        this.setState({value});
    };

    handleChangeIndex = index => {
        this.setState({value: index});
    };

    render() {
        const {classes, theme} = this.props;
        return (

            <div>
                <Helmet>
                    <title>{BUNDLES_TITLE}</title>
                    <meta name="robots" content="noindex, nofollow"/>
                </Helmet>

                <div className={classes.root}>

                    <Header showAutocomplete={false}/>

                    <AppBar position="static" color="default">
                        <Tabs
                            value={this.state.value}
                            onChange={this.handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            fullWidth
                        >
                            <Tab label="Big Bundles"/>
                            <Tab label="Small Bundles"/>
                            <Tab label="Nicknames"/>
                            <Tab label="Crime Description Mappings"/>
                        </Tabs>
                    </AppBar>
                    <SwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={this.state.value}
                        onChangeIndex={this.handleChangeIndex}
                    >
                        <BigBundles/>
                        <SmallBundles/>
                        <NicknamesBundles/>
                        <CrimeDescriptionMapping readOnly={false}/>
                    </SwipeableViews>

                    <NotificationMessage/>

                </div>
            </div>
        );
    }
}

Bundles.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles, {withTheme: true})(Bundles);