import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Fade, LinearProgress, withStyles } from "@material-ui/core";
import { Helmet } from "react-helmet";
import {
  AIR_QUALITY_MAP_TITLE,
  AIR_QUALITY_MAP_DESCRIPTION,
  AQI_TRACKER_KEYWORDS,
  CROSSTOWN_TITLE,
  AIR_QUALITY_MAP_URL,
} from "../config/settings";
import NavBar from "../components/tracker/NavBar";
import Map from "../components/Map";
import { fromJS } from "immutable";
import _ from "lodash";
import moment from "moment";
import Log from "../utils/Log";
import usePartitionData from "../components/api-hooks/hooks/usePartitionData";
import { getAirQualityMapStyle } from "../components/api-hooks/hooks/getMapStyleWithLayers";
import useAirQualityLastUpdatedDate from "../components/api-hooks/hooks/useAirQualityLastUpdatedDate";
import useAirQualityWithDateTime, {
  getAirQualityWithDateTimeURL,
} from "../components/api-hooks/hooks/useAirQualityWithDateTime";
import { updateAirQualityData } from "../utils/UpdateAirQualityData";
import Share from "../components/Share";
import ReactGA from "react-ga";
import LeftPanel from "components/LeftPanel";
import AQMapLegend from "components/MapLegend/AQMapLegend";
import { reportData } from "../utils/ReportData";
import useAirQualityTrend, {
  getAirQualityTrendUrl,
} from "components/api-hooks/hooks/useAirQualityTrend";
import { AIR_QUALITY_MAP_LEGEND_COLORS } from "../config/settings";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  placeholder: {
    position: "absolute",
    zIndex: 1000,
    height: 10,
    width: "100%",
  },
  thisNav: {
    padding: 100,
  },
});

const AirQualityMapContainer = (props) => {
  const { classes } = props;

  // Map style
  const initialMapStyle = getAirQualityMapStyle();

  // Map style state
  const [mapStyle, setMapStyle] = useState(initialMapStyle);

  const selectedPartitionTypeFilter = "neighborhoods";
  const layerIds = ["partition-data", "outlier-data"];

  const [lastUpdated, setLastUpdated] = useState("");
  const [dateTime, setDateTime] = useState(null);

  // Partition data state
  const [partitionDataState] = usePartitionData(selectedPartitionTypeFilter);

  useEffect(() => {
    const { isLoading, data } = partitionDataState;

    if (!isLoading && !_.isEmpty(data)) {
      Log.info(
        "Updating map style with partition source",
        AirQualityMapContainer.name
      );
      setMapStyle((previousMapStyle) =>
        previousMapStyle.setIn(
          ["sources", "partition"],
          fromJS({
            type: "geojson",
            data: data,
          })
        )
      );
    }
  }, [partitionDataState]);

  const [useAirQualityLastUpdatedDateState] = useAirQualityLastUpdatedDate();

  useEffect(() => {
    const { isLoading, data } = useAirQualityLastUpdatedDateState;

    if (!isLoading && !_.isEmpty(data)) {
      let date = moment(data[0].date, ["YYYY-MM-DDTHH:mm:ss.SSSSZ"]).utc();
      setLastUpdated(date);
      let localDateTime = moment(data[0].date, [
        "YYYY-MM-DDTHH:mm:ss.SSSSZ",
      ]).toDate();
      localDateTime.setHours(localDateTime.getHours() + 5);
      setDateTime(localDateTime);
    }
  }, [useAirQualityLastUpdatedDateState]);

  const [airQualityState, setAirQualityStateUrl] = useAirQualityWithDateTime(
    0,
    0,
    0,
    0
  );

  useEffect(() => {
    if (dateTime) {
      let year = dateTime.getFullYear();
      let month = dateTime.getMonth() + 1;
      let day = dateTime.getDate();
      let hour = dateTime.getHours();
      let url = getAirQualityWithDateTimeURL(year, month, day, hour);
      setAirQualityStateUrl(url);
    }
  }, [dateTime, setAirQualityStateUrl]);

  useEffect(() => {
    const { isLoading, data } = airQualityState;

    if (
      !isLoading &&
      !partitionDataState.isLoading &&
      !_.isEmpty(data) &&
      !_.isEmpty(partitionDataState.data)
    ) {
      Log.info(
        "Updating map style with air quality data",
        AirQualityMapContainer.name
      );
      updateAirQualityData(partitionDataState.data, data, "neighborhood");
      setMapStyle((previousMapStyle) =>
        previousMapStyle.setIn(
          ["sources", "partition"],
          fromJS({
            type: "geojson",
            data: partitionDataState.data,
          })
        )
      );
    }
  }, [airQualityState, partitionDataState]);

  const [selectedFeature, setSelectedFeature] = useState(null);
  const [searchPlaceState, setSearchPlace] = useState(null);

  // Search place
  useEffect(() => {
    ReactGA.event({
      category: "Air Quality Map",
      action: "Search place changed",
      label: JSON.stringify(searchPlaceState),
    });
  }, [searchPlaceState]);

  const isLoading =
    partitionDataState.isLoading ||
    airQualityState.isLoading ||
    airQualityState.isLoading;

  /*
  Left panel data:
  */

  const [rank, setRank] = useState(0);
  const [score, setScore] = useState(0);
  const [featureId, setFeatureId] = useState("");
  const [searchName, setSearchName] = useState("");

  useEffect(() => {
    if (selectedFeature) {
      let stats = {};
      const featureProperties = selectedFeature.properties;
      setFeatureId(featureProperties.id);
      setSearchName(featureProperties.label);
      stats = JSON.parse(featureProperties.stats);
      Object.keys(stats).map(function (key) {
        if (key === "AQI") setRank(stats[key]);
        else if (key === "PM2.5") setScore(stats[key]);
        return null;
      });
    }
  }, [selectedFeature]);
  const aqi_statistics = [
    {
      key: "Air Quality Ranking",
      value: rank + "/270",
      info: "yes",
    },
    {
      key: "PM2.5 AQI Score",
      value: score,
      color: getLegendColor(score),
    },
  ];

  function getLegendColor(score) {
    if (score == null) score = 0;
    let legendColor = reportData(score);
    return legendColor != null ? legendColor : "#fffff";
  }

  /* TREND DATA */
  const [airQualityTrendState, setAirQualityTrendUrl] = useAirQualityTrend(
    featureId,
    0,
    0,
    0
  );
  const [chartData, setChartData] = useState([]);
  const mapXaxisLabel = "Daily PM2.5 fluctuation";
  const mapYaxisLabel = "PM2.5 AQI";

  useEffect(() => {
    let year = 0;
    let month = 0;
    let day = 0;
    if (dateTime) {
      year = dateTime.getFullYear();
      month = dateTime.getMonth() + 1;
      day = dateTime.getDate();
    }
    const aqi_url = getAirQualityTrendUrl(featureId, year, month, day);
    setAirQualityTrendUrl(aqi_url);
  }, [selectedFeature, featureId, dateTime, setAirQualityTrendUrl]);

  useEffect(() => {
    const { isLoading, data } = airQualityTrendState;
    let trendData = [];
    if (!isLoading && data != null && data.length > 0) {
      data[0].trends.forEach(function (ele, idx) {
        let x_data;
        x_data = moment(ele.date_observed, ["YYYY-MM-DDTHH:mm:ss.SSSSZ"]).utc();
        x_data = x_data.format("M/D ha");
        let y_data = Math.round(ele.avg_aqi);

        trendData.push({
          x: x_data,
          y: y_data,
        });
      });
    }
    setChartData([trendData]);
  }, [airQualityTrendState]);

  return (
    <div>
      <Helmet>
        <title>{AIR_QUALITY_MAP_TITLE}</title>
        <meta name="description" content={AIR_QUALITY_MAP_DESCRIPTION} />
        <meta name="keywords" content={AQI_TRACKER_KEYWORDS} />
        <meta property="og:title" content={AIR_QUALITY_MAP_TITLE} />
        <meta property="og:site_name" content={CROSSTOWN_TITLE} />
        <meta property="og:description" content={AIR_QUALITY_MAP_DESCRIPTION} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={AIR_QUALITY_MAP_URL} />
      </Helmet>
      <div className={classes.root}>
        <NavBar mode={"aqi-map"} />

        <div id="progressbar" className={classes.placeholder}>
          <Fade
            in={isLoading}
            style={{
              transitionDelay: isLoading ? "800ms" : "0ms",
            }}
            unmountOnExit
          >
            <LinearProgress color="secondary" />
          </Fade>
        </div>

        <Share />

        <Map
          mapStyle={mapStyle}
          onSelectedFeature={setSelectedFeature}
          interactiveLayerIds={layerIds}
          partitionType={selectedPartitionTypeFilter}
          searchAddress={searchPlaceState}
        />
        <AQMapLegend
          colors={AIR_QUALITY_MAP_LEGEND_COLORS}
          title="PM 2.5 AQI Legend"
        />
        <LeftPanel
          reportTitle="Air Quality Report"
          reportTitleDesc="This map measures the concentrations of PM2.5, one of the major contributors to air pollution."
          onSearchAddressChanged={(value) => setSearchPlace(value)}
          lastUpdated={lastUpdated}
          selectedFeature={selectedFeature}
          isLoadingData={isLoading}
          statistics={aqi_statistics}
          searchName={searchName}
          chartData={chartData}
          mapXaxisLabel={mapXaxisLabel}
          mapYaxisLabel={mapYaxisLabel}
          showFaq={true}
        ></LeftPanel>
      </div>
    </div>
  );
};

AirQualityMapContainer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AirQualityMapContainer);
