import React from 'react';
import { hydrate, render } from 'react-dom';
import 'styles/css/index.css';
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import ReactGA from 'react-ga';
import Bundles from "./views/Bundles";
import Bundling from "./views/Bundling";
import NewsletterList from './components/subscription/NewsletterList';
import AQITracker from "./views/AQITracker";
import SubscriptionWrap from "./components/subscription/SubscriptionWrap";
import CssBaseline from "@material-ui/core/CssBaseline";
import {ThemeProvider} from "@material-ui/core/styles";
import theme from './config/theme';
import SafetyMapContainer from "./containers/SafetyMapContainer";
import CoronavirusMapContainer from "./containers/CoronavirusMapContainer";
import TrafficMapContainer from "./containers/TrafficMapContainer";
import AirQualityMapContainer from "./containers/AirQualityMapContainer";
import Tracker from "./components/tracker/Tracker";

// Load env variables in process.env
require('dotenv').config();

// Add these lines:
if (process.env.NODE_ENV !== 'production') {
    localStorage.setItem('debug', 'xtown:*');
}

if (process.env.REACT_APP_ENV === 'production') {
    ReactGA.initialize('UA-112394123-4');
    ReactGA.pageview(window.location.pathname + window.location.search);
} else {
    ReactGA.initialize('UA-112394123-4', {
        debug: true,
    });
}

const rootElement = document.querySelector('#root');

const app =
    <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline/>
        <Router>
            <Switch>
                <Route path="/map" component={SafetyMapContainer}/>
                <Route path="/map/place" component={SafetyMapContainer}/>
                <Route path="/coronavirus" component={CoronavirusMapContainer}/>
                <Route path="/traffic" component={TrafficMapContainer}/>
                <Route path="/aqi-map" component={AirQualityMapContainer}/>

                <Route path="/bundles" component={Bundles}/>
                <Route path="/bundling" component={Bundling}/>

                <Route path="/neighborhood/:area/:month?/:year?" render={() => <Tracker mode={'neighborhood'}/>} />
                <Route path="/crime/:crime/:month?/:year?" render={() => <Tracker mode={'crime'}/>} />
                <Route path="/aqi" exact component={AQITracker}/>
                <Route path="/aqi/:place/:coordinates" component={AQITracker}/>
                <Route path="/subscription" component={SubscriptionWrap}/>
                <Route path="/news" component={NewsletterList}/>
                <Route path="/aqi" exact component={AQITracker}/>
                <Route path="/aqi/:place/:coordinates" component={AQITracker}/>
                <Route path="/subscription" component={SubscriptionWrap}/>
                <Route path="/news" component={NewsletterList}/>

                <Redirect path="/neighborhood" to="/neighborhood/downtown"/>
                <Redirect path="/crime" to="/crime/burglary"/>
                <Redirect path="/" to="/coronavirus"/>
            </Switch>
        </Router>
    </ThemeProvider>;

if (rootElement.hasChildNodes()) {
    hydrate(app, rootElement);
} else {
    render(app, rootElement);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();