import React, {useEffect, useState} from 'react';
import {withStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Switch from "@material-ui/core/Switch";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";

const styles = theme => ({
    tableContainer:{
        width: '110%',
        overflow: 'auto',
        position: 'relative'
    },
    cellTitleContainer:{
        fontFamily: 'helvetica',
        fontSize: '14px',
        fontWeight: '400',
        lineHeight: '1.2',
        padding: '4px 5px 4px 5px',
        letterSpacing: '1.2px',
        color: 'rgba(0, 0, 0, 0.87)',
        textAlign: 'center',
        [theme.breakpoints.up('md')]:{
            minWidth: '100px',
            padding: '4px 30px 4px 30px'
        }
    },
    cellContainer:{
        fontFamily: 'helvetica',
        fontSize: '14px',
        fontWeight: '400',
        lineHeight: '1.5',
        padding: '4px 5px 4px 5px',
        letterSpacing: '1px',
        textAlign: 'center',
        [theme.breakpoints.up('md')]:{
            minWidth: '100px',
            padding: '4px 30px 4px 30px'
        }
    },
    neighborhoodName:{
        color: '#007bff',
    },
    rankingColumn:{
        width: '50px',
        paddingLeft: '0',
    },
    tableTitleRoot:{
        fontFamily: 'Rambla',
        fontSize: '18px',
        fontWeight: '700',
        color: '#383838',
        letterSpacing: '1.2px'
    },
    tableSubTitle:{
        fontSize: '10px',
        fontFamily: 'Rambla',
        fontWeight: '400',
        color: '#383838',
        letterSpacing: '0.8px'
    },
    tableHyperlink:{
        textDecoration: 'none',
        color: '#007bff',
    },
    selectorGroupContainer:{
        width: '100%',
        [theme.breakpoints.up('sm')]:{
            width: '50%'
        }
    },
    selectorLabel:{
        fontFamily: 'Rambla',
        fontSize: '14px',
        fontWeight: '400',
        letterSpacing: '0.8px',
        color: 'rgba(0, 0, 0, 0.54)'
    },
    selectorLeftLabel:{
        marginRight: '-5px',
    },
    selectorRightLabel:{
        marginLeft: '-5px',
    },
    // following three classes used to change the switch bar color when checked
    selectorSwitch:{
        '&$selectorChecked':{
            color: '#fff',
            '&+$selectorBar':{
                backgroundColor: 'rgba(0, 0, 0, 0.5)'
            }
        }
    },
    selectorChecked:{
        fontWeight: '700'
    },
    selectorBar:{
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        opacity: 1
    }
});

const CCrimeTable = (props) => {

    const {classes, mode, rankTableData, dateTitle, changePartitionHandler,
        changeHistoryRangeHandler, restoreNeighborURLName} = props;
    const title = rankTableData&&rankTableData.crimeType + ' Rate Ranking';
    const neighborhoodTrackerUrl = window.location.protocol+'//'+window.location.host;

    const [waiting, setWaiting] = useState(true);

    useEffect(()=>{
        setWaiting(false);
    }, [rankTableData]);

    function changePartition(e){
        setWaiting(true);
        changePartitionHandler(e);
    }

    function changeHistory(e){
        setWaiting(true);
        changeHistoryRangeHandler(e);
    }

    return(
        <div>
            {/*{this.state.hasData?rankTable:null}*/}
            <Grid item xs={12}>
                <Typography variant={'subtitle2'} align={'left'}
                            className={classes.subTitle} classes={{root:classes.tableTitleRoot}}>
                    {title}
                </Typography>
            </Grid>

            {/*switch row for crime table*/}
            <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
            >
                <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justify="flex-start"
                    classes={{container: classes.selectorGroupContainer}}
                >
                    <Typography variant={'subtitle1'}
                                classes={{subtitle1:`${classes.selectorLeftLabel} ${classes.selectorLabel}
                                    ${mode.partition ==='neighborhood'?classes.selectorChecked:null}`}}>
                        Neighborhood </Typography>
                    <Switch classes={{
                        root:classes.selectorSwitch,
                        switchBase:classes.selectorSwitch,
                        checked: classes.selectorChecked
                    }} onChange={changePartition}/>
                    <Typography variant={'subtitle1'}
                                classes={{subtitle1:`${classes.selectorRightLabel} ${classes.selectorLabel}
                                    ${mode.partition ==='zipcode'?classes.selectorChecked:null}`}}>
                        Zip Code </Typography>
                </Grid>

                <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justify= {Math.min(window.innerWidth, window.outerWidth) < 600?'flex-start':"flex-end"}
                    classes={{container: classes.selectorGroupContainer}}
                >
                    <Typography variant={'subtitle1'}
                                classes={{subtitle1:`${classes.selectorLeftLabel} ${classes.selectorLabel}
                                    ${mode.history ==='monthly'?classes.selectorChecked:null}`}}>
                        Last Month </Typography>
                    <Switch classes={{
                        root:classes.selectorSwitch,
                        switchBase:classes.selectorSwitch,
                        checked: classes.selectorChecked
                    }} onChange={changeHistory}/>
                    <Typography variant={'subtitle1'}
                                classes={{subtitle1:`${classes.selectorRightLabel} ${classes.selectorLabel}
                                    ${mode.history ==='yearly'?classes.selectorChecked:null}`}}>
                        Last Year</Typography>
                </Grid>

            </Grid>
            <div className={classes.tableContainer}>
                {/*{this.state.updating&&<Loader noMask={true}/>}*/}
                {rankTableData &&
                    <Table classes={{root: classes.tableContainer}}>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center" className={classes.cellTitleContainer}
                                           classes={{root: classes.rankingColumn}}>Rank</TableCell>
                                <TableCell align="center" className={classes.cellTitleContainer}>
                                    Neighborhood
                                </TableCell>
                                <TableCell align="center" className={classes.cellTitleContainer}>
                                    Crime Count
                                </TableCell>
                                <TableCell align="center" className={classes.cellTitleContainer}>
                                    {rankTableData.crimeType} + ' Rate'
                                    <Typography variant={'subtitle1'} classes={{subtitle1: classes.tableSubTitle}}>
                                        Crimes per 100,000 residents ({dateTitle})
                                    </Typography>
                                </TableCell>
                                <TableCell align="center" className={classes.cellTitleContainer}>Change in crimes
                                    <Typography variant={'subtitle1'} classes={{subtitle1: classes.tableSubTitle}}>
                                        Change compared to last month
                                    </Typography>
                                </TableCell>
                                <TableCell align="center" className={classes.cellTitleContainer}>Change in Rank
                                    <Typography variant={'subtitle1'} classes={{subtitle1: classes.tableSubTitle}}>
                                        Change compared to last month
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rankTableData.crimeTableRows.map((row, idx) => (
                                <TableRow key={idx}>
                                    <TableCell component="th" scope="row"
                                               className={classes.cellContainer} classes={{root: classes.rankingColumn}}>
                                        {row.ranking}
                                    </TableCell>
                                    <TableCell align="center"
                                               className={classes.cellContainer}
                                               classes={{root:classes.neighborhoodName}}>
                                        {mode.partition === 'zipcode'?row.zipcode:
                                            <a href={neighborhoodTrackerUrl+`/neighborhood/${row.neighborhood}`}
                                               className={classes.tableHyperlink}>
                                                {waiting||restoreNeighborURLName(row.neighborhood)}
                                            </a>
                                        }
                                    </TableCell>
                                    <TableCell align="center" className={classes.cellContainer}>
                                        {row.crimeCount}
                                    </TableCell>
                                    <TableCell align="center" className={classes.cellContainer}>
                                        {row.crimeMassRate}
                                    </TableCell>
                                    <TableCell align="center" className={classes.cellContainer}>
                                        {(row.crimeDiff<0?"":"+")+row.crimeDiff}
                                    </TableCell>
                                    <TableCell align="center" className={classes.cellContainer}>
                                        {(row.crimeRankDiff<0?"":"+")+row.crimeRankDiff}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>}
            </div>
        </div>
    );
};

export default withStyles(styles, {withTheme: true})(CCrimeTable)