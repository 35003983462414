import {useState} from "react";


const useMapHoveredData = () => {
    const [hoveredData, setHoveredData] = useState({
        hoveredFeature: null,
        lat: 0,
        lng: 0
    });

    return [hoveredData, setHoveredData];
};

export default useMapHoveredData