import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {withStyles} from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import PubSub from "pubsub-js";
import {TO_NOTIFICATION_MESSAGE} from 'config/settings'

const styles = theme => ({
    close: {
        padding: theme.spacing(0.5),
    },
});

const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
};

const styles1 = theme => ({
    success: {
        backgroundColor: green[600],
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    info: {
        backgroundColor: theme.palette.primary.dark,
    },
    warning: {
        backgroundColor: amber[700],
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
});

function MySnackbarContent(props) {
    const {classes, className, message, onClose, variant, ...other} = props;
    const Icon = variantIcon[variant];

    return (
        <SnackbarContent
            className={classNames(classes[variant], className)}
            aria-describedby="client-snackbar"
            message={
                <span id="client-snackbar" className={classes.message}>
                    <Icon className={classNames(classes.icon, classes.iconVariant)}/>
                    {message}
                </span>
            }
            action={[
                <IconButton
                    key="close"
                    aria-label="Close"
                    color="inherit"
                    className={classes.close}
                    onClick={onClose}
                >
                    <CloseIcon className={classes.icon}/>
                </IconButton>,
            ]}
            {...other}
        />
    );
}

MySnackbarContent.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
    message: PropTypes.node,
    onClose: PropTypes.func,
    variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
};

const MySnackbarContentWrapper = withStyles(styles1)(MySnackbarContent);

class NotificationMessage extends Component {
    queue = [];

    state = {
        open: false,
        messageInfo: {},
    };

    componentDidMount() {
        this._pubsub_token = PubSub.subscribe(TO_NOTIFICATION_MESSAGE, this.subscriber.bind(this));
    }

    componentWillUnmount() {
        PubSub.unsubscribe(this._pubsub_token);
    }

    subscriber(msg, data) {
        switch (msg) {
            case TO_NOTIFICATION_MESSAGE:
                this.handleNewMessage(data);
                break;
            default:
        }
    }

    handleNewMessage(data) {

        this.queue.push({
            message: data.message,
            key: new Date().getTime(),
            variant: data.variant || "success"
        });

        if (this.state.open) {
            // immediately begin dismissing current message
            // to start showing new one
            this.setState({open: false});
        } else {
            this.processQueue();
        }
    };

    processQueue = () => {
        if (this.queue.length > 0) {
            this.setState({
                messageInfo: this.queue.shift(),
                open: true,
            });
        }
    };

    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({open: false});
    };

    handleExited = () => {
        this.processQueue();
    };

    render() {
        const {message, key, variant} = this.state.messageInfo;
        return (
            <div>
                <Snackbar
                    key={key}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    open={this.state.open}
                    autoHideDuration={6000}
                    onClose={this.handleClose}
                    onExited={this.handleExited}
                >
                    <MySnackbarContentWrapper
                        onClose={this.handleClose}
                        variant={variant}
                        message={message}
                    />
                </Snackbar>
            </div>
        );
    }
}

NotificationMessage.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NotificationMessage);