import React from "react";
import PropTypes from "prop-types";
import {
    Collapse,
    FormControl,
    FormLabel,
    Hidden,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select
} from "@material-ui/core";
import TodayIcon from "@material-ui/icons/Today";
import {withStyles} from '@material-ui/core/styles';
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import useMapFilterStyles from "./MapFilter.style";

const styles = () => ({

    datePicker: {
        display: "flex",
        textAlign: "center"
    },

    filterPickerInput: {
        fontSize: 12,
        textAlign: "center",
        width: "100%"
    },
});

/**
 * Time Period Filter provides the UI to select specific time frame
 * for customizing the time period
 */
const TimePeriodFilter = (props) => {
    const filterStyles = useMapFilterStyles();
    const {classes, timePeriods, selected, onChange} = props;

    // Handle changes to time period picker
    const handleTimePeriodChange = (event) => {
        let timePeriod = event.target.value;

        if (timePeriod === "custom") {
            onChange({
                ...selected,
                selectedTimePeriod: timePeriod
            });
            return;
        }

        let fromDate = moment().subtract(parseInt(timePeriod, 10), 'months').startOf('month')._d;
        let toDate = moment().subtract(1, 'months').endOf('month')._d;
        onChange({
            selectedTimePeriod: timePeriod,
            from: fromDate,
            to: toDate
        });
    };

    // Handle changes to from/to date pickers
    const handleDateChange = filter => (value) => {
        onChange({
                ...selected,
                [filter]: value
            }
        );
    };

    return (
        <FormControl variant="outlined"
                     className={filterStyles.formControl}
                     fullWidth={true}>

            <Hidden smDown implementation="css">
                <FormLabel className={filterStyles.formLabel}
                           component="legend"
                           htmlFor="select-time-period">
                    Time Period
                </FormLabel>
            </Hidden>

            <Select
                value={selected.selectedTimePeriod}
                onChange={handleTimePeriodChange}
                IconComponent={TodayIcon}
                input={
                    <OutlinedInput
                        name="time-period"
                        labelWidth={0}
                        id="select-time-period"
                        classes={{input: filterStyles.input}}
                    />
                }
            >

                {/* Time Periods */}
                {timePeriods.map(timePeriod => (
                    <MenuItem key={timePeriod.key} value={timePeriod.value}>
                        <ListItemText classes={{primary: filterStyles.listItem}}
                                      primary={timePeriod.key}/>
                    </MenuItem>
                ))}
            </Select>

            {/* Custom from-to date pickers */}
            <Collapse in={selected.selectedTimePeriod === "custom"}>
                <div className={classes.datePicker}>
                    <DatePicker
                        selected={selected.from}
                        selectsStart
                        startDate={selected.from}
                        endDate={selected.to}
                        minDate={moment().year(2010)._d}
                        maxDate={selected.to}
                        dateFormat="MMM yyyy"
                        showMonthYearPicker
                        onChange={handleDateChange('from')}
                        className={classes.filterPickerInput}
                        placeholderText="From"
                    />

                    <DatePicker
                        selected={selected.to}
                        selectsEnd
                        startDate={selected.from}
                        minDate={selected.from}
                        maxDate={moment().subtract(1, 'months')._d}
                        endDate={selected.to}
                        dateFormat="MMM yyyy"
                        showMonthYearPicker
                        onChange={handleDateChange('to')}
                        className={classes.filterPickerInput}
                        placeholderText="To"
                    />
                </div>
            </Collapse>

        </FormControl>
    );

};

TimePeriodFilter.propTypes = {
    classes: PropTypes.object.isRequired,
    timePeriods: PropTypes.arrayOf(PropTypes.object).isRequired,
    selected: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired
};

export default withStyles(styles)(TimePeriodFilter);