import useDataApi from "../libs/fetch";
import {API_URL} from "../../constants";

/**
 * Constructs the air quality with date time url
 * @returns {string}
 */
export function getAirQualityWithDateTimeURL(year, month, day, hour) {
    return `${API_URL}/api/health/air_quality/date?year=${year}&month=${month}&day=${day}&hour=${hour}`;
}

/**
 * Get air quality with date time
 */
function useAirQualityWithDateTime(year, month, day, hour) {
    const url = getAirQualityWithDateTimeURL(year, month, day, hour);
    const [airQualityState, setAirQualityStateUrl,] = useDataApi(url, "", []);
    return [airQualityState, setAirQualityStateUrl];
}

export default useAirQualityWithDateTime;