import React, { useEffect } from "react";
import { Grid, Link } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import SearchAddress from "../components/SearchAddress/SearchAddress";
import TrendData from "../utils/TrendData";
import WbCloudyIcon from "@material-ui/icons/WbCloudy";
import PropTypes from "prop-types";
import CardListView from "../components/CardListView";
import Avatar from "@material-ui/core/Avatar";
import CameraRollIcon from "@material-ui/icons/CameraRoll";
import GrainIcon from "@material-ui/icons/Grain";
import FaqReports from "./FaqReports";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import clsx from "clsx";
import MenuIcon from "@material-ui/icons/Menu";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles(() => ({
  root: {
    position: "absolute",
    height: "90%",
    top: "50px",
    padding: "30px",
  },
  drawerPaper: {
    overflowY: "scroll",
    position: "absolute",
    width: "380px",
    height: "100%",
    left: "0px",
    // top: "50px",
    background: "#FFFFFF",
    padding: "30px",
  },
  titleContainer: {
    alignContent: "left",
    background: "#ffffff",
    textAlign: "left",
    borderBottom: "0.5px solid #DEDFE7",
  },
  reportTitle: {
    marginTop: "15px",
    top: "145px",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "800",
    fontSize: "25px",
    lineHeight: "35px",
    textAlign: "left",
    color: "#4F4F4F",
  },
  crosstownTitleContainer: {
    marginTop: "2%",
    lineHeight: "27px",
    textAlign: "left",
    letterSpacing: "0.1em",
  },
  logo: {
    height: "25px",
    marginRight: "10px",
  },
  crosstownTitle: {
    color: "#828282",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "20px",
  },
  titleDesc: {
    marginTop: "15px",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    textAlign: "left",
    color: "#A8A8A8",
    marginBottom: "15px",
  },
  searchBox: {
    borderRadius: "10px",
    width: "18vw",
    left: "5%",
    marginBottom: "3px",
    paddingBottom: "5px",
  },
  searchBoxMobile: {
    top: "0.5rem",
    position: "absolute",
    borderRadius: "10px",
    width: "25vw",
    minWidth: "17rem",
    left: "10%",
    marginBottom: "2px",
    justifySelf: "center",
  },
  dataNotLoaded: {
    position: "absolute",
    width: "273px",
    height: "50px",
    left: "43px",
    top: "305px",
    fontFamily: "Open Sans",
    fontSize: "18px",
    lineHeight: "25px",
    alignItems: "center",
    textAlign: "center",
    color: "#949494",
  },
  reportContent: {
    maxHeight: "400px",
    alignItems: "center",
    justifyContent: "center",
  },
  descTitle: {
    color: "#4F4F4F",
    fontfamily: "Open Sans",
    fontSize: "10px",
    lineHeight: "35px",
    alignItems: "center",
    textTransform: "uppercase",
    marginTop: "10px",
    fontWeight: "800",
  },
  searchName: {
    fontSize: "25px",
    fontWeight: "600",
    textAlign: "center",
    color: "#4c4747de",
  },
  reportFAQContainer: {
    padding: "20px",
    // height: "420px",
    border: "1px solid #E0E0E0",
    boxSizing: "border-box",
    borderRadius: "10px",
    overflowY: "scroll",
  },
  reportCardContainer: {
    alignItems: "center",
    justifyContent: "center",
  },
  reportMapContainer: {
    height: "228px",
    border: "1px solid #E0E0E0",
    boxSizing: "border-box",
    borderRadius: "10px",
  },
  reportMap: {
    marginTop: "-40px",
  },
  LearnMoreBtn: {
    marginTop: "55px",
    width: "100px",
    height: "24px",
    color: "#FFFFFF",
  },
  faqFirst: {
    color: "#898c8c",
    fontFamily: "Open Sans",
    fontSize: "12px",
  },
  faqEnd: {
    color: "#34c6eb",
    fontFamily: "Open Sans",
    fontSize: "12px",
    cursor: "pointer",
  },
  menuButton: {
    position: "absolute",
    marginRight: "1%",
    height: "36px",
    width: "36px",
    borderRadius: "18px",
    backgroundColor: "white",
    boxShadow: "2px 2px 2px black;",
  },
  drawerHeader: {
    marginLeft: "18rem",
    position: "absolute",
  },
  drawerHeaderMobile: {
    top: "-0.35rem",
    marginLeft: "19rem",
  },
  slideBtn: {
    backgroundColor: "#f5f5f5",
    borderRadius: "15px",
    height: "30px",
    width: "30px",
    boxShadow: "1px 1px 1px",
  },
}));

const LeftPanel = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery("(min-width:600px)");
  const { reportTitle, reportTitleDesc, onSearchAddressChanged, statistics } =
    props;
  const [open, setOpen] = React.useState(true);
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (onSearchAddressChanged) {
      setOpen(true);
    }
  }, [onSearchAddressChanged]);
  function getLogo(size) {
    if (props.reportTitle === "Air Quality Report")
      return <WbCloudyIcon style={{ fontSize: size }} />;
    if (props.reportTitle === "Coronavirus Report")
      return <GrainIcon style={{ fontSize: size }} />;
    if (props.reportTitle === "Crime Report")
      return <CameraRollIcon style={{ fontSize: size }} />;
    if (props.reportTitle === "Traffic Report")
      return <WbCloudyIcon style={{ fontSize: size }} />;
  }

  return (
    <div>
      <div className={classes.root}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          className={clsx(classes.menuButton, open && classes.hide)}
        >
          <MenuIcon />
        </IconButton>
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div
            className={
              isDesktop ? classes.drawerHeader : classes.drawerHeaderMobile
            }
          >
            <IconButton
              className={classes.slideBtn}
              onClick={handleDrawerClose}
            >
              {theme.direction === "ltr" ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          </div>
          {/* Title panel */}
          <div className={classes.titleContainer}>
            <Grid
              container
              direction="row"
              className={classes.crosstownTitleContainer}
              justify="left"
            >
              <Avatar
                variant="rounded"
                style={{
                  backgroundColor: "#F2994A",
                  height: "35px",
                  width: "35px",
                }}
              >
                {getLogo(20)}
              </Avatar>
            </Grid>
            <div className={classes.reportTitle}>{reportTitle}</div>
            <div className={classes.titleDesc}>
              Type in your neighborhood to generate report
            </div>
            {!props.isLoadingData && (
              <div
                className={
                  isDesktop ? classes.searchBox : classes.searchBoxMobile
                }
              >
                <SearchAddress
                  onSearchAddressChanged={onSearchAddressChanged}
                />
              </div>
            )}
          </div>
          {/* Report description */}
          {props.selectedFeature == null ? (
            <div className={classes.dataNotLoaded}>
              <div style={{ color: "#dedede" }}>{getLogo(150)}</div>
              {reportTitleDesc}
            </div>
          ) : (
            <div className={classes.reportContent}>
              <div className={classes.descTitle}>
                STATISTICAL OVERVIEW - {props.searchName}
              </div>

              {/* List View */}
              {statistics != null ? (
                <div>
                  <div>
                    <CardListView statistics={statistics} />
                  </div>
                </div>
              ) : (
                <div style={{ color: "rgb(169, 169, 169)", fontSize: "12px" }}>
                  Incomplete data! We do not have all the crime data for this
                  area. We thus decided not to give it orange shading but rather
                  shade it grey. Once we add the missing data, we will add it to
                  our color scale.
                </div>
              )}
              <div className={classes.descTitle}>
                TIMELINE - {props.searchName}
              </div>
              {statistics != null ? (
                <Card className={classes.reportMapContainer} variant="outlined">
                  <div className={classes.reportMap}>
                    <TrendData
                      chartData={props.chartData}
                      reportTitle={props.reportTitle}
                      mapXaxisLabel={props.mapXaxisLabel}
                      mapYaxisLabel={props.mapYaxisLabel}
                      chartTitle={props.chartTitle}
                    />
                  </div>
                </Card>
              ) : (
                <div></div>
              )}

              {props.showFaq ? (
                <div>
                  <div className={classes.descTitle}>FAQ</div>

                  {/* FAQ Container */}
                  <Card
                    className={classes.reportFAQContainer}
                    variant="outlined"
                  >
                    {/* {faq} */}
                    <FaqReports reportTitle={props.reportTitle} />
                  </Card>
                </div>
              ) : (
                <div style={{ textAlign: "center", paddingTop: "40px" }}>
                  <span className={classes.faqFirst}>
                    Having a problem with the map?
                  </span>
                  <Link
                    className={classes.faqEnd}
                    href={"mailto:askus@xtown.la"}
                    target="blank"
                  >
                    Let us know.
                  </Link>
                  {/* <span className={classes.faqEnd}> Let us know.</span> */}
                </div>
              )}
            </div>
          )}
        </Drawer>
      </div>
    </div>
  );
};

LeftPanel.propTypes = {
  selectedFeature: PropTypes.object,
  onSearchAddressChanged: PropTypes.func.isRequired,
};

export default LeftPanel;
