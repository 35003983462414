import React, {useEffect} from 'react';
import './style/styles.css'
import ReactGA from 'react-ga';
export const Feedback = () => {

    useEffect(()=>{
        let N = window, d = document;
        N.gaf = {
            init: function( options )
            {
                N.gaf.options = options;
                N.gaf.loadHtml();
                N.gaf.loadButton();
            },
            loadHtml: function()
            {
                N.gaf.buttonHtml = '<a id="gaf-button" style="" href="#">' + N.gaf.options.open + '</a>';

                N.gaf.dialogHtml = '<div id="gaf-dialog"><h5>' + N.gaf.options.title
                    + '</h5><a id="gaf-dialog-close" href="#">&times;</a>'
                    + '<label class="gaf-text-label" for="gaf-text-1">' + N.gaf.options.question1 + '</label>'
                    + '<input id="gaf-text-1" class="gaf-text" type="text" maxlength="500">'
                    + '<label class="gaf-text-label" for="gaf-text-2">' + N.gaf.options.question2 + '</label>'
                    + '<input id="gaf-text-2" class="gaf-text" type="text" maxlength="500">'
                    + '<a id="gaf-submit" href="#">'
                    + N.gaf.options.send + "</a>";

            },
            loadButton: function()
            {
                d.body.appendChild(N.gaf.createElementFromHTML(N.gaf.buttonHtml));
                d.getElementById('gaf-button').addEventListener( 'click', function(e) {
                    N.gaf.loadDialog();
                    e.preventDefault();
                }, false );
            },
            loadDialog: function()
            {
                d.getElementById('gaf-button').removeEventListener('click', function(){}, false );

                d.body.removeChild( d.getElementById('gaf-button') );

                d.body.appendChild(N.gaf.createElementFromHTML(N.gaf.dialogHtml));
                d.getElementById('gaf-text-1').focus();

                d.getElementById('gaf-dialog-close').addEventListener( 'click', function(e) { N.gaf.closeDialog();e.preventDefault(); }, false );
                d.getElementById('gaf-submit').addEventListener( 'click', function(e) { N.gaf.sendFeedback();e.preventDefault(); }, false );
            },
            closeDialog: function()
            {
                d.getElementById('gaf-dialog-close').removeEventListener('click', function(){}, false );
                d.getElementById('gaf-submit').removeEventListener('click', function(){}, false );

                d.body.removeChild( d.getElementById('gaf-dialog') );

                N.gaf.loadButton();
            },

            sendFeedback: function()
            {
                if ( d.getElementById('gaf-text-1').value.length < 1 )
                {
                    d.getElementById('gaf-text-1').style.border = '2px solid #c00';
                    d.getElementById('gaf-text-1').focus();
                    return false;
                }
                ReactGA.event({
                    category: N.gaf.options.eventCategory,
                    action: "Question1: " + N.gaf.options.question1,
                    label: d.getElementById('gaf-text-1').value,
                    value: 1
                });
                if (d.getElementById("gaf-text-2").value.trim().length > 0) {
                    ReactGA.event({
                        category: N.gaf.options.eventCategory,
                        action: "Question2: " + N.gaf.options.question2,
                        label: d.getElementById('gaf-text-2').value,
                        value: 1
                    });
                }
                alert( N.gaf.options.thankyou );
                N.gaf.closeDialog();
            },
            createElementFromHTML: function(htmlString) {
                let div = document.createElement('div');
                div.innerHTML = htmlString.trim();

                // Change this to div.childNodes to support multiple top-level nodes
                return div.firstChild;
            }
        };
        window.gaf.init( {
            'open': 'Feedback',
            'title': 'We would love to hear your feedback!',
            'eventCategory': 'Feedback Map',
            'question1': 'Please enter your suggestions and complaints:',
            'question2': 'What else information would you like to see on this site:',
            'send': 'Send Feedback',
            'thankyou': 'Thank you for your feedback!'
        });
    }, [])

    return(
        <div id="shareBtns"/>
    );
};