import useDataApi from "../libs/fetch";
import {API_URL} from "../../constants";
import {SAFETY_MAP_MODE_VALUE, TRAJECTORY_MODE_VALUE} from "../../MapFilter";
import Log from "../../../utils/Log";


/**
 * Constructs the search crimes url
 * @param mode The search mode.
 * @param partitionType The type of partition: neighborhoods or zipcodes.
 * @returns {string}
 */
export function getSearchCrimesUrl(mode, partitionType) {
    switch (mode) {
        case SAFETY_MAP_MODE_VALUE:
            return `${API_URL}/api/${partitionType}/crimes?`;
        case TRAJECTORY_MODE_VALUE:
            return `${API_URL}/api/${partitionType}/compare/crimes?`;
        default:
            Log.warn(`Unrecognized mode ${mode}`, getSearchCrimesUrl.name);
            return `${API_URL}/api/${partitionType}/crimes?`;
    }
}

/**
 *
 * @param timePeriod The time period object.
 * @param timePeriod.selectedTimePeriod The selected time period.
 * @param timePeriod.from The from date.
 * @param timePeriod.to The to date.
 * @param bigBundle The big bundle.
 * @param smallBundle The small bundle.
 * @returns {{bb: *, from: (*|number), to: (*|number), sb: *}}
 */
export function getSearchCrimesParams(timePeriod, bigBundle = "", smallBundle) {
    return {
        "from": timePeriod.from.getTime(),
        "to": timePeriod.to.getTime(),
        "bb": bigBundle,
        "sb": smallBundle,
    };
}

/**
 * Search for crimes
 * @param mode The search mode.
 * @param partitionType The type of partition: neighborhoods or zipcodes.
 * @param params The query parameters.
 * @param params.from The from date in milliseconds.
 * @param params.to The to date in milliseconds.
 * @param params.bb The crime category aka big bundle.
 * @param params.sb The crime subcategory aka small bundle.
 */
function useSearchCrimes(mode, partitionType, params) {
    const url = getSearchCrimesUrl(mode, partitionType);
    const [searchCrimesState, setSearchCrimesUrl, setSearchCrimesParams] = useDataApi(url, params, []);
    return [searchCrimesState, setSearchCrimesUrl, setSearchCrimesParams];
}

export default useSearchCrimes