import {scaleQuantile} from "d3-scale";
import {range} from "d3-array";

import {CORONAVIRUS_MAP_LEGEND_COLORS} from '../config/settings'

export function updateCoronavirusPercentiles(data, result, id = "id") {
    const {features} = data;

    let stats = {
        "Total Cases": "N/A",
        "Population": "N/A",
    };

    // Initialize all features (some may not appear in result)
    features.forEach(f => {
        f.properties.caption = "No cases/data reported";
        f.properties.outlier = true;
        f.properties.stats = Object.assign({}, stats);
    });

    // Add total cases counts
    result.forEach(r => {
        let f = features.find(f => f.properties[id] === r[id]);
        if (f) {
            // Means that case numbers are suppressed (between 1 and 4 cases in communities <25,000 people).
            const is_suppressed = r.total_cases === null;
            f.properties.is_suppressed = is_suppressed;
            f.properties.total_cases = r.total_cases;
            f.properties.date = r.date;
            f.properties.caption = is_suppressed ? `Case numbers are suppressed` : `${r.total_cases} total cases`;
            f.properties.outlier = false;
            f.properties.ranking = r.ranking; // Suppressed cases get same ranking as zero cases
            f.properties.infection_rate = r.infection_rate;
            f.properties.infection_rate_per_100K = is_suppressed ? 0 : r.infection_rate * 100000;
            f.properties.total_population = r.total_population.toLocaleString();

            f.properties.stats = {
                "Total Cases": is_suppressed ?  "--" : f.properties.total_cases,
                "Population": f.properties.total_population,
            }
        }
    });

    let domain = [];
    features.forEach(f => {
        if (f.properties.total_cases)
            domain.push(f.properties.infection_rate_per_100K);
    });

    const scale = scaleQuantile()
        .domain(domain)
        .range(range(CORONAVIRUS_MAP_LEGEND_COLORS.length));

    // Add percentile property
    features.forEach(f => {
        f.properties.percentile = scale(f.properties.infection_rate_per_100K);
    });
}