import React from 'react';
import PropTypes from 'prop-types';
import {
    Checkbox,
    FormControl,
    FormLabel,
    Hidden,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select
} from '@material-ui/core';
import CrimesIcon from '../icons/Crimes';
import useMapFilterStyles from "./MapFilter.style";

const SmallBundlesFilter = (props) => {
    const classes = useMapFilterStyles();
    const {disabled, smallBundles, selected, onChange} = props;

    return (
        <FormControl variant="outlined"
                     className={classes.formControl}
                     fullWidth={true}>

            <Hidden smDown implementation="css">
                <FormLabel
                    className={classes.formLabel}
                    component="legend"
                    htmlFor="select-multiple-small-bundle-types">
                    Crime Types
                </FormLabel>
            </Hidden>
            <Select
                multiple
                displayEmpty
                value={selected}
                onChange={onChange}
                IconComponent={CrimesIcon}
                renderValue={selected => {
                    if (selected.length === 0) {
                        return <em>All</em>;
                    } else if (selected.length >= 3) {
                        return selected.length + " selected";
                    }
                    return selected.join(', ');
                }}
                input={
                    <OutlinedInput
                        disabled={disabled}
                        name="small-bundle-types"
                        labelWidth={0}
                        id="select-multiple-small-bundle-types"
                        classes={{input: classes.input}}
                    />
                }>

                <MenuItem disabled key="All" value="">
                    <Checkbox checked={selected.length === 0}/>
                    <ListItemText classes={{primary: classes.listItem}} primary="All"/>
                </MenuItem>
                {
                    smallBundles.map(smallBundleFilter => (
                        <MenuItem key={smallBundleFilter.key} value={smallBundleFilter.value}>
                            <Checkbox
                                checked={selected.indexOf(smallBundleFilter.value) > -1}/>
                            <ListItemText classes={{primary: classes.listItem}}
                                          primary={smallBundleFilter.key}/>
                        </MenuItem>
                    ))
                }
            </Select>
        </FormControl>
    );

};

SmallBundlesFilter.propTypes = {
    disabled: PropTypes.bool.isRequired,
    smallBundles: PropTypes.arrayOf(PropTypes.object).isRequired,
    selected: PropTypes.arrayOf(PropTypes.string).isRequired,
    onChange: PropTypes.func.isRequired
};

export default SmallBundlesFilter;