import MAP_STYLE from '../../../config/map-style.json';
import MAP_DATA from './los_angeles_map'
// Make a copy of the map style
const neighborhoodMapStyle = {
    ...MAP_STYLE,
    sources: {...MAP_STYLE.sources},
    layers: MAP_STYLE.layers.slice(),
    isComplte: false,
    updateLayers: function(data){
        this.sources['la-neighborhoods'].data = (data);
        // return a new deeply copied object to force re-render
        return JSON.parse(JSON.stringify(this));
    }

};


neighborhoodMapStyle.sources['la-neighborhoods'] = {
    type: 'geojson',
    data: MAP_DATA
};

// neighborhoodMapStyle.updateLayers({});

neighborhoodMapStyle.layers.push({
    id: 'la-neighborhoods-fill',
    source: 'la-neighborhoods',
    type: 'fill',
    paint: {
        'fill-outline-color': '#0040c8',
        'fill-color': '#fff',
        'fill-opacity': 0
    }
}, {
    id: 'la-neighborhoods-outline',
    source: 'la-neighborhoods',
    type: 'line',
    paint: {
        'line-width': 2,
        'line-color': '#0040c8'
    }
});

export {neighborhoodMapStyle};