import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import {withStyles} from "@material-ui/core/styles/index";
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
// import Tab from '@material-ui/core/Tab';
import './style/styles.css'

const styles = theme => ({
    tab:{
        minWidth: '30px',
        [theme.breakpoints.up('md')]: {
            minWidth: '50px',
        },
        labelContainer:{
            padding: '6px 10px',
            [theme.breakpoints.up('md')]: {
                padding: '6px 20px',
            },
        }

    },
    tabContainer:{
        color: '#000',
        backgroundColor: '#fff'
    },
    indicator:{
        backgroundColor: "#ff9700"
    },
    introCaption:{
        fontFamily: 'Helvetica',
        fontSize: '14px',
        fontWeight: '400',
        color: '#383838',
        letterSpacing: '0.7px',
        display: 'none',
        [theme.breakpoints.up('md')]: {
            fontSize: '16px',
            color: '#383838',
            letterSpacing: '1.1px',
            display: 'flex'
        },
    },
    tabPadding:{
        padding: '3px 10px',
        [theme.breakpoints.up('md')]: {
            padding: '3px 10px',
        },
    },
    tabRoot:{
        fontFamily: 'Helvetica',
        fontSize: '14px',
        fontWeight: '400',
        color: '#383838',
        letterSpacing: '0.7px',
        textTransform: 'none',
        minHeight: '35px',
        [theme.breakpoints.up('md')]: {
            fontSize: '16px',
            color: '#383838',
            letterSpacing: '1.1px',
        },
    }

});

class TabBar extends Component{

    state = {
        value: 0,
    };

    handleChange = (event, value) => {
        this.setState({ value });
    };

    render(){
        const { mode } = this.props;

        const crime_intro = 'Monthly updates on crime trends in the City of Los Angeles';
        const nh_intro = 'Monthly updates on crime trends in the City of Los Angeles';
        const aqi_intro = 'Monthly updates on air quality index in the City of Los Angeles';

        const {classes} = this.props;

        // TabIndicatorProps only accept classes, withStyle doesn't work
        const indicatorStyle = {
            style: {
                backgroundColor: "#ff9700"
            }
        };
        return(

            <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
            >
                <Typography component="span" className={classes.introCaption}>
                    {mode === 'crime' ? crime_intro : mode === 'aqi' ? aqi_intro : mode === 'neighborhood' ? nh_intro : ''}
                </Typography>

                <Tabs
                    value={this.state.value}
                    className={classes.tabContainer}
                    onChange={this.handleChange}
                    TabIndicatorProps={indicatorStyle}
                >
                    {/*<Tab className={classes.tab} classes={{labelContainer:  classes.tabPadding, root: classes.tabRoot}} disableRipple label="Crime"/>*/}
                    {/*<Tab className={classes.tab} classes={{labelContainer:  classes.tabPadding, root: classes.tabRoot}} disableRipple disabled label="Traffic"/>*/}
                    {/*<Tab className={classes.tab} classes={{labelContainer:  classes.tabPadding, root: classes.tabRoot}} disableRipple disabled label="Health" />*/}
                </Tabs>
            </Grid>


        );



    }

}



export default withStyles(styles, {withTheme: false})(TabBar)