import {scaleQuantile} from "d3-scale";
import {range} from "d3-array";

import {MAP_LEGEND_COLORS} from '../config/settings'

export function updatePercentiles(data, result, id = "id", selectedSmallBundleFilterCrimes, selectedBigBundleFilterCrime) {
    if (data === null) return;
    if (!('features' in data)) return;
    const {features} = data;
    let max_crime_count = Number.NEGATIVE_INFINITY, min_crime_count = Number.POSITIVE_INFINITY;
    let max_crime_rate = Number.NEGATIVE_INFINITY, min_crime_rate = Number.POSITIVE_INFINITY;

    let crime_stats = {};
    crime_stats["All"] = 0;
    selectedSmallBundleFilterCrimes.forEach(crime => {
        crime_stats[crime] = 0;
    });
    // Reset feature crime counts
    features.forEach(f => {
        f.properties.crime_counts = {};
        f.properties.crime_stats = Object.assign({}, crime_stats);
        f.properties.population = 0;
        f.properties.total_crime_counts = 0;
        f.properties.total_crime_rate = 0;
        f.properties.total_crime_rate_per_100K = 0;
        f.properties.ranking = 'N/A';
        f.properties.outlier = false;
    });

    // Add crime counts
    result.forEach(r => {
        let f = features.find(f => f.properties[id] === r[id]);
        if (f) {

            f.properties.population = parseInt(r.population, 10);
            f.properties.total_crime_counts = parseInt(r.total_crime_count, 10);
            f.properties.total_crime_rate = parseFloat(r.total_crime_rate);

            if (r.ranking != null) {
                f.properties.ranking = parseInt(r.ranking, 10);
            }

            f.properties.outlier = f.properties.total_crime_rate === 0;

            // Ignore outliers and incomplete areas
            if (!f.properties.outlier && f.properties.complete)
                f.properties.total_crime_rate_per_100K = f.properties.total_crime_rate * 100000;
            else {
                f.properties.total_crime_rate = null;
                f.properties.total_crime_rate_per_100K = null;
            }

            f.properties.big_bundles = r.big_bundles;

            let big_bundles = r.big_bundles;
            big_bundles.forEach(function (big_bundle) {

                let small_bundles = big_bundle.small_bundles;
                small_bundles.forEach(function (small_bundle) {

                    let nicknames = small_bundle.nicknames;
                    nicknames.forEach(function (nickname) {
                        let crime_type = nickname.crime_type;
                        f.properties.crime_counts[crime_type] = parseInt(nickname.crime_count, 10);
                    });
                });
            });


            min_crime_rate = Math.min(min_crime_rate, f.properties.total_crime_rate_per_100K);
            max_crime_rate = Math.max(max_crime_rate, f.properties.total_crime_rate_per_100K);
            min_crime_count = Math.min(min_crime_count, f.properties.total_crime_counts);
            max_crime_count = Math.max(max_crime_count, f.properties.total_crime_counts);
        }
    });


    let domain = [];
    features.forEach(f => {
        domain.push(parseFloat(f.properties.total_crime_rate_per_100K));
    });

    const scale = scaleQuantile().domain(domain).range(range(MAP_LEGEND_COLORS.length));

    // Add percentile property
    features.forEach(f => {
        if (!f.properties.outlier && f.properties.complete) {
            if (f.properties.total_crime_rate_per_100K === 0) {
                f.properties.percentile = 0;
            } else {
                f.properties.percentile = scale(f.properties.total_crime_rate_per_100K);
            }
        }
    });

    features.forEach(f => {
        for (let crime_type in f.properties.crime_stats) {

            if (crime_type === "All") {
                f.properties.crime_stats[crime_type] = f.properties.total_crime_counts;
            }

            let found = false;
            for (let j in f.properties.big_bundles) {
                let big_bundle = f.properties.big_bundles[j];

                if (crime_type === big_bundle.name) {
                    f.properties.crime_stats[crime_type] += big_bundle.total_crime_count;
                    found = true;
                    break;
                }
            }

            if (found) {
                continue;
            }

            for (let j in f.properties.big_bundles) {
                let big_bundle = f.properties.big_bundles[j];
                for (let k in big_bundle.small_bundles) {
                    let small_bundle = big_bundle.small_bundles[k];
                    if (crime_type === small_bundle.name) {
                        f.properties.crime_stats[crime_type] += small_bundle.total_crime_count;
                        found = true;
                        break;
                    }
                }

                if (found)
                    break;
            }
        }
    });
}