import React from "react";
import LineGraph from "../components/LineGraph";

const TrendData = (props) => {
  const colorScale = ["#2D9CDB"];

  return (
    <div>
      {props.chartData == null ? (
        ""
      ) : (
        <LineGraph
          data={props.chartData}
          xAxisLabel={props.mapXaxisLabel}
          yAxisLabel={props.mapYaxisLabel}
          colorScale={colorScale}
        />
      )}
    </div>
  );
};

export default TrendData;
