import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import {withStyles} from "@material-ui/core/styles/index";
import Typography from '@material-ui/core/Typography';
import PubSub from "pubsub-js";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {SELECTED_PLACE_SUGGESTION} from "../../config/settings";
import Log from "../../utils/Log";
import {API_URL} from "../constants";

const styles = theme => ({
    cellContainer:{
        padding: '4px 10px 4px 10px',
        [theme.breakpoints.up('md')]:{
            padding: '4px 56px 4px 24px'
        }
    },

    highlightedCellContainer:{
        padding: '4px 10px 4px 10px',
        [theme.breakpoints.up('md')]:{
            padding: '4px 56px 4px 24px'
        },
        backgroundColor: 'orange'
    },

    tableTitleRoot:{
        fontFamily: 'Rambla',
        fontSize: '16px',
        fontWeight: '700',
        color: '#383838',
        letterSpacing: '1.2px'
    }
});

class AQIRankTable extends Component{

    constructor(props){
        super(props);
        this.state = {
            hasData: false
        }
    }

    componentDidMount(){
        PubSub.subscribe(SELECTED_PLACE_SUGGESTION, this.subscribe);
    }

    componentWillUnmount(){
        PubSub.clearAllSubscriptions();
    }

    _fetchAQIRankTableData() {
        let {neighborhood} = this.state;

        let date = new Date();
        // date.setFullYear( date.getFullYear() - 1 );
        date = date.toISOString().split(".")[0];
        let datetime = date.split("T")[0] + " " + date.split("T")[1];

        let url = API_URL + '/api/air-quality/ranking?datetime=' + datetime + '&neighborhood=' + neighborhood;
        fetch(url)
            .then((response) => {return response.json()})
            .then(json => {
                let ranks = json.result.data;
                Log.info("ranks ", ranks);
                this.setState({
                    rankTableData: ranks,
                    hasData: true
                })
            });

    }

    subscribe = (msg, data)=>{
        switch(msg){
            case SELECTED_PLACE_SUGGESTION:
                if(data.label === undefined){
                    this.setState({
                        hasData: false
                    })
                }
                else{
                    this.setState({
                        hasData: false,
                        center: data.center,
                        neighborhood: data.neighborhood
                    }, function(){
                        this._fetchAQIRankTableData();
                    });
                }
                break;
            default:
                Log.warn(msg);
        }
    };

    render(){
        const {classes} = this.props;
        const title = 'Air Quality Ranking';

        return(
            <Grid
                container
            >
                {/*{this.state.hasData?rankTable:null}*/}
                <Grid item xs={12}>
                    <Typography variant={'subtitle2'} align={'left'} className={classes.subTitle} classes={{root:classes.tableTitleRoot}}>
                        {title}
                    </Typography>
                </Grid>


                {this.state.hasData ?
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center" className={classes.cellContainer}>Rank</TableCell>
                                <TableCell align="center" className={classes.cellContainer}>Neighborhood</TableCell>
                                <TableCell align="center" className={classes.cellContainer}>AQI</TableCell>
                                <TableCell align="center" className={classes.cellContainer}>AQI Category</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.rankTableData.map((row, idx) => (
                                <TableRow key={idx}>
                                    <TableCell component="th" scope="row" className={row.neighborhood === this.state.neighborhood ? classes.highlightedCellContainer : classes.cellContainer}>
                                        {row.rank}
                                    </TableCell>
                                    <TableCell align="center" className={row.neighborhood === this.state.neighborhood ? classes.highlightedCellContainer : classes.cellContainer}>{row.name}</TableCell>
                                    <TableCell align="center" className={row.neighborhood === this.state.neighborhood ? classes.highlightedCellContainer : classes.cellContainer}>{parseFloat(row.aqi).toFixed(2)}</TableCell>
                                    <TableCell align="center" className={row.neighborhood === this.state.neighborhood ? classes.highlightedCellContainer : classes.cellContainer}>{row.aqi_category}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                :null}

            </Grid>
        );
    }

}


export default withStyles(styles, {withTheme: true})(AQIRankTable)