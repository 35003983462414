// Main Menu Drawer : Implementation on click of top left menu button
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import CommuteIcon from "@material-ui/icons/Commute";
import HomeWorkIcon from "@material-ui/icons/HomeWork";
import AddBoxIcon from "@material-ui/icons/AddBox";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import CloudIcon from "@material-ui/icons/Cloud";
import EmojiTransportationIcon from "@material-ui/icons/EmojiTransportation";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import HouseIcon from "@material-ui/icons/House";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import {
  CORONAVIRUS_MAP_URL,
  AIR_QUALITY_MAP_URL,
  MAP_URL,
} from "./constants.js";

const useStyles = makeStyles(() => ({
  container: {
    width: "315px",
    background: "#F5F5F5",
    height: "100%",
    padding: "30px",
  },
  drawerContent: {},
  searchContainer: {
    height: "10%",
  },
  searchBar: {
    width: "243.99px",
    height: "32.93px",
    border: "1px solid #000000",
    boxSizing: "border-box",
    borderRadius: "5px",
    padding: "5px",
  },
  searchIcon: {},
  searchLabel: {
    color: "#666666",
  },
  topicsContainer: {
    marginTop: "10px",
    width: "100%",
  },
  contentHeading: {
    color: "#909090",
    borderBottom: "0.5px solid #909090",
    borderWidth: "100%",
    fontFamily: "Open Sans",
    fontWeight: "bold",
    fontSize: "12px",
    lineHeight: "16px",
    paddingBottom: "10px",
  },
  contentRowTitle: {
    fontFamily: "Open Sans",
    fontWeight: "800",
    fontSize: "13px",
    lineHeight: "18px",
    alignItems: "center",
    color: "#5E5E5E",
  },
}));

const MenuLeftDrawer = () => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const searchBar = () => (
    <Grid container className={classes.searchBar}>
      <Grid item xs={11}>
        <label className={classes.searchLabel}>Search Crosstown Site</label>
      </Grid>
      <Grid item xs={1}>
        <SearchIcon style={{ fontSize: 20 }} className={classes.searchIcon} />
      </Grid>
    </Grid>
  );

  const storyData = [
    {
      id: 1,
      label: "Crime",
      url: "https://xtown.la/category/crime",
    },
    {
      id: 2,
      label: "Health",
      url: "https://xtown.la/category/health",
    },
    {
      id: 3,
      label: "Traffic",
      url: "https://xtown.la/category/traffic",
    },
  ];
  const toolsData = [
    {
      id: 1,
      label: "Air Quality Map",
      url: AIR_QUALITY_MAP_URL,
    },
    {
      id: 2,
      label: "Coronavirus Cases",
      url: CORONAVIRUS_MAP_URL,
    },
    {
      id: 3,
      label: "Crime Report",
      url: MAP_URL,
    },
  ];
  const aboutCrosstownData = [
    {
      id: 1,
      label: "About Us",
      url: "https://xtown.la/about-us",
    },
    {
      id: 2,
      label: "Newsletter sign up",
      url: "https://newsletter.xtown.la/#/",
    },
    {
      id: 3,
      label: "Our data",
      url: "https://xtown.la/our-data/",
    },
  ];
  const drawer = (anchor) => (
    <div
      className={classes.container}
      role="presentation"
      // onClick={toggleDrawer(anchor, false)}
    >
      <Grid container className={classes.drawerContent} spacing={4}>
        {/* Search Box */}
        <Grid item className={classes.searchContainer}>
          {searchBar()}
        </Grid>
        {/* Crosstown Tools */}
        <Grid item container className={classes.toolsContainer}>
          <Grid item xs={12} className={classes.contentHeading}>
            CROSSTOWN TOOLS
          </Grid>
          <Grid item xs={12}>
            <Grid item xs={12}>
              {toolsData.map((tool, index) => (
                <ListItem button component="a" key={tool.id} href={tool.url}>
                  <ListItemIcon>
                    {(() => {
                      switch (index) {
                        case 0:
                          return <CloudIcon />;
                        case 1:
                          return <EmojiTransportationIcon />;
                        case 2:
                          return <AddBoxIcon />;
                        case 3:
                          return <LocationOnIcon />;
                        case 4:
                          return <HouseIcon />;
                        default:
                          return <CloudIcon />;
                      }
                    })()}
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    className={classes.contentRowTitle}
                    primary={tool.label}
                  />
                  <ChevronRightIcon style={{ fontSize: 15 }} />
                </ListItem>
              ))}
            </Grid>
          </Grid>
        </Grid>
        {/* Story Topics */}
        <Grid container item className={classes.topicsContainer}>
          <Grid item xs={12} className={classes.contentHeading}>
            Stories
          </Grid>
          <Grid item xs={12}>
            <Grid item xs={12}>
              {storyData.map((story, index) => (
                <ListItem
                  button
                  key={story.label}
                  component="a"
                  href={story.url}
                >
                  <ListItemIcon>
                    {index === 0 ? (
                      <HomeWorkIcon />
                    ) : index === 1 ? (
                      <AddBoxIcon />
                    ) : (
                      <CommuteIcon />
                    )}
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    className={classes.contentRowTitle}
                    primary={story.label}
                  />
                  <ChevronRightIcon style={{ fontSize: 15 }} />
                </ListItem>
              ))}
            </Grid>
          </Grid>
        </Grid>
        {/* About Crosstown */}
        <Grid item container className={classes.aboutContainer}>
          <Grid item xs={12} className={classes.contentHeading}>
            ABOUT CROSSTOWN
          </Grid>
          <Grid item xs={12}>
            {aboutCrosstownData.map((item) => (
              <ListItem
                button
                component="a"
                key={item.id}
                href={item.url}
                target="blank"
              >
                <ListItemText
                  disableTypography
                  className={classes.contentRowTitle}
                  primary={item.label}
                />
                <ChevronRightIcon style={{ fontSize: 15, right: "0px" }} />
              </ListItem>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );

  return (
    <div>
      <div>
        <Button onClick={toggleDrawer("left", true)}>
          <MenuIcon style={{ color: "#B1B9C2" }} />
        </Button>
        <ClickAwayListener onClickAway={toggleDrawer("left", true)}>
          <Drawer
            anchor={"left"}
            open={state["left"]}
            onClose={toggleDrawer("left", false)}
          >
            {drawer("left")}
          </Drawer>
        </ClickAwayListener>
      </div>
    </div>
  );
};

export default MenuLeftDrawer;
