import React, { useState, useEffect} from 'react';
import {withStyles} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import NavBar from "./NavBar";
import TabBar from "./TabBar";
import About from "./About";
import Footer from "./Footer";
import {Feedback} from "./Feedback";
import SubscribeBar from "./SubscribeBar";
import SearchBar from "../tracker/SearchBar";
import Trie from "../../utils/Trie";
import Log from "../../utils/Log";
import {
    API_URL, DEFAULT_CRIME,
    DEFAULT_NEIGHBORHOOD_Id, MONTHNAMES
} from "../constants";
import TrackerContent from "./TrackerContent";
import LocationBar from "./LocationBar";
import MessageSnackBar from "./MessageSnackBar";
import {Loader} from "./Loader";

const styles = theme => ({
    root: {
        width: '100%'
    },
    body: {
        [theme.breakpoints.up('md')]: {
            width: '70%',
        },
        [theme.breakpoints.up('lg')]: {
            width: '80%',
        },
        [theme.breakpoints.up('xl')]: {
            width: '70%',
        },
        width: '90%',
        margin: 'auto',
        padding: '10px 0'
    },
    footer: {
        backgroundColor: '#3C3C3C',
        color: '#eee'
    },
    mobileGrid: {
        padding: '5px 0'
    },
    sideBarContainer:{
        padding: '0px 5px',
        [theme.breakpoints.up('md')]:{
            borderRightWidth: '0.5px',
            borderRightColor: '#d1d5da',
            borderRightStyle: 'solid',
            padding: '0px 19px',
            paddingLeft: '0',
            height: '500px'
        }
    },
    locationContainer:{
        display: 'none',
        [theme.breakpoints.up('md')]:{
            display: 'flex'
        }
    },
    searchBarContainer: {
        padding: '0',
        [theme.breakpoints.up('md')]: {
        }
    },
    subTitle: {
        display: 'inline-block',
    },
    subTitle2:{
        display: 'none',
        [theme.breakpoints.up('md')]:{
            display: 'flex',
        }
    },

});

const Tracker = (props) => {

    const {mode, classes} = props;

    const lastPathName = document.location.pathname.split('/')[2];

    const [searchItemId, setSearchItemId] = useState(lastPathName);
    const [itemNameToId, setItemNameToId] = useState({});
    const [sideBarTitle, setSideBarTitle] = useState(restoreNeighborURLName(lastPathName));
    const [searchBarInfo, setSearchBarInfo] = useState(new Trie());
    const [mapStyle, setMapStyle]= useState(null);
    const [crimeCTableData, setCrimeCTableData] = useState(null);
    const [crimeNTableData, setCrimeNTableData] = useState(null);
    const [crimeRateData, setCrimeRateData] = useState(null);
    const [crimeChartData, setCrimeChartData] = useState(null);
    const [NTableMode, setNTableMode] = useState('monthly');
    const [CTableMode, setCTableMode] = useState({history: 'monthly', partition: 'neighborhood'});
    const [subscribeEmail, setSubscribeEmail] = useState(null);
    const [isMobile, setIsMobile] = useState(false);
    const [subSnackBar, setSubSnackBar] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    let checksum = 0;
    const month = new Date().getMonth();
    const year = new Date().getFullYear();
    const dateTitle = `${MONTHNAMES[month]} ${year}`;
    // wait for 4 fetch functions at neighbor mode, 2 at crime mode
    const modeChecksum = mode==='neighborhood'?4:2;
    

    useEffect(()=>{
        function isMobile(){
            setIsMobile(Math.min(window.innerWidth, window.outerWidth)<400);
        }
        window.addEventListener('resize', isMobile);
        return () => {
            window.removeEventListener('resize', isMobile);
        }
    }, []);

    // an effect hook to retrieve search bar information
    useEffect(()=>{
        if(mode === 'neighborhood'){
            fetchNeighborNames();
        }
        else if(mode === 'crime'){
            fetchCrimeNames();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mode]);

    useEffect(()=>{
        setIsLoading(true)
        switch (mode) {
            case 'neighborhood':
                fetchCrimeChartDataByNeighbor();
                fetchNeighborMapGeoData();
                fetchCrimeRankData();
                fetchCrimeTableDataByNeighbor(NTableMode);
                break;
            case 'crime':
                fetchCrimeChartDataByCrime(searchItemId);
                fetchCrimeTableDataByCrime(CTableMode);
                break;
            default:
                break;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchItemId]);

    useEffect(()=>{
        if (mode === 'neighbor') {
            fetchCrimeTableDataByNeighbor(NTableMode);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [NTableMode]);

    useEffect(()=>{
        if (mode === 'crime'){
            fetchCrimeTableDataByCrime(CTableMode);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [CTableMode]);


    useEffect(()=>{
        if(subscribeEmail) {
            closeAllSnackBars();
            // email is empty
            if (!subscribeEmail || subscribeEmail.length === 0) {
               setSubSnackBar({
                   variant: 'warning',
                   message: 'User email cannot be empty!',
                   isMobile: isMobile
               });
            }
            // incorrect email format
            else if (subscribeEmail.indexOf('@') < 0) {
                setSubSnackBar({
                    variant: 'warning',
                    message: 'Please include \'@\' in the email address!',
                    isMobile: isMobile
                });
            }
            else {
                // clear the previous subscription error message
                let url = API_URL + '/api/subscriptions/subscribers';
                let reqBody = {
                    neighborhoods: [searchItemId],
                    email: subscribeEmail
                };
                fetch(url, {
                    method: 'POST',
                    body: JSON.stringify(reqBody),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then(res => {
                    if (res.status === 200 || res.status === 409) {
                        res.json().then(reqBody => {
                            // no hash returned on second submit of same email address
                            if (reqBody.merge_fields !== undefined) {
                                let email = reqBody.email_address;
                                let {SUB_HASH, PASS_HASH} = reqBody.merge_fields;
                                let subPageLink = SUB_HASH + '?email='+email+ '&pwdHash=' + PASS_HASH

                                if (res.status === 200) {
                                    setSubSnackBar({
                                        variant: 'success',
                                        message: 'Thank you for your subscription! You can modify it anytime ',
                                        link: subPageLink,
                                        isMobile: isMobile
                                    });
                                }
                                else {
                                    setSubSnackBar({
                                        variant: 'warning',
                                        message: 'You are a returning user. Check your latest subscription ',
                                        link: subPageLink,
                                        isMobile: isMobile
                                    });
                                }
                            }
                        });
                    } else {
                        res.json().then(body => {
                            setSubSnackBar({
                                variant: 'error',
                                message: `Failed to subscribe: ${body.error}`,
                                isMobile: isMobile
                            });
                        });
                    }
                }).catch(error => {
                    Log.error("Failed to subscribe user", error);
                    setSubSnackBar({
                        variant: 'error',
                        message: `Failed to subscribe: ${error}`,
                        isMobile: isMobile
                    });
                })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subscribeEmail]);

    function addChecksum() {
        checksum ++;
        if(checksum === modeChecksum) {
            checksum = 0;
            setTimeout(()=>{setIsLoading(false)}, 1000);
        }
    }
    
    function fetchNeighborNames(){
        fetch(API_URL + '/api/neighborhoods/names')
            .then(res=>res.json())
            .then(data=>{
                let neighborList = data.result.data;
                let neighborNameToIdMap = {};
                neighborList.sort((a,b)=>a.name.localeCompare(b.name));
                let neighborNames = new Trie();
                neighborList.forEach(value => {
                    neighborNames.insert(value.name);
                    neighborNameToIdMap[value.name] = value.neighborhood
                });
                setSearchBarInfo(neighborNames);
                setItemNameToId(neighborNameToIdMap)
            }).catch(error =>{
                Log.error("Failed to fetch neighbor names", error);
            });
    }

    function fetchCrimeNames(){
        fetch(API_URL+'/api/bundles/small')
            .then(res=>res.json())
            .then(data=>{
                let crimeTypes = new Trie();
                let itemNameToId = {};
                data.forEach(crime  =>{
                    crimeTypes.insert(restoreCrimeType(crime.name));
                    itemNameToId[restoreCrimeType(crime.name)] = crime.name
                });
                setSearchBarInfo(crimeTypes);
                setItemNameToId(itemNameToId)
            }).catch(error =>{
                Log.error("Failed to fetch crime names", error);
        })
    }

    function changeHistoryRangeHandler(e){
        let newMode = e.target.checked ? 'yearly':'monthly';
        if(mode === 'crime'){
            setCTableMode((prev)=>({
                ...prev,
                history: newMode
            }))
        }
        else{
            setNTableMode(newMode);
        }
    }

    function changePartitionHandler(e){
        let newMode = e.target.checked ?'zipcode':'neighborhood';
        setCTableMode((prev)=>({
            ...prev,
            partition: newMode
        }));
    }

    function searchBarHandler(itemId){
        setSearchItemId(itemId);
        setSideBarTitle(restoreNeighborURLName(itemId));
        window.history.pushState(null, null, itemId);
    }

    function subscribeBarHandler(email){
        setSubscribeEmail(email);
    }

    // fetch data for crime chart
    function fetchCrimeChartDataByNeighbor(){
        fetch(API_URL + "/api/neighborhoods/" + searchItemId + "/monthly")
            .then(res => res.json())
            .then(data => {
                // If input area name is empty, use the default neighborhood name and start over
                // Add a messenger bar later
                if (data.result["data"].length === 0) {
                    setSearchItemId(DEFAULT_NEIGHBORHOOD_Id);
                    // this.checkSum = 0;
                    window.history.pushState(null, null, 'downtown');
                    return;
                }

                let chartData = data.result["data"][0]["trends"];
                let xAxis = [];
                let yAxis = [];
                chartData.forEach((value) => {
                    xAxis.push(value.year + "." + value.month);
                    yAxis.push(parseInt(value.crime_count));
                });
                setCrimeChartData({
                    crimeType: 'All Crimes',
                    xAxis,
                    yAxis,
                    xTitle: 'Total Crimes',
                    yTitle: 'Count of Crimes'

                });
                addChecksum();
            }).catch(error => {
                // setChecksum(checksum+1);
                addChecksum();
                Log.error('failed to fetch chart data!', error);
            });
    }

    function fetchCrimeChartDataByCrime(crimeType){
        fetch(`${API_URL}/api/crimes/${crimeType}/monthly/${month}/${year}`)
            .then(res => res.json())
            .then(data => {
                // If the crime type is invalid, use the default crime type instead and start over
                if (data.result.data.length === 0) {
                    setCrimeChartData({
                        crimeType: DEFAULT_CRIME
                    });
                    window.history.pushState(null, null, 'burglary');
                    return;
                }
                let xAxis = [], yAxis = [];
                let chartData = data.result["data"];
                chartData.forEach((value) => {
                    xAxis.push(value.year + "." + value.month);
                    yAxis.push(parseInt(value.num));
                });
                let crimeType = restoreCrimeType(searchItemId);
                setCrimeChartData({
                    crimeType: crimeType,
                    xAxis,
                    yAxis,
                    xTitle: crimeType,
                    yTitle: 'Count of Crimes'
                });
                window.history.pushState(null, null, crimeType);
                addChecksum();
            }).catch(error=>{
                addChecksum();
                Log.error('fetch crime chart data', error)
            });
    }

    // fetch crime ranking table
    function fetchCrimeTableDataByNeighbor(tableMode){
        fetch(API_URL +
            `/api/neighborhoods/crime_ranking?neighborhood=${searchItemId}&aggregation=${tableMode}`)
            .then(res => res.json())
            .then(data => {
                const bundleList = data.result['data'].slice(0, Math.min(data.result['data'].length, 10));
                setCrimeNTableData(bundleList);
                addChecksum();
            })
            .catch(error => {
                // setChecksum(checksum+1);
                addChecksum();
                Log.error('fail to fetch crime rank table data with neighbor name', error);
            });
    }

    function fetchCrimeTableDataByCrime(tableMode){
        let crimeType = restoreCrimeType(searchItemId);
        fetch(API_URL +
            `/api/crimes/neighborhood_ranking?partition=${tableMode.partition}&crime=${crimeType}&aggregation=${tableMode.history}`)
            .then(res => res.json())
            .then(data => {
                let dataList = data.result.data;
                let crimeTableRows = [];
                dataList.forEach(value => {
                    // let num = parseInt(value.crime_rate);
                    let columns = {
                        ranking: value.ranking,
                        neighborhood: value.neighborhood,
                        zipcode: value.zipcode,
                        crimeCount: value.total_crimes,
                        crimeDiff: value.diff_count,
                        crimeRankDiff: value.diff,
                        crimeMassRate: (parseFloat(value.crime_rate) * 100000).toFixed(2)
                    };
                    crimeTableRows.push(columns);
                });
                // setChecksum(checksum+1);
                addChecksum();
                setCrimeCTableData({crimeTableRows, crimeType: restoreCrimeType(searchItemId)});
            })
            .catch(error => {
                addChecksum();
                // setChecksum(checksum+1);
                Log.error('fail to fetch crime rank table data with crime type', error);
            });
    }

    // fetch neighbor geo partition data
    function fetchNeighborMapGeoData(){
        fetch(API_URL + '/api/neighborhoods/geojson')
            .then(res=>res.json())
            .then(data=>{
                let feature = {};
                data.features.forEach(value => {
                    if (value.properties.id === searchItemId) {
                        feature = {
                            geometry: value.geometry,
                            properties: value.properties,
                            neighborName: restoreNeighborURLName(searchItemId)
                        };
                        setMapStyle(feature);
                    }
                });
                addChecksum();
            }).catch(error => {
                // setChecksum(checksum+1);
                addChecksum();
                Log.error('failed to fetch map data!', error);
            });
    }

    // fetch data for crime ranking
    function fetchCrimeRankData(){
        // date object is 0 based while api is 1 based
        // take last month's data, which is complete
        let currMonth = month;
        let currYear = year;

        if (currMonth === 0) {
            currMonth = 12;
            currYear -= 1;
        }
        let lastMonth = currMonth - 1;
        let lastYear = currYear;
        if (lastMonth === 0) {
            lastMonth = 12;
            lastYear -= 1;
        }
        let currMonthUrl = API_URL + `/api/neighborhoods/ranks/${currMonth}/${currYear}`;
        let lastMonthUrl = API_URL + `/api/neighborhoods/ranks/${lastMonth}/${lastYear}`;
        fetch(currMonthUrl)
            .then(res => res.json())
            .then(data => {
                const rankList = data.result["data"];
                let count = 0;
                let currRank = 0;
                rankList.forEach((value) => {
                    count++;
                    if (value.neighborhood === searchItemId) {
                        currRank = value.ranking;
                    }
                });
                let rankData = {
                    neighborName: restoreNeighborURLName(searchItemId),
                    dataMissing: true
                };

                // if there is rank in current month, find the diff with last month
                if (currRank) {
                    let prevRank = 0;
                    fetch(lastMonthUrl)
                        .then(res => res.json())
                        .then(data => {
                            const lastMonthList = data.result["data"];
                            lastMonthList.forEach((value) => {
                                if (value.neighborhood === searchItemId) {
                                    prevRank = value.ranking;
                                }
                            });

                            let diff = currRank - prevRank;
                            rankData.currRank = currRank;
                            rankData.count = count;
                            rankData.diff = prevRank !== 0 ? diff : 0;
                            rankData.dataMissing = false;
                        }).catch(error=>{
                            Log.error('failed to fetch crime ranking data!', error);
                        });
                }
                setCrimeRateData(rankData);
                addChecksum()
            }).catch(error=>{
                addChecksum();
                // setChecksum(checksum+1);
                Log.error('failed to fetch crime ranking data!', error);
            });
    }

    // restore names in url format
    function restoreNeighborURLName(urlType){
        try {
            let words = urlType.split('-');
            let res = '';
            words.forEach((value, idx) => {
                if (value.length >= 0) {
                    res += value.charAt(0).toUpperCase() + value.slice(1);
                } else {
                    res += value;
                }
                if (idx !== words.length - 1) {
                    res += ' ';
                }
            });
            return res;
        } catch (e) {
            Log.error("invalid type!", e)
        }
    }

    // restore the crime type from url format
    function restoreCrimeType(crimeType){
        const prepositions = new Set(['on', 'with', 'by', 'in', 'or', 'and']);
        try {
            let words = crimeType.split(' ');
            let res = '';
            words.forEach((value, idx) => {

                // Prepositions dont need to be capitalized
                if(!prepositions.has((value))) {
                    res += value.charAt(0).toUpperCase() + value.slice(1);
                    if (idx !== words.length - 1) {
                        res += ' ';
                    }
                }
                else{
                    res += value + ' ';
                }
            });
            return res;
        } catch (e) {
            Log.error("invalid crime type!", e)
        }
    }

    function closeAllSnackBars(){
        setSubSnackBar(null);
    }

    return (
        <div>
            {isLoading?<Loader/>:null}
            <Grid container className={classes.body} spacing={2}>
                <Grid item xs={12}>
                    <NavBar mode={mode}/>
                    <TabBar mode={mode}/>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Grid
                        container
                        direction="column"
                        justify="flex-start"
                        alignItems="stretch"
                        spacing={0}
                        className={classes.sideBarContainer}
                    >
                        <Grid className={classes.locationContainer}><LocationBar location = {sideBarTitle} /></Grid>
                        <Grid className={classes.searchBarContainer}>
                            <SearchBar mode={mode}
                                       searchBarInfo={searchBarInfo}
                                       nameToIdMap={itemNameToId}
                                       searchBarHandler={searchBarHandler}/>
                        </Grid>
                        {<SubscribeBar subscribeBarHandler={subscribeBarHandler} />}
                    </Grid>
                </Grid>

                <Grid item xs={12} md={8}>
                    <TrackerContent
                        mode={mode}
                        mapStyle={mapStyle}
                        crimeRateData={crimeRateData}
                        crimeChartData={crimeChartData}
                        crimeCTableData={crimeCTableData}
                        crimeNTableData={crimeNTableData}
                        CTableMode={CTableMode}
                        NTableMode={NTableMode}
                        changeHistoryRangeHandler={changeHistoryRangeHandler}
                        changePartitionHandler={changePartitionHandler}
                        restoreCrimeType={restoreCrimeType}
                        restoreNeighborURLName={restoreNeighborURLName}
                        dateTitle={dateTitle}
                    />
                </Grid>
                <About tracker={"Crime Tracker"}/>
            </Grid>
            <div className={classes.footer}>
                <Footer/>
            </div>
            <Feedback/>

            {subSnackBar && <MessageSnackBar
                variant={subSnackBar.variant}
                message={subSnackBar.message}
                link={subSnackBar.link}
                isMobile={isMobile}
                closeSnackBarHandler={closeAllSnackBars}
            />}

        </div>
    );
};

export default withStyles(styles, {withTheme: true})(Tracker);