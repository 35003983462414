import React from 'react';
import Grid from '@material-ui/core/Grid';
import {withStyles} from "@material-ui/core/styles/index";
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
    locationLogo:{
        float: 'left',
        height: '25px',
        width: '19px',
        marginRight: '10px',
    },
    locationContainer:{
        borderBottomWidth: '0.5px',
        borderBottomColor: '#d1d5da',
        borderBottomStyle: 'solid',
        padding: '10px 0'
    },

    typoRoot:{
        fontFamily: 'Rambla',
        fontSize: '22px',
        color: '#383838',
        letterSpacing: '1.1px',
        fontWeight: '700'
    }

});

const LocationBar = (props) =>{

    const imgPath = window.location.protocol + '//' + window.location.host + "/images";
    const {classes, location} = props;
    return(
        <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
            className={classes.locationContainer}
        >
            <span><img className={classes.locationLogo} src={imgPath + '/location_logo.svg'} alt={'location'}/></span>
            <Typography classes={{root: classes.typoRoot}}>
                {location}
            </Typography>
        </Grid>
    );
};



export default withStyles(styles, {withTheme: true})(LocationBar)