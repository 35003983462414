import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import {withStyles} from "@material-ui/core/styles/index";
import AQIAutocomplete from "../air-quality/AQIAutocomplete";
import SubscribeBar from '../tracker/SubscribeBar'
import LocationBar from "../tracker/LocationBar";
const styles = theme => ({
    sideBarContainer:{
        padding: '0px 5px',
        [theme.breakpoints.up('md')]:{
            borderRightWidth: '0.5px',
            borderRightColor: '#d1d5da',
            borderRightStyle: 'solid',
            padding: '0px 19px',
            paddingLeft: '0',
            height: '500px'
        }
    },
    locationContainer:{
        display: 'none',
        [theme.breakpoints.up('md')]:{
            display: 'flex'
        }
    },
    searchBarContainer: {
        padding: '0',
        [theme.breakpoints.up('md')]: {
        }
    },
});

class NSideBar extends Component{

    render(){
        const {classes, coordinates, areaName} = this.props;
        return(

            <Grid
                container
                direction="column"
                justify="flex-start"
                alignItems="stretch"
                spacing={0}
                className={classes.sideBarContainer}
            >
                <Grid className={classes.locationContainer}><LocationBar location = {areaName} /></Grid>
                <Grid className={classes.searchBarContainer}><AQIAutocomplete coordinates = {coordinates}/></Grid>
                <SubscribeBar/>
            </Grid>
        );

    }


}


export default withStyles(styles, {withTheme: true})(NSideBar)