import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  Hidden,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ModeFilter from "./ModeFilter";
import TimePeriodFilter from "./TimePeriodFilter";
import BigBundlesFilter from "./BigBundlesFilter";
import SmallBundlesFilter from "./SmallBundlesFilter";
import PartitionTypeFilter from "./PartitionTypeFilter";

import moment from "moment";
import useBigBundleFilters from "../api-hooks/hooks/use-big-bundle-filters";
import useSmallBundleFilters from "../api-hooks/hooks/use-small-bundle-filters";
import PropTypes from "prop-types";

export const SAFETY_MAP_MODE_VALUE = "safe";
export const TRAJECTORY_MODE_VALUE = "traj";
export const DEFAULT_MODE_FILTER = SAFETY_MAP_MODE_VALUE;

const lastMonth = moment().subtract(1, "months");
const lastTwelveMonth = moment().subtract(12, "months");
export const LAST_MONTH_VALUE = "1";
export const LAST_YEAR_VALUE = "12";
export const DEFAULT_DATE_FORMAT = "MMM YYYY";
export const DEFAULT_DATE_RANGE = {
  selectedTimePeriod: LAST_MONTH_VALUE,
  from: lastMonth.clone().startOf("month")._d,
  to: lastMonth.clone().endOf("month")._d,
};

export const DEFAULT_BIG_BUNDLE_FILTER = "";
export const DEFAULT_SMALL_BUNDLE_FILTER = [];
export const DEFAULT_PARTITION_TYPE_FILTER = "neighborhoods";

const styles = (theme) => ({
  customize: {
    width: 120,
  },

  expanded: {
    width: "max-content",
    display: "inline-flex",
  },

  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },

  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
    width: "100%",
    textAlign: "center",
    fontWeight: 500,
    lineHeight: "24px",
  },

  mapFiltersRoot: {
    position: "absolute",
    top: 80,
    left: 370,
    margin: 10,
    padding: 10,
    zIndex: 10,
    width: "15%",
  },

  bottomNavigationRoot: {
    width: "auto",
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 10,
  },
});

const MapFilter = (props) => {
  const {
    classes,
    selectedMode,
    selectedTimePeriod,
    selectedBigBundle,
    selectedSmallBundle,
    selectedPartitionType,
    onModeChanged,
    onTimePeriodChanged,
    onBigBundleChanged,
    onSmallBundleChanged,
    onPartitionChanged,
  } = props;

  const [expanded, setExpanded] = useState(false);

  const modeFilterData = [
    {
      key: "Safety Map",
      value: SAFETY_MAP_MODE_VALUE,
    },
    {
      key: "Trajectory",
      value: TRAJECTORY_MODE_VALUE,
    },
  ];

  const lastMonthKey = lastMonth.format(DEFAULT_DATE_FORMAT);
  const lastTwelveMonthKey = lastTwelveMonth.format(DEFAULT_DATE_FORMAT);
  const lastYearKey = lastTwelveMonthKey + " to " + lastMonthKey;
  const timePeriods = [
    {
      key: lastMonthKey,
      value: LAST_MONTH_VALUE,
    },
    {
      key: lastYearKey,
      value: LAST_YEAR_VALUE,
    },
    {
      key: "Custom",
      value: "custom",
    },
  ];

  const partitions = [
    {
      key: "Neighborhoods",
      value: "neighborhoods",
    },
    {
      key: "Zipcodes",
      value: "zipcodes",
    },
  ];

  const [bigBundlesState] = useBigBundleFilters();
  const [smallBundlesState, setQuerySmallBundles] = useSmallBundleFilters(
    selectedBigBundle
  );

  useEffect(() => {
    const { isLoading } = smallBundlesState;
    if (!isLoading) {
      // TODO Use another way to handle this
      onSmallBundleChanged([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [smallBundlesState]);

  return (
    <div id="map-filters" className={classes.mapFiltersRoot}>
      {/* Desktop Rendering */}
      <Hidden smDown implementation="css">
        <div
          className={[classes.customize, expanded ? classes.expanded : ""].join(
            " "
          )}
        >
          <ExpansionPanel
            expanded={expanded}
            onChange={(event, expanded) => setExpanded(expanded)}
          >
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className={classes.heading}>Filters</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails
              classes={{ root: "expansion-panel-details" }}
            >
              <Grid
                container
                direction="column"
                justify="space-around"
                alignItems="stretch"
              >
                <Grid item>
                  <ModeFilter
                    modes={modeFilterData}
                    selected={selectedMode}
                    onChange={(event) => onModeChanged(event.target.value)}
                  />
                </Grid>

                <Grid item>
                  <TimePeriodFilter
                    timePeriods={timePeriods}
                    selected={selectedTimePeriod}
                    onChange={(value) => onTimePeriodChanged(value)}
                  />
                </Grid>

                {bigBundlesState.isLoading ? (
                  <div>Loading ...</div>
                ) : (
                  <BigBundlesFilter
                    bigBundles={bigBundlesState.data}
                    selected={selectedBigBundle}
                    onChange={(event) => {
                      onBigBundleChanged(event.target.value);
                      setQuerySmallBundles(event.target.value);
                    }}
                  />
                )}

                {smallBundlesState.isLoading ? (
                  <div>Loading ...</div>
                ) : (
                  <Grid item>
                    <SmallBundlesFilter
                      disabled={selectedBigBundle === ""}
                      smallBundles={smallBundlesState.data}
                      selected={selectedSmallBundle}
                      onChange={(event) =>
                        onSmallBundleChanged(event.target.value)
                      }
                    />
                  </Grid>
                )}

                <Grid item>
                  <PartitionTypeFilter
                    partitions={partitions}
                    selected={selectedPartitionType}
                    onChange={(event) => onPartitionChanged(event.target.value)}
                  />
                </Grid>
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </div>
      </Hidden>
    </div>
  );
};

MapFilter.propTypes = {
  classes: PropTypes.object.isRequired,
  selectedMode: PropTypes.string.isRequired,
  selectedTimePeriod: PropTypes.object.isRequired,
  selectedBigBundle: PropTypes.string.isRequired,
  selectedSmallBundle: PropTypes.array.isRequired,
  selectedPartitionType: PropTypes.string.isRequired,
  onModeChanged: PropTypes.func.isRequired,
  onTimePeriodChanged: PropTypes.func.isRequired,
  onBigBundleChanged: PropTypes.func.isRequired,
  onSmallBundleChanged: PropTypes.func.isRequired,
  onPartitionChanged: PropTypes.func.isRequired,
  onSearchAddressChanged: PropTypes.func.isRequired,
};

export default withStyles(styles)(MapFilter);
