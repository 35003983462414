import React from 'react';
import Grid from '@material-ui/core/Grid';
import {withStyles} from "@material-ui/core/styles/index";
import Typography from '@material-ui/core/Typography';


const styles = theme => ({
    container:{
        [theme.breakpoints.up('md')]:{
            margin: '2rem 0rem',
        }
    },
    rankNumber:{
        color: '#FF9700',
        fontSize: '100px',
        fontFamily: '\'Poppins\' sans-serif',
        fontWeight: 'bold',
        width: '100%',
        height: 'auto',
        margin: '0 auto',
        position: 'relative'
    },
    totalNumber:{
        fontSize: '30px',
        color:  '#d3d3d3',
        width: '100%',
        height: 'auto',
        margin: '0 auto',
        position: 'relative'
    },
    introContainer:{
        marginTop: '20px'
    },
    introRoot:{
        fontFamily: 'helvetica',
        fontSize: '14px',
        fontWeight: '400',
        color: '#383838',
        letterSpacing: '0.8px',
        lineHeight: '18px',
        marginBottom:'6px',
        whiteSpace: 'pre-wrap'
    }

});

const CrimeRateRank = (props) => {

    const {rateRankData, classes} = props;
    let info = 'loading...';
    if(rateRankData) {
        if(rateRankData.dataMissing){
            info = '  Given this area\'s extraordinarily high crimes/population ratio, we have identified it as an ' +
                'outlier, and we have removed it from our crime rate rankings.'
        }
        else {
            const trends = ['an increase from last month.', 'a decrease from last month.', 'the same as last month.'];
            info = rateRankData.neighborName + ' is now ranked ' + rateRankData.currRank
                + ' in terms of crime rate in the Los Angeles areas that we cover, ';
            if (rateRankData.diff > 0) {
                info += trends[0];
            }
            else if (rateRankData.diff < 0) {
                info += trends[1];
            }
            else {
                info += trends[2];
            }
        }
    }
    return(
        <Grid container spacing={1} className={classes.container}>
            <Grid item xs={12}>
                <Typography variant={'h1'} align = {'center'} className={classes.rankNumber}>
                    {rateRankData?!rateRankData.dataMissing?rateRankData.currRank:'N/A':null}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant={'h1'} align = {'center'} className={classes.totalNumber}>
                    {rateRankData&&!rateRankData.dataMissing?'of ' + rateRankData.count:null}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant={'body1'} className={classes.introContainer} classes={{root:classes.introRoot}}>
                    {rateRankData?info:null}
                </Typography>
            </Grid>
        </Grid>
    );

};


export default withStyles(styles, {withTheme: true})(CrimeRateRank)