import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Header from '../components/Header'
import CrimeDescriptionMapping from "../components/bundles/CrimeDescriptionMapping";
import {Helmet} from "react-helmet";
import {BUNDLING_TITLE} from "../config/settings";

const styles = theme => ({
    root: {
        width: '100%',
    },

});

class Bundling extends Component {
    render() {
        const {classes} = this.props;
        return (
            <div>
                <Helmet>
                    <title>{BUNDLING_TITLE}</title>
                </Helmet>

                <div className={classes.root}>
                    <Header showAutocomplete={false}/>
                    <CrimeDescriptionMapping readOnly={true}/>
                </div>
            </div>
        );
    }
}

Bundling.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles, {withTheme: true})(Bundling);