import useDataApi from "../libs/fetch";
import {API_URL} from "../../constants";

const emptyGeoJSON = {
    'type': 'FeatureCollection',
    'features': []
};

/**
 * Constructs the partition data url
 * @param partitionType the type of partition: neighborhoods or zipcodes.
 * @returns {string}
 */
export function getPartitionDataUrl(partitionType) {
    return `${API_URL}/api/${partitionType}/geojson`;
}

function usePartitionData(partitionType) {
    const url = getPartitionDataUrl(partitionType);
    const [partitionDataState, setPartitionDataUrl,] = useDataApi(url, "", emptyGeoJSON, false);
    return [partitionDataState, setPartitionDataUrl];
}

export default usePartitionData