import React, {Component} from 'react';
import NavBar from '../tracker/NavBar';
import Footer from '../tracker/Footer';
import {Helmet} from "react-helmet";
import './style/style.css';
import './style/switch.css';
import './style/feedback.css';
import {withStyles} from "@material-ui/core/styles/index";
import {API_URL, TO_SUBSCRIPTION, UNSUB_NEIGHBOR_NAMES, SUB_NEIGHBOR_BAR} from "../constants";
import Log from "../../utils/Log";
import Grid from '@material-ui/core/Grid';
import SIntroduction from './SIntroduction';
import SNeighborSubBar from './SNeighborSubBar';
import STransition from './STransition';
import SNeighborhoodList from './SNeighborhoodList';
import PubSub from "pubsub-js";

const styles = theme => ({
    root: {
        width: '100%'
    },
    container: {
        [theme.breakpoints.up('md')]: {
            margin: '2rem 0rem',
        }
    },
    body: {
        [theme.breakpoints.up('md')]: {
            width: '70%',
        },
        [theme.breakpoints.up('lg')]: {
            width: '70%',
        },
        [theme.breakpoints.up('xl')]: {
            width: '70%',
        },
        width: '90%',
        margin: 'auto',
        padding: '10px 0',
        marginBottom: '100px'
    },
    footer: {
        backgroundColor: '#3C3C3C',
        color: '#eee'
    },
    mobileGrid: {
        padding: '5px 0'
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: theme.spacing.unit / 4,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        maxWidth: 300,
    },
    selectMenu: {
        minHeight: 200
    },
    submitBtnContainer: {
        margin: theme.spacing.unit / 4,
        display: 'flex',
        height: '100%',
        width: '30%',
        minWidth: '90px',
        flexDirection: 'column-reverse'
    },
    submitBtn: {
        backgroundColor: '#ff9700',
        fontFamily: 'Rambla',
        fontSize: '16px',
        color: '#fff',
        wordSpacing: '0.9px',
        fontWeight: '700',
        borderRadius: '10px',
        borderColor: '#383838',
        borderWidth: '1px',
        '&:hover': {
            backgroundColor: '#FFB019',
        }
    },
    submitBtnLabel: {
        fontFamily: 'rambla',
        fontSize: '16px',
        fontWeight: '700',
        color: '#fff',
        letterSpacing: '0.9px',
        textTransform: 'none',
        lineHeight: '30px'
    },

    transitionContainer: {
        margin: '20px'
    },

    transitionText: {
        fontFamily: 'IBMPlex',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '30px',
        color: '#767676'
    },

    newSubscriptionContainer: {
        margin: '20px',
        marginTop: '123px',
        minHeight: '650px'
    },

    subscribedNeighborContainer: {
        margin: '20px'
    },
    pageMask: {
        zIndex: '100',
        backgroundColor: '#2b2b2b',
        position: 'fixed',
        width: '100%',
        height: '100%',
        opacity: '0.8'
    },
    maskText:{
        fontFamily: 'Rambla',
        fontSize: '32px',
        color: '#fff',
        wordSpacing: '0.9px',
        fontWeight: '700',
        textAlign: 'center'
    }
});


class Subscription extends Component{
    constructor(props) {
        super(props);
        this.state= {
            subNeighbors: [],                   // current sub neighborhoods, [{id, name, categories}
            // subCrimes: [],                      // current sub crimes id
            selectedNeighbors: [],              // option values, corresponding to neighbor id
            // selectedCrimes: [],                 // crimes-category selected to subscribe before submit
            // deletedNeighbors: [],               // neighbor selected to unsubscribe before submit
            // deletedCrimes: [],                  // crimes selected to unsubscribe before submit
            visualCategories: new Map(),           // all visual categories    [{id, category_name, description, create_time}]
            selectedVisualCategories: [],
            userHash: '',
            pwdHash: '',
            email: '',
            neighborSubscription: new Map(),     // Map: [key]{neighbor_id(str), [values]{neighbor_name(str), subscribed(bool), visualCategories[]}},
            verifiedUser: true
        }
    }

    componentDidMount(){
        // need to set standards for the url
        let userHash = window.location.pathname.split("/")[2];
        let search = window.location.search.split('&');
        let email = search[0].slice(search[0].indexOf('=')+1);
        let pwdHash = search[1].slice(search[1].indexOf('=')+1);
        this.subscribeToken = PubSub.subscribe(TO_SUBSCRIPTION, this.subscribe);

        this.setState({
            userHash: userHash,
            pwdHash: pwdHash,
            email: email
        });
    }

    componentWillUnmount() {
        PubSub.unsubscribe(this.subscribeToken);
    }

    componentDidUpdate(prevProps, prevState, snapshot){
        if(this.state.userHash !== prevState.userHash && this.state.pwdHash !== prevState.pwdHash){
            this._fetchSubscribedData(this.state.userHash);
        }
    }

    subscribe = (msg, data) => {
        if(data.action){
            if(data.action === 'subscribe'){
                this.state.neighborSubscription.get(data.neighborId).subscribed = true;
            }
            else if(data.action === 'unsubscribe'){
                this.state.neighborSubscription.get(data.neighborId).subscribed = false;
            }
            this.submitSubscriptionChanges();
        }
    };

    // restore the crime type from url format
    restoreCrimeType = (crimeType) => {
        const prepositions = new Set(['on', 'with', 'by', 'in', 'or', 'and']);
        try {
            let words = crimeType.split(' ');
            let res = '';
            words.forEach((value, idx) => {

                // Prepositions dont need to be capitalized
                if(!prepositions.has((value))) {
                    res += value.charAt(0).toUpperCase() + value.slice(1);
                    if (idx !== words.length - 1) {
                        res += ' ';
                    }
                }
                else{
                    res += value + ' ';
                }
            });
            return res;
        } catch (e) {
            console.error("invalid crime type!")
        }
    };


    _fetchSubscribedData = (userHash) =>{
        if(userHash !== undefined) {
            let url = API_URL + "/api/neighborhoods/names";
            fetch(url)
                .then(res => res.json())
                .then(resBody => {
                    let neighborMap = new Map();
                    // neighborMap.set('', {name: '', subscribed: false, visualCategories: []});
                    resBody.result.data.forEach(neighborhood => {
                        neighborMap.set(neighborhood.neighborhood, {
                            name: neighborhood.name,
                            subscribed: false,
                            visualCategories: []
                        })
                    });

                    let url = API_URL + "/api/subscriptions/subscribers/" + userHash;
                    fetch(url)
                        .then(res => res.json())
                        .then(data => {
                            let subNeighborList = [];
                            // let crimeList = [];
                            if (data.subscription !== '') {
                                let parsedData = JSON.parse(data.subscription);
                                console.log(parsedData);

                                let categories = this.state.visualCategories;

                                // if category is empty, start a fetch for categories
                                if (categories.size === 0) {
                                    let url = API_URL + "/api/visualization/chart/category";
                                    fetch(url, {
                                        method: 'GET',
                                        headers: {
                                            'Content-Type': 'application/json',
                                        },
                                    }).then(res => res.json())
                                        .then(resBody => {
                                            categories = new Map();
                                            let data = resBody.result.data;
                                            data.forEach((category) => {
                                                categories.set(category.id, {...category});
                                            });
                                            parsedData.neighborhoods.forEach(subNeighbor => {
                                                if (neighborMap.has(subNeighbor.id)) {
                                                    let neighbor = neighborMap.get(subNeighbor.id);
                                                    neighbor.subscribed = true;

                                                    // find the corresponding category object and add it to map
                                                    subNeighbor.categoryId.forEach(id => {
                                                        neighbor.visualCategories.push(id);
                                                    });
                                                    subNeighborList.push({
                                                        id: subNeighbor.id,
                                                        ...neighbor
                                                    });
                                                }
                                            });
                                            // console.log(subNeighborList);
                                            // console.log(neighborMap);
                                            // prepare unsub neighborhood names

                                            this.setState({
                                                neighborSubscription: neighborMap,
                                                // crimes: crimeList,
                                                subNeighbors: subNeighborList,
                                                userHash: userHash,
                                                visualCategories: categories,
                                                verifiedUser: true
                                            });

                                            PubSub.publish(SUB_NEIGHBOR_BAR, {action: UNSUB_NEIGHBOR_NAMES,
                                                neighbors: neighborMap});
                                        })
                                } else {
                                    parsedData.neighborhoods.forEach(subNeighbor => {
                                        // console.log(subNeighbor);
                                        if (neighborMap.has(subNeighbor.id)) {
                                            let neighbor = neighborMap.get(subNeighbor.id);
                                            neighbor.subscribed = true;

                                            subNeighbor.categoryId.forEach(id => {
                                                neighbor.visualCategories.push(id);
                                            });
                                            subNeighborList.push({
                                                id: subNeighbor.id,
                                                ...neighbor
                                            });
                                        }
                                    });
                                    this.setState({
                                        neighborSubscription: neighborMap,
                                        // crimes: crimeList,
                                        subNeighbors: subNeighborList,
                                        userHash: userHash,
                                        visualCategories: categories,
                                        verifiedUser: true
                                    });

                                    PubSub.publish(SUB_NEIGHBOR_BAR, {action: UNSUB_NEIGHBOR_NAMES,
                                        neighbors: neighborMap});

                                }

                                // parsedData.crimes.forEach(crime => {
                                //     crimeList.push(crime);
                                // });

                            }
                            // this._fetchUnSubscribedNeighborhood(neighborList);
                            // this._fetchUnSubscribedCrime(crimeList);
                        }).catch((e) => {
                        console.log(e);
                        Log.error("unable to fetch subscribed data");
                        this.setState({
                            verifiedUser: false
                        })
                    });
                    // // console.log(neighborhoodList);
                    // this.setState({
                    //     neighborhoods: neighborhoodList
                    // });
                });
        }
    };

    handleCrimeAddition = event => {
        this.setState({ selectedCrimes: event.target.value });
    };

    handleNeighborhoodDeletion = event => {
        this.setState({ deletedNeighbors: event.target.value });
    };

    handleCrimeDeletion = event => {
        this.setState({ deletedCrimes: event.target.value });
    };

    submitSubscriptionChanges = () =>{
        let subscribedNeighbors = [];
        let neighborMap = this.state.neighborSubscription;
        Array.from(neighborMap.keys()).forEach(id=>{
            if(neighborMap.get(id).subscribed){
                subscribedNeighbors.push({
                    id: id,
                    name: neighborMap.get(id).name,
                    categoryId: neighborMap.get(id).visualCategories
                })
            }
        });

        console.log(subscribedNeighbors);
        //
        // let data = {
        //     'neighborhoods': this.state.selectedNeighbors.concat(this.state.subNeighborhoods),
        //     'crimes': this.state.selectedCrimes.concat(this.state.subNeighborhoods)
        // };
        let url = API_URL + "/api/subscriptions/subscribers";
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: this.state.email,
                passwordHash: this.state.pwdHash,
                subscription: {
                    neighborhoods: subscribedNeighbors
                }
            })
        })
            .then(res => res.json())
            .then(data => {
                console.log('Success:', data);
                this.setState({
                    selectedNeighbors: [],
                    selectedCrimes: [],
                    subNeighbors: subscribedNeighbors
                });
                // update the subscriptions in the state
                this._fetchSubscribedData(this.state.userHash);

            })
            .catch(error => {
                console.error('Error:', error);
            });
    };

    handleChangeMultipleNeighborhoods = event => {
        const { options } = event.target;
        const values = [];
        for (let i = 0, l = options.length; i < l; i += 1) {
            if (options[i].selected) {
                values.push(options[i].value);
            }
        }

        // if only 1 neighborhood is selected, pre select the category it already has
        // else if multiple are selected, do nothing
        if(values.length === 1){
            this.setState({
                selectedNeighbors: values,
                selectedVisualCategories: this.getSelectedCategory(values[0])
            });
        }
        else {
            this.setState({
                selectedNeighbors: values,
                selectedVisualCategories: []
            });
        }
    };

    // When user select categories, assign the categories to all selected neighborhoods
    handleChangeMultipleCategories = event => {

        if(this.state.selectedNeighbors.length === 0){
            alert('Selected Neighborhood cannot be null!');
            return;
        }
        const { options } = event.target;
        const value = [];
        for (let i = 0, l = options.length; i < l; i += 1) {
            if (options[i].selected) {
                value.push(options[i].value);
            }
        }

        let neighborMap = this.state.neighborSubscription;

        // update the visual category list in the neighborhood map
        this.state.selectedNeighbors.forEach(neighbor => {
            if(neighborMap.has(neighbor)){
                neighborMap.get(neighbor).visualCategories = value;
                neighborMap.get(neighbor).subscribed = true;
            }
        });

        this.setState({
            neighborSubscription: neighborMap,
            selectedVisualCategories: value
        });
    };


    getSelectedCategory = (selectedNeighbor) =>{
        return this.state.neighborSubscription.get(selectedNeighbor).visualCategories;
    };

    render(){
        const {classes} = this.props;
        const {
            subNeighbors,
        } = this.state;

        return(
            <div>
                {this.state.verifiedUser||
                <Grid container
                    direction="row"
                    justify="center"
                    alignItems="center" className={classes.pageMask}>
                    >
                    <Grid item className={classes.maskText}>
                        <p>Subscription page is currently disabled</p>
                        <p>Please check your email to confirm the registration first </p>
                    </Grid>
                </Grid>}

                <Helmet>
                    <title>Subscription</title>
                </Helmet>
                <div className={classes.body}>
                    <NavBar mode={''}/>

                    <Grid
                        container
                        direction="row"
                        justify="space-between"
                        alignItems="flex-start"
                        classes={{root: classes.newSubscriptionContainer}}
                    >
                        <Grid item xs={6} style={{marginTop: '100px'}}>
                            <SIntroduction/>
                            <SNeighborSubBar/>
                        </Grid>

                        <Grid item xs={5}>
                            <div style={{height: '482px', width: '100%', backgroundColor: '#F2F2F2'}}/>
                        </Grid>
                    </Grid>

                    <STransition/>

                    <SNeighborhoodList email={this.state.email} subscribedNeighbors={subNeighbors}/>

                </div>
                <div className={classes.footer}>
                    <Footer/>
                </div>
            </div>
    )
    }
}
export default withStyles(styles, {withTheme: true})(Subscription);