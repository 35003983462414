import {useEffect, useState} from "react";


const useMapViewport = (initialLatitude = 34.022687,
                        initialLongitude = -118.285938,
                        initialZoom = 9,
                        initialMinZoom = 8,
                        initialMaxZoom = 20,
                        initialBearing = 0,
                        initialPitch = 0,
                        initialWidth = window.innerWidth,
                        initialHeight = window.innerHeight) => {

    const [viewport, setViewport] = useState({
        latitude: initialLatitude,
        longitude: initialLongitude,
        zoom: initialZoom,
        minZoom: initialMinZoom,
        maxZoom: initialMaxZoom,
        bearing: initialBearing,
        pitch: initialPitch,
        width: initialWidth,
        height: initialHeight - 77, // TODO Find a better way to adjust height based on the height of topbar
    });

    useEffect(() => {
        function handleResize() {

            setViewport({
                ...viewport,
                height: window.innerHeight - 77, // TODO Find a better way to adjust height based on the height of topbar
                width: window.innerWidth
            });
        }

        window.addEventListener('resize', handleResize);

        return _ => {
            window.removeEventListener('resize', handleResize)
        };
    });
    return [viewport, setViewport];
};

export default useMapViewport