import React, {Component} from 'react';

import BloomingMenu from 'blooming-menu'

import "styles/css/Share.css"

import ReactGA from 'react-ga';
import {withStyles} from "@material-ui/core";

const styles = theme => ({
    menuButton: {
        zIndex: 6,
        position: 'absolute',
        top: 170,
        right: 60,
        [theme.breakpoints.down('sm')]: {
            top: 130,
            right: 30,
        },
    },
});

class Share extends Component {

    componentDidMount() {
        this.bloomingMenu = new BloomingMenu({
            startAngle: 180,
            endAngle: 90,
            radius: 100,
            itemsNum: 5,
            itemAnimationDelay: 0.08,
            mainContent: "&nbsp;&nbsp;&nbsp;&nbsp;",
            fatherElement: document.getElementById("shareBtns")
        });

        this.bloomingMenu.render();
        let self = this;
        let shareBtnInfo = ["Share to Facebook", "Share to Twitter", "Share to LinkedIn", "Share to WeChat", "Copy Link"];
        this.bloomingMenu.props.elements.items.forEach(function (item, index) {

            const shareBtns = document.getElementById("shareBtns");
            const topbar = document.querySelector('#topBar');
            let offset = {
                top: shareBtns.offsetTop + topbar.clientHeight - 12 + self.bloomingMenu.props.radius * Math.sin(Math.PI / 180 * (90 / 4) * index),
                left: shareBtns.offsetLeft - self.bloomingMenu.props.radius * 2 - 3 * shareBtnInfo[index].length + self.bloomingMenu.props.radius * (1 - Math.cos(Math.PI / 180 * (90 / 4) * index))
            };

            let bmtooltip = document.createElement('div');
            bmtooltip.className = "bm-tooltip bm-tooltip-" + index;
            bmtooltip.setAttribute('data-placement', 'left');
            bmtooltip.style = 'position: absolute; will-change: transform; top: 0px; left: 0px; '
                + 'transform: translate3d(' + offset.left + 'px, ' + offset.top + 'px, 0px);">';
            bmtooltip.innerHTML = '<div role="tooltip" aria-hidden="true" class="bm-tooltip-left">'
                + shareBtnInfo[index] + '</div>';
            document.body.appendChild(bmtooltip);

            item.addEventListener('mouseover', function () {
                let tooltip = document.querySelector('.bm-tooltip-' + index + ' .bm-tooltip-left');
                tooltip.className += ' bm-tooltip-active';
            });
            item.addEventListener('mouseout', function () {
                let tooltip = document.querySelector('.bm-tooltip-' + index + ' .bm-tooltip-left');
                tooltip.classList.remove('bm-tooltip-active');
            })
            item.addEventListener('click', function () {
                switch (index) {
                    case 0:
                        // Facebook
                        window.open('https://www.facebook.com/share.php?u=' + encodeURIComponent(window.location.href), 'facebook-intent', 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=550, height=420');
                        break;
                    case 1:
                        // Twitter
                        window.open('https://twitter.com/intent/tweet?text=' + encodeURIComponent(document.title) + '&url=' + encodeURIComponent(window.location.href) + '&original_referer=' + encodeURIComponent(window.location.href), 'twitter-intent', 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=550, height=420');
                        break;
                    case 2:
                        // LinkedIn//Instagram
                        window.open('https://www.linkedin.com/cws/share?url=' + encodeURIComponent(window.location.href), 'linkedin-intent', 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=550, height=420');
                        break;
                    case 3:
                        // WeChat
                        break;
                    case 4:
                        self.copyToClipboard(window.location.href);
                        //copy link
                        break;
                    default:
                        ;
                }
            });
        });
        this.feedback(document, window);
        window.gaf.init({
            'open': 'Feedback',
            'title': 'We would love to hear your feedback!',
            'eventCategory': 'Feedback Map',
            'question1': 'Please enter your suggestions and complaints:',
            'question2': 'What else information would you like to see on this site:',
            'send': 'Send Feedback',
            'thankyou': 'Thank you for your feedback!'
        });
    }

    feedback(d, N) {
        N.gaf = {

            init: function (options) {
                N.gaf.options = options;
                N.gaf.loadHtml();
                N.gaf.loadButton();
            },
            loadHtml: function () {
                N.gaf.buttonHtml = '<a id="gaf-button" style="" href="#">' + N.gaf.options.open + '</a>';

                N.gaf.dialogHtml = '<div id="gaf-dialog"><h5>' + N.gaf.options.title
                    + '</h5><a id="gaf-dialog-close" href="#">&times;</a>'
                    + '<label class="gaf-text-label" for="gaf-text-1">' + N.gaf.options.question1 + '</label>'
                    + '<input id="gaf-text-1" class="gaf-text" type="text" maxlength="500">'
                    + '<label class="gaf-text-label" for="gaf-text-2">' + N.gaf.options.question2 + '</label>'
                    + '<input id="gaf-text-2" class="gaf-text" type="text" maxlength="500">'
                    + '<a id="gaf-submit" href="#">'
                    + N.gaf.options.send + "</a>";

            },
            loadButton: function () {
                d.body.appendChild(N.gaf.createElementFromHTML(N.gaf.buttonHtml));
                d.getElementById('gaf-button').addEventListener('click', function (e) {
                    N.gaf.loadDialog();
                    e.preventDefault();
                }, false);
            },
            loadDialog: function () {
                d.getElementById('gaf-button').removeEventListener('click', function () {
                }, false);

                d.body.removeChild(d.getElementById('gaf-button'));

                d.body.appendChild(N.gaf.createElementFromHTML(N.gaf.dialogHtml));
                d.getElementById('gaf-text-1').focus();

                d.getElementById('gaf-dialog-close').addEventListener('click', function (e) {
                    N.gaf.closeDialog();
                    e.preventDefault();
                }, false);
                d.getElementById('gaf-submit').addEventListener('click', function (e) {
                    N.gaf.sendFeedback();
                    e.preventDefault();
                }, false);
            },
            closeDialog: function () {
                d.getElementById('gaf-dialog-close').removeEventListener('click', function () {
                }, false);
                d.getElementById('gaf-submit').removeEventListener('click', function () {
                }, false);

                d.body.removeChild(d.getElementById('gaf-dialog'));

                N.gaf.loadButton();
            },

            sendFeedback: function () {
                if (d.getElementById('gaf-text-1').value.length < 1) {
                    d.getElementById('gaf-text-1').style.border = '2px solid #c00';
                    d.getElementById('gaf-text-1').focus();
                    return false;
                }
                ReactGA.event({
                    category: N.gaf.options.eventCategory,
                    action: "Question1: " + N.gaf.options.question1,
                    label: d.getElementById('gaf-text-1').value,
                    value: 1
                });
                if (d.getElementById("gaf-text-2").value.trim().length > 0) {
                    ReactGA.event({
                        category: N.gaf.options.eventCategory,
                        action: "Question2: " + N.gaf.options.question2,
                        label: d.getElementById('gaf-text-2').value,
                        value: 1
                    });
                }
                alert(N.gaf.options.thankyou);
                N.gaf.closeDialog();
            },
            createElementFromHTML: function (htmlString) {
                var div = document.createElement('div');
                div.innerHTML = htmlString.trim();

                // Change this to div.childNodes to support multiple top-level nodes
                return div.firstChild;
            }
        };
    }

    copyToClipboard(text) {
        var dummy = document.createElement("input");
        document.body.appendChild(dummy);
        dummy.setAttribute('value', text);
        dummy.select();
        document.execCommand("copy");
        document.body.removeChild(dummy);
    }

    render() {
        const {classes} = this.props;

        return (
            <div id="shareBtns" className={classes.menuButton}></div>
        );
    }
}

export default withStyles(styles)(Share);
