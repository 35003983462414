import Autocomplete from "react-autocomplete";
import PubSub from "pubsub-js";
import {
    TO_SUBSCRIPTION,
    UNSUB_NEIGHBOR_NAMES,
    SUB_SEARCH_BAR_FOCUS,
    SUB_NEIGHBOR_BAR,
} from "../constants";
import React, {Component} from "react";
import {withStyles} from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import Trie from '../../utils/Trie';

import './style/Searchbar.css';

const styles = (theme) =>({
    wrapperStyle:{
        position: 'relative',
            display: 'inline-block',
            margin: '1rem',
            [theme.breakpoints.up('md')]:{
            display: 'block',
        }
    },
    menuStyle:{
        position: 'absolute',
        width: '90%',
        top: '25px',
        left: '0px',
        zIndex: '1',
        backgroundColor: '#fff'
    },
    searchBarContainer:{
        margin: '10px'
    },
    addButtonStyle:{
        margin: '10px',
        cursor: 'pointer',
        '&:hover':{
            color: '#4fc3f7',
        },
        fontSize: '42px',
        color: '#C4C4C4',
        transition: '.25s'
    }

});

const placeHolderText = "choose your neighborhood";


class SNeighborSubBar extends Component{



    constructor(props) {
        super(props);
        this.state = {
            prefix: '',                                 // the word on input bar
            unSubNeighborNames: new Trie(),             // unsubscribed neighborhood names
            neighborNamesToIds: new Map()               // neighbor name mapped to id
        };
        this.inputBar = null;
        this.document = null;
    }

    componentDidMount() {
        this.subscribeToken = PubSub.subscribe(SUB_NEIGHBOR_BAR, this.subscribe);
    }

    componentWillUnmount() {
        PubSub.unsubscribe(this.subscribeToken);
    }

    subscribe = (msg, data)=>{
        const{action} = data;
        if(action === UNSUB_NEIGHBOR_NAMES){
            const {neighbors} = data;
            let unSubNeighborNames = [];
            let neighborNamesToIds = new Map();
            Array.from(neighbors.keys()).forEach(id=>{
                let neighbor = neighbors.get(id);
                if(!neighbor.subscribed) {
                    unSubNeighborNames.push(neighbor.name);
                    neighborNamesToIds.set(neighbor.name, id);
                }
            });

            unSubNeighborNames = unSubNeighborNames.sort();

            let neighborNames = new Trie();
            unSubNeighborNames.forEach(neighborName => {
                neighborNames.insert(neighborName);
            });

            this.setState({
                unSubNeighborNames: neighborNames,
                neighborNamesToIds: neighborNamesToIds
            })
        }
        else if(action === SUB_SEARCH_BAR_FOCUS){
            console.log("received");
            this.getSearchBarDOMElement(this.document).focus();
        }
    };

    selectNeighbor = (neighborName) => {
        this.setState({
            prefix: neighborName
        });
        let inputBar = document.getElementById('search').getElementsByTagName('input')[0];
        inputBar.addEventListener('focus', this.clearInputBar);
        inputBar.blur();
    };

    submitNeighbor = () =>{
        const {neighborNamesToIds, prefix} = this.state;
        if(neighborNamesToIds.has(prefix)) {
            PubSub.publish(TO_SUBSCRIPTION, {action: 'subscribe', neighborId: neighborNamesToIds.get(prefix)});
            this.setState({
                prefix: ''
            })
        }
    };

    clearInputBar = () =>{
        this.setState({
            prefix: '',
        });
        let inputBar = document.getElementById('search').getElementsByTagName('input')[0];
        inputBar.removeEventListener('focus', this.clearInputBar);
    };

    isMobile =()=>{
        let isMobile = false;
        if(window.screen.width <= 960){
            isMobile = true;
        }
        return isMobile;
    };

    getSearchBarDOMElement = (document)=> {
        return document.getElementById('search').getElementsByTagName('input')[0];
    };

    getSearchBarContainerDOMElement = (document)=> {
        return  document.getElementById('searchBarContainer');
    };

    render(){
        const {unSubNeighborNames, prefix} = this.state;
        const {classes} = this.props;

        let limit  = this.isMobile()?5:5;
        let neighborNameList = [];
        if(!prefix){
            neighborNameList = unSubNeighborNames.findAll('').slice(0, limit);
        }
        neighborNameList = unSubNeighborNames.findAll(prefix).slice(0, limit);
        this.document = document;
        let that = this;
        const inputProps = {
            style:{
                display: 'inline-block',
                width: '383px',
                height: '42px',
                color: '#5e5e5e',
                fontSize: '16px',
                fontWeight: '400',
                fontFamily: 'Open Sans',
                lineHeight:'22px',
                backgroundColor: '#f8f8f8',
                backgroundClip: 'padding-box',
                WebkitTransition: 'rgba(0, 123, 255, 0.25) .15s ease-in-out,box-shadow .15s ease-in-out',
                transition: 'rgba(0, 123, 255, 0.25) .15s ease-in-out,box-shadow .15s ease-in-out',
                borderRadius: '10px',
                textIndent: '20px',
                border: '0',
                padding: '0px',
                textAlign: 'left'
            },
            placeholder: placeHolderText,
            onFocus(){
                that.getSearchBarContainerDOMElement(document).style.marginRight = '10px';
                let inputBar = that.getSearchBarDOMElement(document);
                inputBar.style.outline = 0;
                inputBar.style.borderRadius = '10px 10px 0 0';
            },
            onBlur(){
                let inputBar = that.getSearchBarDOMElement(document);
                inputBar.style.boxShadow = '';
                that.getSearchBarContainerDOMElement(document).style.marginRight = '10px';
                inputBar.style.borderRadius = '10px';
            }};

        let itemKey = 1;
        const itemNum = neighborNameList.length;
        return (
            <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
                className={classes.inputContainer}
                id={'search'}
            >
                <Grid item id={'searchBarContainer'} className={classes.searchBarContainer}>
                    <Autocomplete
                        wrapperStyle={{styles: classes.wrapperStyle}}
                        menuStyle={{styles: classes.menuStyle}}
                        inputProps={inputProps}
                        getItemValue={item => item}
                        items={
                            neighborNameList
                        }
                        renderItem={(neighbor, isHighlighted) => {
                            const itemStyle = {
                                backgroundColor: isHighlighted ? '#F2F2F2' : '#F8F8F8',
                                textAlign: 'left',
                                fontSize: '16px',
                                fontWeight: '400',
                                fontFamily: 'Open Sans',
                                width: '383px',
                                height: '42px',
                                lineHeight: '42px',
                                textIndent: '20px',
                                color: '#5e5e5e',
                                cursor: 'pointer'
                            };
                            if(itemKey === itemNum){
                                itemKey = 1;
                                return <div key={5} style={{...itemStyle, borderRadius: '0 0 10px 10px'}} >
                                    {neighbor}</div>
                            }
                            return <div key={itemKey++} style={itemStyle}>
                            {neighbor}</div>;
                        }}
                        value={this.state.prefix}
                        onChange={(e) => {
                            let prefix = e.target.value;
                            this.setState({
                                prefix: prefix
                            })
                        }}
                        onSelect={value => {
                            this.selectNeighbor(value)
                        }}
                        // onMenuVisibilityChange={(isOpen) => {
                        //     let isMobile = this.isMobile();
                        //     PubSub.publish(MENU_TOGGLED, {isOpen, isMobile});
                        //     PubSub.publish(CRIME_DATE_FINISHED + '.toggleTitle', isMobile);
                        // }}
                    />
                </Grid>
                <Grid item >
                    {/*<div className={classes.addButtonStyle} onClick={this.submitNeighbor}>*/}
                    {/*    <img src={ IMAGE_PATH + '/plus-button.png'}/>*/}
                    {/*</div>*/}
                    <AddCircleRoundedIcon classes={{root: classes.addButtonStyle}}  onClick={this.submitNeighbor}/>
                </Grid>
            </Grid>
        )
    }
}

export default withStyles(styles, {withTheme: true})(SNeighborSubBar);