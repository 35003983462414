import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import InfoIcon from "@material-ui/icons/Info";

const useStyles = makeStyles(() => ({
  container: {
    minWidth: "100%",
    border: "1px solid #EAEAEA",
    borderRadius: "5px",
    padding: "5px",
  },
  row: {
    borderBottom: "0.5px solid #DEDFE7",
    alignItems: "center",
    padding: "10px",
  },
  leftVal: {
    fontFamily: "Open Sans",
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "16px",
    alignItems: "center",
    justifyContent: "center",
  },
  rightVal: {
    fontFamily: "Open Sans",
    fontWeight: "600",
    fontSize: "12px",
    textAlign: "right",
  },
  rightValueAvatar: {
    height: "20px",
    width: "27px",
    fontFamily: "Open Sans",
    fontWeight: "600",
    fontSize: "12px",
    marginInlineStart: "auto",
  },
  infoIcon: {
    fontSize: "15px",
    color: "#C4C4C4",
    paddingLeft: "2px",
    cursor: "pointer",
  },
}));

const CardListView = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      {props.statistics.map((row) => (
        <Grid container className={classes.row} key={row.key}>
          <Grid item className={classes.leftVal} xs={8}>
            {row.key}
            {row.info != null ? (
              <InfoIcon className={classes.infoIcon} />
            ) : null}
          </Grid>
          <Grid item xs={4} className={classes.rightVal}>
            {row.color != null ? (
              <Avatar
                variant="rounded"
                style={{
                  backgroundColor: row.color != null ? row.color : "white",
                  color: "black",
                }}
                className={classes.rightValueAvatar}
              >
                {row.value}
              </Avatar>
            ) : (
              <div>{row.value}</div>
            )}
          </Grid>
        </Grid>
      ))}
    </div>
  );
};

export default CardListView;
