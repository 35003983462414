import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import {withStyles} from "@material-ui/core/styles/index";
import Typography from '@material-ui/core/Typography';
import InputBar from './InputBar';
import './style/styles.css'
const styles = theme => ({
    subscribeContainer:{
        display: 'none',
        [theme.breakpoints.up('md')]:{
            display: 'block'
        }
    },
    textField: {
        width: '60%'
    },
    contentContainer:{
        display: 'none',
        [theme.breakpoints.up('md')]:{
            display: 'block'
        }
    },
    signUpButton:{
        backgroundColor: '#ff9700',
        fontFamily: 'Rambla',
        fontSize: '16px',
        color: '#fff',
        wordSpacing: '0.9px',
        fontWeight: '700',
        borderRadius: '10px',
        borderColor: '#383838',
        borderWidth: '1px',
        '&:hover':{
            backgroundColor: '#FFB019',
            boxShadow: 'none',
        }
    },
    subscribeTitle:{
        width: '100%'
    },
    titleRoot:{
        fontFamily: 'Rambla',
        fontSize: '16px',
        fontWeight: '700',
        color: '#383838',
        letterSpacing: '1.2px'
    },
    bodyRoot:{
        fontFamily: 'helvetica',
        fontSize: '14px',
        fontWeight: '400',
        color: '#383838',
        letterSpacing: '0.8px',
        lineHeight: '18px',
        marginBottom:'14px',
        whiteSpace: 'pre-wrap'
    },
    signUpButtonRoot:{
        width: '90px',
        height: '30px',
        marginTop: '10px',
        padding: '0',
        boxShadow: 'none',
        [theme.breakpoints.up('lg')]:{
            marginLeft: '20px',
            marginTop: '0',
        }
    },
    signUpEmail:{
        padding: '11px 14px',
        fontSize: '16px',
        fontWeight: '400',
        fontFamily: 'Rambla',
        letterSpacing: '0.9px',
        borderColor: 'rgb(234, 234, 234)'
    },
    signUpButtonLabel:{
        fontFamily: 'rambla',
        fontSize: '16px',
        fontWeight: '700',
        color: '#fff',
        letterSpacing: '0.9px',
        textTransform: 'none',
        lineHeight: '30px'
    },
    inputBorder:{
        borderColor: 'blue'
    },
    signUpMargin:{
        marginTop: '8px'
    },
    labelPosition:{
        top: '-4px',
        fontSize: '16px',
        fontWeight: '400',
        fontFamily: 'Rambla',
        letterSpacing: '0.9px'
    },
});

const SubscribeBar = (props) => {


    const {subscribeBarHandler, classes} = props;

    let subscribeEmail = '';

    function updateUseEmail(email){
        subscribeEmail = email;
    }

    function subscribeUser(){
        subscribeBarHandler(subscribeEmail)
    }

    const title = 'Want to Know More?';
    const content = 'We refresh these rankings monthly, as soon as we have the next month\'s worth of data.\n\n' +
        'Sign up, and we\'ll let you know when to come and check it out.';

    return(
        <div>
            <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                className={classes.subscribeContainer}
            >
                <Typography
                    variant={'subtitle2'}
                    gutterBottom
                    paragraph
                    className={classes.contentContainer}
                    classes={{root: classes.titleRoot}}
                >{title}</Typography>

                <Typography
                    variant={'body1'}
                    paragraph
                    className={classes.contentContainer}
                    classes={{root: classes.bodyRoot}}
                >{content}</Typography>


                <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="center"
                >
                    <InputBar updateHandler = {updateUseEmail} fixedWidth={true}/>
                    <Button variant="contained" className={classes.signUpButton}
                            classes={{root: classes.signUpButtonRoot, label: classes.signUpButtonLabel}}
                            onClick = {subscribeUser}
                    >
                        Sign Up
                    </Button>
                </Grid>
            </Grid>
        </div>
    );
};


export default withStyles(styles, {withTheme: false})(SubscribeBar)