import React, { useState, useEffect, useRef } from 'react';
import {withStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import MapGL, {FlyToInterpolator} from "react-map-gl";
import {neighborhoodMapStyle} from "./config/neighborhood-map-style";
import bbox from "@turf/bbox";
import {geometry} from "@turf/helpers";
import WebMercatorViewport from 'viewport-mercator-project';
import * as d3 from "d3-ease";

const styles = theme => ({
    mapContainer:{
        position: 'relative',
        width: '100%',
        height: '380px',
        marginTop: '10px'
    },
    mapTitle:{
        marginTop: '20px',
        marginBottom: '10px',
        fontFamily: 'Rambla',
        fontSize: '16px',
        fontWeight: '700',
        color: '#383838',
        letterSpacing: '1.2px'
    }
});

const NeighborMap = (props) => {
    const {mapFeature, classes} = props;

    const mapRef = useRef(null);
    const [mapStyle, setMapStyle] = useState(null);
    const [viewport, setViewport] = useState(null);

    useEffect(()=>{
        if (mapFeature) {
            const mapWidth = document.getElementById('mapContainer').offsetWidth;
            const webViewport = new WebMercatorViewport({
                width: mapWidth,
                height: 326,
                longitude: -118.285938,
                latitude: 34.022687,
                zoom: 9
            });
            const bounds = bbox(geometry(mapFeature.geometry.type, mapFeature.geometry.coordinates));
            const {longitude, latitude, zoom} = webViewport.fitBounds([[bounds[0], bounds[1]], [bounds[2],
                bounds[3]]], {
                padding: 10,
                offset: [0, -50]
            });
            setViewport({
                ...webViewport,
                longitude,
                latitude,
                zoom,
                transitionInterpolator: new FlyToInterpolator(),
                transitionDuration: 500,
                transitionEasing: d3.easeCubic
            });

            const newStyleData = {
                type: "FeatureCollection",
                features: [mapFeature]
            };
            setMapStyle(neighborhoodMapStyle.updateLayers(newStyleData));
        }
    }, [mapFeature]);

    useEffect(()=>{
        if(viewport) {
            const mapWidth = document.getElementById('mapContainer').offsetWidth;

            function handleResize() {
                setViewport({
                    ...viewport,
                    width: mapWidth
                });
            }
            window.addEventListener('resize', handleResize);
            return _ => {
                window.removeEventListener('resize', handleResize)
            };
        }
    });

    function viewportChangeHandler(viewport) {
        // limit the zoom range
        if(viewport.zoom > 12){
            viewport.zoom = 12;
        }
        else if(viewport.zoom < 8){
            viewport.zoom = 8;
        }
        setViewport(viewport)
    }

    return(
        // create id to the div block so that the map can dynamically resize based on the div's width
        <div className={classes.mapContainer} id={'mapContainer'}>
            <Typography variant={'subtitle2'} classes={{root: classes.mapTitle}}>
                {mapStyle && mapFeature.neighborName}
            </Typography>
            {mapStyle && viewport &&
                <MapGL
                    {...viewport}
                    ref={mapRef}
                    mapStyle={mapStyle}
                    onViewportChange={viewportChangeHandler}
                    mapboxApiAccessToken={process.env.REACT_APP_MAP_BOX_ACCESS_TOKEN}
                    interactiveLayerIds={['la-neighborhoods-fill']}
                />}
        </div>

    )
};
export default withStyles(styles, {withTheme: true})(NeighborMap)