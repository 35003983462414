import useDataApi from "../libs/fetch";
import {API_URL} from "../../constants";

/**
 * Constructs the coronavirus trend url
 * @returns {string}
 */
export function getCoronavirusTrendUrl(query) {
    return `${API_URL}/api/health/coronavirus_trend?neighborhood=${query}`;
}

/**
 * Get Coronavirus trend for a neighborhood
 */
function useCoronavirusTrend(query) {
    const url = getCoronavirusTrendUrl(query);
    const [coronavirusTrendState, setCoronavirusTrendUrl,] = useDataApi(url, "", []);
    return [coronavirusTrendState, setCoronavirusTrendUrl];
}

export default useCoronavirusTrend;