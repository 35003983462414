import React from 'react';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import SSubscribedNeighbor from './SSubscribedNeighbor';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import PubSub from "pubsub-js";
import {SUB_NEIGHBOR_BAR, SUB_SEARCH_BAR_FOCUS} from "../constants";

const styles = theme => ({
    newSubscriptionContainer: {
        margin: '20px'
    },
    subscriptionTitleContainer: {
        margin: '10px'
    },
    subscriptionBodyContainer:{
        margin: '10px 10px 10px 10px',
    },
    subscriptionTitle: {
        fontFamily: 'Open Sans',
        fontWeight: 700,
        fontSize: '36px',
        lineHeight: '49px',
        color: '#000'
    },
    subscriptionBody: {
        fontFamily: 'Open Sans',
        fontWeight: 400,
        fontSize: '20px',
        lineHeight: '27px',
        color: '#000',
        fontStyle: 'italic',
    },
    subscribedNeighborContainer:{
        padding: '25px 10px 25px 10px'
    },
    addNeighborBtnContainer:{
        height: '346px',
        width: '346px',
        borderColor: '#c4c4c4',
        borderStyle: 'dashed',
        borderWidth: '1px',
        borderRadius: '10px',
        cursor: 'pointer'
    },
    addButtonStyle:{
        position: 'relative',
        left: '140px',
        top: '140px',
        margin: '10px',
        cursor: 'pointer',
        '&:hover':{
            color: '#4fc3f7',
        },
        fontSize: '42px',
        color: '#C4C4C4',
        transition: '.25s'
    },
    placeHolderBlock:{
        width: '346px',
        height: '346px',
    }
});


function SNeighborhoodList(props) {
    const {classes, subscribedNeighbors} = props;
    const neighborNumber = subscribedNeighbors.length;

    subscribedNeighbors.sort((e1, e2)=> e1.name.localeCompare(e2.name));

    const addBoxClickHandler = () => {
        window.scrollTo({
            top: 200,
            left: 0,
            behavior: 'smooth'
        });

        // set time out, waiting for the transition animation to finish
        setTimeout(()=>{
            PubSub.publish(SUB_NEIGHBOR_BAR, {action: SUB_SEARCH_BAR_FOCUS});
        }, 500);
    };

    return<Grid
        container
        direction="row"
        justify="space-around"
        alignItems="flex-start"
        classes={{root: classes.newSubscriptionContainer}}
        id={'subscribed-neighbors'}
    >
        <Grid item xs={12} classes={{root:classes.subscriptionTitleContainer}}>
            <Typography classes={{root: classes.subscriptionTitle}}>Your subscriptions</Typography>
        </Grid>
        <Grid item xs={12} classes={{root: classes.subscriptionBodyContainer}}>
            <Typography classes={{root: classes.subscriptionBody}}>
            Manage the following subscriptions that are currently being sent to {' '}
                <span style={{color: '#f34a9b'}}>{props.email}</span> </Typography>
        </Grid>

        {subscribedNeighbors.map((neighbor, idx)=>{
            return <Grid item xl={4} md={6} xs={12} key={idx} classes={{root: classes.subscribedNeighborContainer}}>
                <SSubscribedNeighbor neighborName={neighbor.name} neighborId={neighbor.id}/>
            </Grid>
        })}

        <Grid item xs={4} classes={{root: classes.subscribedNeighborContainer}}>
            <div className={classes.addNeighborBtnContainer}
                 onClick={addBoxClickHandler}>
                <AddCircleRoundedIcon classes={{root: classes.addButtonStyle}}/>
            </div>
        </Grid>

        {neighborNumber%3===0?<Grid item xs={4} classes={{root: classes.subscribedNeighborContainer}}>
            <div className={classes.placeHolderBlock}/></Grid>: null}

        {(neighborNumber%3===0||neighborNumber%3===1) ?
            <Grid item xs={4} classes={{root: classes.subscribedNeighborContainer}}>
            <div className={classes.placeHolderBlock}/></Grid>: null}



    </Grid>

}

export default withStyles(styles, {withTheme: true})(SNeighborhoodList);