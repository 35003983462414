import useDataApi from "../libs/fetch";
import {API_URL} from "../../constants";

/**
 * Constructs the air quality last updated date url 
 * @returns {string}
 */
export function getAirQualityLastUpdatedDateUrl() {
    return `${API_URL}/api/health/air_quality/last_updated`;
}

/**
 * Get the air quality last updated date
 */
function useAirQualityLastUpdatedDate() {
    const url = getAirQualityLastUpdatedDateUrl;
    const [airQualityLastUpdatedDateState, ,] = useDataApi(url, "", []);
    return [airQualityLastUpdatedDateState];
}

export default useAirQualityLastUpdatedDate;