import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Fade, LinearProgress, withStyles } from "@material-ui/core";
import { Helmet } from "react-helmet";
import {
  SAFETY_MAP_TITLE,
  SAFETY_MAP_DESCRIPTION,
  CROSSTOWN_TITLE,
  SAFETY_MAP_KEYWORDS,
  SAFETY_MAP_URL,
} from "../config/settings";
import NavBar from "../components/tracker/NavBar";
import Map from "../components/Map";
import MapFilter, {
  DEFAULT_BIG_BUNDLE_FILTER,
  DEFAULT_DATE_RANGE,
  DEFAULT_MODE_FILTER,
  DEFAULT_PARTITION_TYPE_FILTER,
  DEFAULT_SMALL_BUNDLE_FILTER,
  SAFETY_MAP_MODE_VALUE,
  TRAJECTORY_MODE_VALUE,
} from "../components/MapFilter/MapFilter";
import { fromJS } from "immutable";
import _ from "lodash";
import Log from "../utils/Log";
import useSearchCrimes, {
  getSearchCrimesParams,
  getSearchCrimesUrl,
} from "../components/api-hooks/hooks/useSearchCrimes";
import useFilters from "../components/api-hooks/hooks/useFilters";
import usePartitionData, {
  getPartitionDataUrl,
} from "../components/api-hooks/hooks/usePartitionData";
import { updatePercentiles } from "../utils/UpdatePercentiles";
import { updateDifference } from "../utils/UpdateDifference";
import getMapStyleWithLayers from "../components/api-hooks/hooks/getMapStyleWithLayers";
import SafetyMapLegend from "../components/MapLegend/SafetyMapLegend";
import Share from "../components/Share";
import ReactGA from "react-ga";
import LeftPanel from "components/LeftPanel";
import useNeighborhoodTrend, {
  getNeighborhoodTrendUrl,
} from "components/api-hooks/hooks/useNeighborhoodTrend";
import moment from "moment";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  placeholder: {
    position: "absolute",
    zIndex: 1000,
    height: 10,
    width: "100%",
  },
  thisNav: {
    padding: 100,
  },
});

const SafetyMapContainer = (props) => {
  const { classes } = props;

  // Filters state
  const [
    filters,
    setModeFilter,
    setTimePeriodFilter,
    setBigBundleFilter,
    setSmallBundleFilter,
    setPartitionTypeFilter,
  ] = useFilters(
    DEFAULT_MODE_FILTER,
    DEFAULT_DATE_RANGE,
    DEFAULT_BIG_BUNDLE_FILTER,
    DEFAULT_SMALL_BUNDLE_FILTER,
    DEFAULT_PARTITION_TYPE_FILTER
  );

  // Filter values
  const {
    selectedModeFilter,
    selectedTimePeriodFilter,
    selectedBigBundleFilter,
    selectedSmallBundleFilter,
    selectedPartitionTypeFilter,
  } = filters;

  // Selected feature from map
  const [selectedFeature, setSelectedFeature] = useState(null);

  // Map style
  const mapStyleWithLayers = getMapStyleWithLayers(selectedModeFilter);

  // Map style state
  const [mapStyle, setMapStyle] = useState(mapStyleWithLayers);

  const [searchPlaceState, setSearchPlace] = useState(null);

  const layerIds = ["partition-data", "trajectory-data"];

  // Partition data state
  const [partitionDataState, setPartitionDataUrl] = usePartitionData(
    selectedPartitionTypeFilter
  );
  useEffect(() => {
    const { isLoading, data } = partitionDataState;

    if (!isLoading && !_.isEmpty(data)) {
      Log.info(
        "Updating map style with partition source",
        SafetyMapContainer.name
      );
      setMapStyle((previousMapStyle) =>
        previousMapStyle.setIn(
          ["sources", "partition"],
          fromJS({
            type: "geojson",
            data: data,
          })
        )
      );
    }
  }, [partitionDataState]);

  // Tracts data state
  const [tractsDataState] = usePartitionData("tracts");
  useEffect(() => {
    const { isLoading, data } = tractsDataState;

    if (!isLoading && !_.isEmpty(data)) {
      Log.info(
        "Updating map style with tracts source",
        SafetyMapContainer.name
      );
      setMapStyle((previousMapStyle) =>
        previousMapStyle.setIn(
          ["sources", "tracts"],
          fromJS({
            type: "geojson",
            data: data,
          })
        )
      );
    }
  }, [tractsDataState]);

  // Crime rate data
  const params = getSearchCrimesParams(
    selectedTimePeriodFilter,
    selectedBigBundleFilter,
    selectedSmallBundleFilter
  );
  const [searchCrimesState, setSearchCrimesUrl, setSearchCrimesParams] =
    useSearchCrimes(selectedModeFilter, selectedPartitionTypeFilter, params);
  useEffect(() => {
    const { isLoading, data } = searchCrimesState;

    if (
      !isLoading &&
      !partitionDataState.isLoading &&
      !_.isEmpty(data) &&
      !_.isEmpty(partitionDataState.data)
    ) {
      Log.info("Updating map style with crime data", SafetyMapContainer.name);

      switch (filters.selectedModeFilter) {
        case SAFETY_MAP_MODE_VALUE:
          updatePercentiles(
            partitionDataState.data,
            data,
            "id",
            filters.selectedSmallBundleFilter,
            filters.selectedBigBundleFilter
          );
          break;
        case TRAJECTORY_MODE_VALUE:
          updateDifference(partitionDataState.data, data, "id");
          break;
        default:
          Log.error(`Unrecognized mode [${filters.selectedModeFilter}]`);
      }

      setMapStyle((previousMapStyle) =>
        previousMapStyle.setIn(
          ["sources", "partition"],
          fromJS({
            type: "geojson",
            data: partitionDataState.data,
          })
        )
      );
    }
    // TODO better way to handle deps, here adding filters.selectedModeFilter is problematic
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    searchCrimesState,
    partitionDataState,
    filters.selectedSmallBundleFilter,
    filters.selectedBigBundleFilter,
  ]);

  // Mode filter
  useEffect(() => {
    Log.info(
      `Mode changed: [${filters.selectedModeFilter}]`,
      SafetyMapContainer.name
    );
    const mapStyleWithLayers = getMapStyleWithLayers(
      filters.selectedModeFilter
    );
    const newMapStyle = mapStyleWithLayers
      .setIn(
        ["sources", "tracts"],
        fromJS({ type: "geojson", data: tractsDataState.data })
      )
      .setIn(
        ["sources", "partition"],
        fromJS({ type: "geojson", data: partitionDataState.data })
      );
    setSelectedFeature(null);
    setMapStyle(newMapStyle);
  }, [
    filters.selectedModeFilter,
    partitionDataState.data,
    tractsDataState.data,
  ]);

  // Time period, big and small bundle filters
  useEffect(() => {
    const params = getSearchCrimesParams(
      filters.selectedTimePeriodFilter,
      filters.selectedBigBundleFilter,
      filters.selectedSmallBundleFilter
    );

    const params_str = JSON.stringify(params);
    Log.info(`Filters changed: [${params_str}]`, SafetyMapContainer.name);
    setSearchCrimesParams(params);

    ReactGA.event({
      category: "Safety Map",
      action: "Search filters changed",
      label: params_str,
    });
  }, [
    filters.selectedTimePeriodFilter,
    filters.selectedBigBundleFilter,
    filters.selectedSmallBundleFilter,
    setSearchCrimesParams,
  ]);

  // Partition type filter
  useEffect(() => {
    Log.info(
      `Partition type changed: [${filters.selectedPartitionTypeFilter}]`,
      SafetyMapContainer.name
    );
    setPartitionDataUrl(
      getPartitionDataUrl(filters.selectedPartitionTypeFilter)
    );
    setSearchCrimesUrl(
      getSearchCrimesUrl(
        filters.selectedModeFilter,
        filters.selectedPartitionTypeFilter
      )
    );
    setSelectedFeature(null);
  }, [
    filters.selectedModeFilter,
    filters.selectedPartitionTypeFilter,
    setPartitionDataUrl,
    setSearchCrimesUrl,
  ]);

  // Partition type filter
  useEffect(() => {
    ReactGA.event({
      category: "Safety Map",
      action: "Partition type filter changed",
      label: filters.selectedPartitionTypeFilter,
    });
  }, [filters.selectedPartitionTypeFilter]);

  // Mode filter
  useEffect(() => {
    ReactGA.event({
      category: "Safety Map",
      action: "Mode filter changed",
      label: filters.selectedModeFilter,
    });
  }, [filters.selectedModeFilter]);

  // Search place
  useEffect(() => {
    ReactGA.event({
      category: "Safety Map",
      action: "Search place changed",
      label: JSON.stringify(searchPlaceState),
    });
  }, [searchPlaceState]);

  const isLoading = partitionDataState.isLoading || searchCrimesState.isLoading;

  /* Left Panel data */

  const [rank, setRank] = useState(0);
  const [allCrime, setAllCrime] = useState(0);
  const [crimePer100K, setCrimePer100K] = useState(0);
  const [searchName, setSearchName] = useState("");

  useEffect(() => {
    if (selectedFeature) {
      const featureProperties = selectedFeature.properties;
      setRank(featureProperties.ranking);
      setAllCrime(featureProperties.total_crime_counts);
      setCrimePer100K(
        Math.round(
          JSON.parse(featureProperties.total_crime_rate_per_100K) * 100
        ) /
          100 +
          " per 100K"
      );
      setSearchName(featureProperties.label);
      setChartTitle(selectedFeature.properties.label);
    }
  }, [selectedFeature]);

  const crime_statistics = [
    {
      key: "Crime Ranking",
      value: rank,
    },
    {
      key: "All Crime",
      value: allCrime,
    },
    {
      key: "Crime Rate",
      value: crimePer100K,
      info: "yes",
    },
  ];

  const [propertyName, setPropertyName] = useState("neighborhood");
  const [property, setProperty] = useState("");
  // const [href, setHref] = useState(`/neighborhood/${featureId}`);
  // const colorScale = ["#ED892E"];

  useEffect(() => {
    if (selectedFeature) setProperty(selectedFeature.properties[propertyName]);
    // setHref(`/${propertyName}/${featureId}`);
  }, [selectedFeature, propertyName]);

  useEffect(() => {
    if (selectedFeature)
      if (
        selectedFeature.properties.tract ||
        selectedFeature.source === "tracts"
      ) {
        setPropertyName("tract");
      } else if (selectedPartitionTypeFilter === "zipcodes") {
        setPropertyName("zipcode");
      }
  }, [selectedFeature, selectedPartitionTypeFilter]);

  const [chartData, setChartData] = useState([]);
  const [neighborhoodTrendState, setNeighborhoodTrendUrl] =
    useNeighborhoodTrend(propertyName, property);
  const [mapXaxisLabel, setxAxisLabel] = useState("");
  const mapYaxisLabel = "Number of Crimes";
  const [chartTitle, setChartTitle] = useState("");

  useEffect(() => {
    const { isLoading, data } = neighborhoodTrendState;
    let trendData = [];
    if (!isLoading && data.length > 0) {
      setxAxisLabel("Last " + data[0].trends.length + " months");
      data[0].trends.forEach(function (ele, idx) {
        let cur = moment(new Date(), "MM YYYY").format("MMM YYYY");
        let date = moment(ele.month + " " + ele.year, "MM YYYY").format(
          "MMM YYYY"
        );
        let crime_count = parseInt(ele.crime_count, 10);
        if (
          cur.split(" ")[0] === date.split(" ")[0] &&
          cur.split(" ")[1] === date.split(" ")[1]
        ) {
          return;
        }
        trendData.push({
          x: date,
          y: crime_count,
          label: "During " + date + "\nCrime Counts: " + crime_count,
        });
      });
    }
    setChartData([trendData]);
  }, [neighborhoodTrendState]);

  useEffect(() => {
    const url = getNeighborhoodTrendUrl(propertyName, property);
    setNeighborhoodTrendUrl(url);
  }, [propertyName, property, setNeighborhoodTrendUrl]);

  return (
    <div>
      <Helmet>
        <title>{SAFETY_MAP_TITLE}</title>
        <meta name="description" content={SAFETY_MAP_DESCRIPTION} />
        <meta name="keywords" content={SAFETY_MAP_KEYWORDS} />
        <meta property="og:title" content={SAFETY_MAP_TITLE} />
        <meta property="og:site_name" content={CROSSTOWN_TITLE} />
        <meta property="og:description" content={SAFETY_MAP_DESCRIPTION} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={SAFETY_MAP_URL} />
      </Helmet>

      <div className={classes.root}>
        <NavBar mode={"Crime"} />

        <div id="progressbar" className={classes.placeholder}>
          <Fade
            in={isLoading}
            style={{
              transitionDelay: isLoading ? "800ms" : "0ms",
            }}
            unmountOnExit
          >
            <LinearProgress color="secondary" />
          </Fade>
        </div>

        <Share />

        <Map
          mapStyle={mapStyle}
          onSelectedFeature={setSelectedFeature}
          interactiveLayerIds={layerIds}
          partitionType={selectedPartitionTypeFilter}
          searchAddress={searchPlaceState}
          partitionData={partitionDataState.data}
        />

        <LeftPanel
          reportTitle="Crime Report"
          reportTitleDesc="This map gives an overview of overall crime that has occured in the Los Angeles county area."
          onSearchAddressChanged={(value) => setSearchPlace(value)}
          //   lastUpdated={lastUpdated}
          selectedFeature={selectedFeature}
          isLoadingData={isLoading}
          statistics={rank === "N/A" ? null : crime_statistics}
          searchName={searchName}
          chartData={chartData}
          mapXaxisLabel={mapXaxisLabel}
          mapYaxisLabel={mapYaxisLabel}
          chartTitle={chartTitle}
          showFaq={false}
        ></LeftPanel>

        <MapFilter
          selectedMode={selectedModeFilter}
          selectedTimePeriod={selectedTimePeriodFilter}
          selectedBigBundle={selectedBigBundleFilter}
          selectedSmallBundle={selectedSmallBundleFilter}
          selectedPartitionType={selectedPartitionTypeFilter}
          onModeChanged={(value) => setModeFilter(value)}
          onTimePeriodChanged={(value) => setTimePeriodFilter(value)}
          onBigBundleChanged={(value) => setBigBundleFilter(value)}
          onSmallBundleChanged={(value) => setSmallBundleFilter(value)}
          onPartitionChanged={(value) => setPartitionTypeFilter(value)}
          onSearchAddressChanged={(value) => setSearchPlace(value)}
        />

        <SafetyMapLegend selectedMode={selectedModeFilter} />

        {/* <MapFeatureTooltip
          selectedTimePeriodFilter={selectedTimePeriodFilter}
          selectedCrimeTypes={selectedSmallBundleFilter}
          selectedPartitionType={selectedPartitionTypeFilter}
          selectedFeature={selectedFeature}
        /> */}
      </div>
    </div>
  );
};

SafetyMapContainer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SafetyMapContainer);
