import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import {withStyles} from "@material-ui/core/styles/index";
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import './style/styles.css';
import {
    // AQI_URL,
    CRIME_TRACKER_URL,
    FACEBOOK_URL,
    IMAGE_PATH,
    MAP_URL,
    NEIGHBORHOOD_URL,
    SUBSCRIBE_USER, TO_CRIMETRACKER,
    TO_NEIGHBORHOODTRACKER,
    TWITTER_URL
} from "../constants";
import Icon from '@mdi/react'
import InputBar from '../tracker/InputBar'
import { mdiFacebookBox, mdiTwitter, mdiEmail, mdiMapMarker,mdiPhone } from '@mdi/js';
import PubSub from "pubsub-js";
const styles = theme => ({
    footContainer:{
        width: '90%',
        margin: 'auto',
        padding: '30px 0',
        paddingTop: '50px',
        [theme.breakpoints.up('md')]:{
            width: '70%',
        },
        [theme.breakpoints.up('lg')]:{
            width: '1020x'
        }
    },
    desktopLogo:{
        display: 'none',
        [theme.breakpoints.up('md')]:{
            display: 'block',
            width: '40%',
        }
    },
    mobileLogo:{
        display: 'flex',
        textAlign: 'center',
        marginTop: '40px',
        [theme.breakpoints.up('md')]:{
            display: 'none'
        }
    },
    mobileInputContainer:{
        marginTop: '10px',
        width: '100%',
        marginBottom: '20px',
        [theme.breakpoints.up('md')]:{
            display: 'none'
        }
    },
    logoContainer:{
        marginTop: '30px',
    },
    logoStyle:{

    },
    aboutUsBlock:{
        width: '50%',
        display: 'block',
        [theme.breakpoints.up('md')]:{
            width: '20%',
        }
    },
    ourProductBlock:{
        width: '50%',
        display: 'block',
        [theme.breakpoints.up('md')]:{
            width: '20%',
        }
    },
    desktopContactUs:{
        display: 'none',
        [theme.breakpoints.up('md')]:{
            display: 'block',
            width: '20%',
        }
    },
    mobileContactUs:{
        display: 'flex',
        marginTop: '10px',
        [theme.breakpoints.up('md')]:{
            display: 'none',
        }
    },
    signUpButton:{
        backgroundColor: '#ff9700',
        width: '90px',
        height: '30px',
        padding: '0',
        borderRadius: '10px',
        marginLeft: '0px',
        marginTop: '20px',
        marginRight: '100px',
        [theme.breakpoints.up('sm')]:{
            marginTop: '0px',
            marginLeft: '20px',
            marginRight: '0'
        },
        [theme.breakpoints.up('md')]:{
            width: '95px',
            marginLeft: '0px',
            marginTop: '20px',
            marginRight: '100px',
        },
        [theme.breakpoints.up('lg')]:{
            marginLeft: '20px',
            marginTop: '0',
            marginRight: '0'
        },
        '&:hover':{
            backgroundColor: '#FFB019',
            boxShadow: 'none'
        },
        boxShadow: 'none'
    },
    signUpButtonLabel:{
        fontFamily: 'rambla',
        fontSize: '14px',
        fontWeight: '300',
        color: '#fff',
        letterSpacing: '0.9px',
        textTransform: 'none',
        lineHeight: '30px',
    },
    socialIcon:{
        color: '#eee',
        padding: '0',
        position: 'relative',
        top: '5px',
        marginRight: '10px'
    },
    footerList:{
        margin: '20px 0px'
    },
    footerTitle:{
        fontFamily: 'Rambla',
        fontSize: '22px',
        fontWeight: '700',
        lineHeight: '26px',
        letterSpacing: '1.1px',
        marginBottom: '8px'
    },
    footerBody:{
        whiteSpace: 'pre-wrap',
        fontFamily: 'helvetica',
        fontSize: '12px',
        fontWeight: '400',
        letterSpacing: '0.69px',
        lineHeight: '20px',
        marginBottom: '6px',
        [theme.breakpoints.up('md')]:{
            fontSize: '14px',
            letterSpacing: '0.8px',
            lineHeight: '25px',
        }
    },
    signUpBar:{
        marginTop: '17px'
    },
    copyrightStyle:{
        color: '#fff',
        fontFamily: 'helvetica',
        fontSize: '14px',
        fontWeight: '400',
        letterSpacing: '0.8px',
        lineHeight: '25px',
        whiteSpace: 'pre-wrap'
    },
    copyrightMobileStyle:{
        color: '#fff',
        fontFamily: 'helvetica',
        fontSize: '12px',
        fontWeight: '400',
        letterSpacing: '0.69px',
        marginTop: '15px'
    },
    columnTitle:{
        fontFamily: 'rambla',
        fontSize: '16px',
        fontWeight: '700',
        color: '#fff',
        letterSpacing: '1px',
        lineHeight: '25px'
    },
    columnBody:{
        color: '#fff',
        fontFamily: 'helvetica',
        fontSize: '12px',
        fontWeight: '400',
        letterSpacing: '0.8px',
        lineHeight: '20px',
        textDecoration: 'none',
        position: 'relative',
        [theme.breakpoints.up('md')]:{
            fontSize: '14px',
            lineHeight: '25px',
        }
    },
    mobileInputBar:{
        width:"100%",
    }
});

class Footer extends Component{

    constructor(props){
        super(props);
        this.state = {
            userEmail: ''
        }
    }

    updateUseEmail = (email) =>{
        this.setState({
            userEmail: email
        })
    };

    subscribeUser = () =>{
        let data = {
            class: SUBSCRIBE_USER,
            userEmail: this.state.userEmail
        };

        // Cannot know which to send, just broadcast
        PubSub.publish(TO_NEIGHBORHOODTRACKER, data);
        PubSub.publish(TO_CRIMETRACKER, data);
    };


    render(){
        const {classes} = this.props;
        const content = 'Tell us what do you want to know about crime, traffic and air quality. \nWant to know more? Sign up for our newsletter.';
        return(
            <div className={classes.footContainer} id="footer">
                <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="flex-start"
                >
                    <Typography variant={'h5'} color={'inherit'} classes={{root: classes.footerTitle}}><strong> You are here.</strong></Typography>
                    <Typography variant={'body1'} color={'inherit'} classes={{root: classes.footerBody}}>{content}</Typography>
                    <Grid container spacing={0} className={classes.footerList}>
                        {/* Logo and subscription bar*/}
                        <div className={classes.desktopLogo}>
                            <Grid
                                container
                                direction="row"
                                justify="flex-start"
                                alignItems="center"
                            >
                                <InputBar updateHandler = {this.updateUseEmail} fixedWidth={true}/>
                                <Button variant="contained" classes={{root:classes.signUpButton, label: classes.signUpButtonLabel}}
                                        onClick = {this.subscribeUser}
                                >
                                    Sign Up
                                </Button>
                            </Grid>
                            <div className={classes.logoContainer}>
                                <img src= {IMAGE_PATH+ "/Crosstown_White.png"} height="32px" width="167px" alt={'logo '}/>
                                <Typography variant={'body1'} color={'inherit'} className={classes.copyrightStyle}>@ 2019 All rights reserved</Typography>
                            </div>
                        </div>

                        {/* mobile version of logo and subscription bar */}
                        <div className={classes.mobileInputContainer}>
                            <Grid
                                container
                                direction="row"
                                justify="flex-start"
                                alignItems="center"
                            >
                                <InputBar width={'70%'} display={'inline-block'} updateHandler = {this.updateUseEmail}/>
                                <Button variant="contained" classes={{root:classes.signUpButton, label: classes.signUpButtonLabel}}
                                        onClick = {this.subscribeUser}
                                >
                                    Sign Up
                                </Button>
                            </Grid>
                        </div>
                        {/* About Us */}
                        <div className={classes.aboutUsBlock}>
                            <Grid
                                container
                                direction="column"
                                justify="center"
                                alignItems="flex-start"
                            >
                                <Typography variant={'subtitle2'} color={'inherit'} className={classes.columnTitle}>About Us</Typography>
                                <Typography variant={'body1'} color={'inherit'} className={classes.columnBody}>
                                    <a className={classes.columnBody} href={'https://xtown.la/about-us'}>About CrossTown</a>
                                </Typography>
                                <Typography variant={'body1'} color={'inherit'} className={classes.columnBody}>
                                    <a className={classes.columnBody} href={'https://xtown.la/collaborate-with-us/'}>Collaborate With Us</a>
                                </Typography>
                                <Typography variant={'body1'} color={'inherit'} className={classes.columnBody}>
                                    <a className={classes.columnBody} href={'https://xtown.la/team-bios'}>Team Bios</a>
                                </Typography>
                                <Typography variant={'body1'} color={'inherit'} className={classes.columnBody}>
                                    <a className={classes.columnBody} href={'https://xtown.la/our-data/'}>Our Data</a>
                                </Typography>
                                <Typography variant={'body1'} color={'inherit'} className={classes.columnBody}>
                                    <a className={classes.columnBody} href={'https://xtown.la/re-publishing-guidelines/'}>Republishing Guidelines</a>
                                </Typography>
                                <Typography variant={'body1'} color={'inherit'} className={classes.columnBody}>
                                    <a className={classes.columnBody} href={'https://xtown.la/sitemap.xml'}>Sitemap</a>
                                </Typography>
                            </Grid>
                        </div>

                        {/* Our product */}
                        <div className={classes.ourProductBlock}>
                            <Grid
                                container
                                direction="column"
                                justify="center"
                                alignItems="flex-start"
                            >
                                <Typography variant={'subtitle2'} color={'inherit'} className={classes.columnTitle}>Our Products</Typography>
                                <Typography variant={'body1'} color={'inherit'} className={classes.columnBody}>
                                    <a className={classes.columnBody} href={MAP_URL}>Safety Map</a>
                                </Typography>
                                <Typography variant={'body1'} color={'inherit'} className={classes.columnBody}>
                                    <a className={classes.columnBody} href={NEIGHBORHOOD_URL}>Neighborhood Tracker</a>
                                </Typography>
                                <Typography variant={'body1'} color={'inherit'} className={classes.columnBody}>
                                    <a className={classes.columnBody} href={CRIME_TRACKER_URL}>Crime Tracker</a>
                                </Typography>
                                {/*<Typography variant={'body1'} color={'inherit'} className={classes.columnBody}>*/}
                                {/*    <a className={classes.columnBody} href={AQI_URL}>Air Quality Tracker</a>*/}
                                {/*</Typography>*/}
                            </Grid>


                            {/*Contact Us block in mobile mode*/}
                            <Grid
                                container
                                direction="column"
                                justify="center"
                                alignItems="flex-start"
                                className={classes.mobileContactUs}
                            >
                                <Typography variant={'subtitle2'} color={'inherit'} className={classes.columnTitle}>Contact Us</Typography>
                                <Grid
                                    container
                                    direction="row"
                                    justify="flex-start"
                                    alignItems="flex-start"
                                >
                                    <a className={classes.columnBody} href={'mailto:askus@xtown.la'}>
                                        <Icon path={mdiEmail}
                                              className={classes.socialIcon}
                                              size={0.8}
                                              color={'#eee'}
                                        />
                                    </a>
                                    <a className={classes.columnBody} href={FACEBOOK_URL}>
                                        <Icon path={mdiFacebookBox}
                                              size={0.8}
                                              className={classes.socialIcon}
                                              color={'#eee'}
                                        />
                                    </a>
                                    <a className={classes.columnBody} href={TWITTER_URL}>
                                        <Icon path={mdiTwitter}
                                              size={0.8}
                                              className={classes.socialIcon}
                                              color={'#eee'}
                                        />
                                    </a>
                                    <br />
                                    <a className={classes.columnBody} href='https://www.google.com/maps/place/3502+Watt+Way+%23227B,+Los+Angeles,+CA+90007/@34.0220211,-118.2887856,17z/data=!3m1!4b1!4m5!3m4!1s0x80c2c7e4adc42b9f:0xd4b2fc991c8de6ad!8m2!3d34.0220167!4d-118.2865969'>
                                        <Icon path={mdiMapMarker}
                                              size={0.8}
                                              className={classes.socialIcon}
                                              color={'#eee'}
                                        />
                                        3502 Watt Way Suite 227B
                                        Los Angeles, CA 90007
                                    </a>
                                    <br />
                                    <div className={classes.columnBody}>
                                        <Icon path={mdiPhone}
                                              size={0.8}
                                              className={classes.socialIcon}
                                              color={'#eee'}
                                        />
                                        (323) 207-6738‬
                                    </div>
                                </Grid>
                            </Grid>
                        </div>

                        {/* Contact Us */}
                        <div className={classes.desktopContactUs}>
                            <Grid
                                container
                                direction="column"
                                justify="center"
                                alignItems="flex-start"
                            >
                                <Typography variant={'subtitle2'} color={'inherit'} className={classes.columnTitle}>Contact Us</Typography>

                                <Typography variant={'body1'} color={'inherit'} className={classes.columnBody}>
                                    <a href={'mailto:askus@xtown.la'} className={classes.columnBody}>
                                        <Icon path={mdiEmail}
                                              className={classes.socialIcon}
                                              size={0.8}
                                              color={'#eee'}
                                        />
                                        askus@xtown.la</a>
                                </Typography>
                                <Typography variant={'body1'} color={'inherit'} className={classes.columnBody}>
                                    <a className={classes.columnBody} href={TWITTER_URL}>
                                        <Icon path={mdiTwitter}
                                              size={0.8}
                                              className={classes.socialIcon}
                                              color={'#eee'}
                                        />
                                        Twitter
                                    </a>
                                </Typography>
                                <Typography variant={'body1'} color={'inherit'} className={classes.columnBody}>
                                    <a className={classes.columnBody} href={FACEBOOK_URL}>
                                        <Icon path={mdiFacebookBox}
                                              size={0.8}
                                              className={classes.socialIcon}
                                              color={'#eee'}
                                        />
                                        Facebook
                                    </a>
                                </Typography>
                                <Typography variant={'body1'} color={'inherit'} className={classes.columnBody}>
                                    <a className={classes.columnBody} href='https://www.google.com/maps/place/3502+Watt+Way+%23227B,+Los+Angeles,+CA+90007/@34.0220211,-118.2887856,17z/data=!3m1!4b1!4m5!3m4!1s0x80c2c7e4adc42b9f:0xd4b2fc991c8de6ad!8m2!3d34.0220167!4d-118.2865969'>
                                        <Icon path={mdiMapMarker}
                                              size={0.8}
                                              className={classes.socialIcon}
                                              color={'#eee'}
                                        />
                                        3502 Watt Way Suite 227B
                                        Los Angeles, CA 90007
                                    </a>
                                </Typography>
                                <Typography variant={'body1'} color={'inherit'} className={classes.columnBody}>
                                    <Icon path={mdiPhone}
                                          size={0.8}
                                          className={classes.socialIcon}
                                          color={'#eee'}
                                    />
                                    (323) 207-6738‬
                                </Typography>
                            </Grid>
                        </div>

                        {/* Logo */}
                        <Grid item xs={12} className={classes.mobileLogo}>
                            <Grid   container
                                    direction="column"
                                    justify="center"
                                    alignItems="center"
                            >
                                <img src= {IMAGE_PATH+ "/Crosstown_White.png"} height="32px" width="167px" alt={'logo '} />
                                <Typography variant={'body1'} color={'inherit'} className={classes.copyrightMobileStyle}>@2019 All rights reserved</Typography>
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>
            </div>
        );

    }


}


export default withStyles(styles, {withTheme: true})(Footer)