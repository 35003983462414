import {useState} from "react";


const useFilters = (initialMode, initialDateRange, initialBigBundle, initialSmallBundle, initialPartitionType) => {
    const [state, setState] = useState({
        selectedModeFilter: initialMode,
        selectedTimePeriodFilter: initialDateRange,
        selectedBigBundleFilter: initialBigBundle,
        selectedSmallBundleFilter: initialSmallBundle,
        selectedPartitionTypeFilter: initialPartitionType
    });

    function updateState(vals) {
        setState(prevState => {
            return {...prevState, ...vals};
        });
    }

    function setModeFilter(value) {
        updateState({selectedModeFilter: value});
    }

    function setTimePeriodFilter(value) {
        updateState({selectedTimePeriodFilter: value});
    }

    function setBigBundleFilter(value) {
        updateState({selectedBigBundleFilter: value});
    }

    function setSmallBundleFilter(value) {
        updateState({selectedSmallBundleFilter: value});
    }

    function setPartitionTypeFilter(value) {
        updateState({selectedPartitionTypeFilter: value});
    }

    return [state, setModeFilter, setTimePeriodFilter, setBigBundleFilter, setSmallBundleFilter, setPartitionTypeFilter];
};

export default useFilters