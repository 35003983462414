import useDataApi from "../libs/fetch";
import {API_URL} from "../../constants";

/**
 * Constructs the air quality trend url
 * @returns {string}
 */
export function getAirQualityTrendUrl(neighborhood, year, month, day) {
    return `${API_URL}/api/health/air_quality/neighborhood?neighborhood=${neighborhood}&year=${year}&month=${month}&day=${day}`;
}

/**
 * Get air quality trend for a neighborhood
 */
function useAirQualityTrend(neighborhood, year, month, day) {
    const url = getAirQualityTrendUrl(neighborhood, year, month, day);
    const [airQualityTrendState, setAirQualityTrendUrl,] = useDataApi(url, "", []);
    return [airQualityTrendState, setAirQualityTrendUrl];
}

export default useAirQualityTrend;