// Implementation of a basic Trie class

function Node(){
    this.childs = [];               // Array that contains list of child nodes
    this.dataList = [];             // Array that contains list of generic data
    this.dataSet = new Set();       // Set to avoid duplicate entries
}


export default function Trie() {
    this.head = new Node();
}

Trie.prototype.insert = function(value){
    let currNode = this.head;
    let trimmed = value.toLowerCase().replace(/\s/g, '');
    if(!currNode.dataSet.has(value)) {
        currNode.dataList.push(value);
        currNode.dataSet.add(value);
    }
    for(let i = 0; i<trimmed.length; i++){
        let idx = trimmed.charCodeAt(i)- 'a'.charCodeAt(0);
        if(currNode.childs[idx] === undefined) {
            currNode.childs[idx] = new Node();
        }
        currNode = currNode.childs[idx];
        if(!currNode.dataSet.has(value)) {
            currNode.dataList.push(value);
            currNode.dataSet.add(value);
        }
    }

};

Trie.prototype.findAll = function(prefix){
    let trimmed = prefix.toLowerCase().replace(/\s/g, '');
    let currNode = this.head;
    for(let i = 0; i<trimmed.length; i++){
        let idx = trimmed.charCodeAt(i)- 'a'.charCodeAt(0);
        if(currNode.childs[idx] === undefined){
            return [];
        }
        currNode = currNode.childs[idx];
    }

    return currNode.dataList;

};