import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import {withStyles} from "@material-ui/core/styles/index";
import Typography from '@material-ui/core/Typography';
import Link from "@material-ui/core/Link";

const styles = theme => ({
    paragraph: {
        marginLeft: '1rem'
    },

    aboutContainer: {
        marginTop: '10px'
    },

    aboutTitleRoot: {
        fontFamily: 'Rambla',
        fontSize: '16px',
        fontWeight: '700',
        color: '#383838',
        lineHeight: 1.5,
        letterSpacing: '1.2px'
    },

    paragraphRoot: {
        fontFamily: 'helvetica',
        lineHeight: 1.5,
        fontSize: '14px',
        fontWeight: '400',
        color:'#7C7C7C',
        letterSpacing: '0.8px',
        marginBottom:'6px',
        whiteSpace: 'pre-wrap'
    }

});

const crime_title = "About Our Crime Data";
const crime_para = "1. Our data comes from the two largest agencies, the Los Angeles Police Department and the Los Angeles County Sheriff’s Department, " +
    "as well as smaller agencies like the Pasadena and Santa Monica police departments\n\n" +
    "2. Some of the crime names we use describe multiple official crimes. For example, when you see the crime \"theft from vehicle\" " +
    "in the table above, that represents a bundle of five of LAPD's \"crime code descriptions,\" including \"THEFT FROM MOTOR VEHICLE -" +
    "ATTEMPT\" and \"BURGLARY FROM VEHICLE.\".";

const aqi_title = "About Our Air Quality Data";
const aqi_para = "Our air quality data is derived from the 12 monitoring stations of the " +
    "Environmental Protection Agency and the South Coast Air Quality Management District. " +
    "We collect hourly PM 2.5 AQI (Air Quality Index) data through the Airnow API. PM 2.5 refers " +
    "to the mixture of solid particles and liquid droplets found in the air. PM 2.5 consists of " +
    "particles smaller than 2.5 µm in aerodynamic diameter. How small is that? The EPA compares it " +
    "to a single hair from your head. The average human hair is about 70 micrometers in diameter, " +
    "which is as much as 30 times larger than the largest fine particle.";
const contact = "If you have any questions about our data, send us an email: ";

class About extends Component {

    render() {
        const {classes, tracker} = this.props;

        return (
            <Grid container spacing={3} className={classes.aboutContainer}>
                <Grid item xs={12}>
                    <Typography variant={'subtitle2'} classes={{root: classes.aboutTitleRoot}}>
                        {tracker === "Crime Tracker" ? crime_title : tracker === "Air Quality" ? aqi_title : ''}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant={'body1'} align={'justify'} paragraph className={classes.paragraph}
                                classes={{root: classes.paragraphRoot}}>
                        {tracker === "Crime Tracker" ? crime_para : tracker === "Air Quality" ? aqi_para : ''}
                    </Typography>

                    <Typography variant={'body1'} align={'justify'} paragraph className={classes.paragraph}
                                classes={{root: classes.paragraphRoot}}>
                        {"Learn more "}
                        <Link href={"https://xtown.la/our-data/"} style={{color:'#FF9700'}} >
                            {"here"}
                        </Link>
                        {'.'}
                    </Typography>

                    <Typography variant={'body1'} align={'justify'} paragraph className={classes.paragraph}
                                classes={{root: classes.paragraphRoot}}>
                        {contact}
                        <Link href={"mailto:askus@xtown.la"} style={{color:'#FF9700'}}>
                            {"askus@xtown.la"}
                        </Link>
                    </Typography>
                </Grid>

            </Grid>
        );
    }
}

export default withStyles(styles, {withTheme: true})(About)