import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {withStyles} from '@material-ui/core/styles';

import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import Input from '@material-ui/core/Input';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import {fade} from '@material-ui/core/styles/colorManipulator';
import SearchIcon from '@material-ui/icons/Search';
import Log from "../../utils/Log";

import {SearchPlace} from '../../utils/SearchPlace'
import PubSub from 'pubsub-js';
import {SELECTED_PLACE_SUGGESTION} from '../../config/settings'
import 'styles/css/Autocomplete.css'

import ReactGA from 'react-ga';
import {UPDATE_AREA, API_URL} from "../constants";

const styles = theme => ({

    suggestionContainer: {
        flexGrow: 1,
        position: 'relative',
        justifyContent: 'flex-end',
        marginLeft: 10,
    },
    suggestionsContainerOpen: {
        position: 'absolute',
        zIndex: 10000,
        marginTop: theme.spacing(2),
        top: 20,
        width: '100%',
        right: 0,
    },
    suggestion: {
        display: 'block',
    },
    suggestionsList: {
        margin: 0,
        padding: 0,
        listStyleType: 'none',
    },

    autocompleteInputWrap: {
        width: '100%'
    },

    root: {
        width: '100%',
    },

    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.90),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 1),
        },
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: 'auto',
        },
    },
    searchIcon: {
        width: theme.spacing(5),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        width: '100%',
    },
    inputInput: {
        paddingTop: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(5),
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: 170,
            '&:focus': {
                width: 220,
            },
        },
    },
});

class AQIAutocomplete extends Component {

    constructor(props) {
        super(props);
        this.state = {
            suggestions: [],
            placeholder: 'Search for an address',
            searchValue: ''
        };
        this._fitBounds = (data) => {
        };
        this._selectedPartitionType = 'Neighborhoods';
    }

    componentDidMount() {
        const {coordinates} = this.props;
        if(coordinates != null){
            this._search(coordinates, true);
        }
    }

    componentWillUnmount() {

    }

    _handleSuggestionsFetchRequested = ({value}) => {
        this._getSuggestions(value);
    };

    _handleSuggestionsClearRequested = () => {
        this.setState({
            suggestions: [],
        });
    };

    _gaEvent(value) {

        ReactGA.event({
            category: 'Air Quality Search',
            action: this.state.placeholder,
            label: "aqi/" + value
        });

    }

    _onChange = (event, {newValue, method}) => {
        this.setState({
            searchValue: newValue,
        });
    };

    _onSuggestionSelected = (event, {suggestion, suggestionValue, suggestionIndex, sectionIndex, method}) => {
        this._selectedPlace(suggestion);
    };

    _selectedPlace(suggestion) {
        let center = suggestion.center;
        const lat = center[1];
        const long = center[0];

        let go_to_url;
        let window_url = window.location.href.split("/");
        if(window_url[window_url.length - 1] === "aqi"){
            go_to_url = 'aqi/' + suggestion.label + '/' + long + ',' + lat;
        } else if(window_url[window_url.length - 2] === "aqi"){
            go_to_url = suggestion.label + '/' + long + ',' + lat;
        } else {
            go_to_url = '../' + suggestion.label + '/' + long + ',' + lat;
        }

        let url = API_URL + '/api/neighborhoods/get_neighborhood?lat=' + lat +'&long=' + long;
        fetch(url)
            .then(response => response.json())
            .then(json => {
                Log.info(json);
                if(json.result.data.length > 0){
                    window.history.pushState('', '', go_to_url);
                    suggestion.neighborhood = json.result.data[0].neighborhood;
                    PubSub.publish(SELECTED_PLACE_SUGGESTION, suggestion);
                    PubSub.publish(UPDATE_AREA, suggestion.label);
                } else {

                }
            });
    }

    _getSuggestionValue = suggestion => {
        return suggestion.address;
    };

    _shouldRenderSuggestions = () => {
        return true;
    };

    _getSuggestions(value) {
        this._search(value, false);
    }

    _search(term, autoselect = true) {
        const inputValue = term.trim().toLowerCase();
        const inputLength = inputValue.length;
        let self = this;
        // if (suggestLoading || loading) {
        if (inputLength !== 0) {
            SearchPlace(inputValue, function (err, res, body, searchTime) {
                if (!err) {
                    if ('features' in body) {

                        let _suggestions = [];
                        body.features.forEach(function (ele, idx) {
                            // Log.info("ele ", ele);
                            _suggestions.push({label: ele.text, address: ele.place_name, center: ele.center});
                        });

                        if(!autoselect){
                            self.setState({
                                suggestions:  _suggestions
                            });
                        } else {
                            self._selectedPlace(_suggestions[0]);
                        }

                    }
                }
            });
        }
    }

    _renderInputComponent = inputProps => {
        const {classes, ref, ...other} = inputProps;
        return (
            <div className={classes.search}>
                <div className={classes.searchIcon}>
                    <SearchIcon/>
                </div>
                <Input
                    id="searchBar"
                    disableUnderline
                    inputProps={other}
                    classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput,
                    }}
                    inputRef={ref}
                />
            </div>
        );
    };

    _renderSuggestion(suggestion, {query, isHighlighted}) {
        const matches = match(suggestion.address, query);
        const parts = parse(suggestion.address, matches);

        return (
            <MenuItem className='autocomplete-item' selected={isHighlighted} component="div">
                <div>
                    {parts.map((part, index) => {
                        return part.highlight ? (
                            <span key={String(index)} style={{fontWeight: 300}}>
                  {part.text}
                </span>
                        ) : (
                            <strong key={String(index)} style={{fontWeight: 500}}>
                                {part.text}
                            </strong>
                        );
                    })}
                </div>
            </MenuItem>
        );
    }

    _renderSuggestionsContainer = options => {
        const {containerProps, children} = options;

        return (
            <Paper {...containerProps} square>
                {children}
            </Paper>
        );
    };

    render() {
        const {classes} = this.props;

        // Autosuggest will pass through all these props to the input.
        const inputProps = {
            classes,
            placeholder: this.state.placeholder,
            value: this.state.searchValue,
            onChange: this._onChange
        };

        return (
            <Autosuggest
                theme={{
                    container: classes.suggestionContainer,
                    suggestionsContainerOpen: [classes.suggestionsContainerOpen, "suggestionsContainerOpen"].join(' '),
                    suggestionsList: classes.suggestionsList,
                    suggestion: classes.suggestion,
                }}
                renderInputComponent={this._renderInputComponent}
                suggestions={this.state.suggestions}
                shouldRenderSuggestions={this._shouldRenderSuggestions}
                onSuggestionsFetchRequested={this._handleSuggestionsFetchRequested}
                onSuggestionsClearRequested={this._handleSuggestionsClearRequested}
                renderSuggestionsContainer={this._renderSuggestionsContainer}
                getSuggestionValue={this._getSuggestionValue}
                renderSuggestion={this._renderSuggestion}
                onSuggestionSelected={this._onSuggestionSelected}
                highlightFirstSuggestion={true}
                inputProps={inputProps}
            />);

    }
}

AQIAutocomplete.propTypes = {
    classes: PropTypes.object.isRequired
};


export default withStyles(styles)(AQIAutocomplete);