import xhr from 'xhr'
import {MAX_BOUNDS_ARR} from 'config/settings'

export function SearchPlace(query, callback) {
  var searchTime = new Date();
  let types = 'address,poi';//'postcode,place,address,poi,neighborhood';
  let proximity = '';
  let bbox = MAX_BOUNDS_ARR;
  var uri = 'https://api.tiles.mapbox.com/geocoding/v5/mapbox.places/' + encodeURIComponent(query) + '.json' +
    '?access_token=' + process.env.REACT_APP_MAP_BOX_ACCESS_TOKEN +
    (proximity ? '&proximity=' + proximity : '') +
    (bbox ? '&bbox=' + bbox : '') +
    (types ? '&types=' + encodeURIComponent(types) : '');
  xhr({
    uri: uri,
    json: true
  }, function(err, res, body) {
    callback(err, res, body, searchTime);
  });
}
