import React from 'react';
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon';

export default createSvgIcon(
    <React.Fragment>
        <path
            d="M20.684 11.055l-1.782-.41.567-1.778-.043-.015a17.69 17.69 0 0 0-2.262-.575A5.21 5.21 0 0 1 12 12.852a5.214 5.214 0 0 1-5.152-4.47c-.809.173-1.57.384-2.27.634l.52 1.629-1.782.41L0 17.172v3.723h3.953v-6.278h16.094v6.278H24V17.14zm0 0M17.86 3.824A6.461 6.461 0 0 0 13.257.18a6.49 6.49 0 0 0-2.516 0 6.461 6.461 0 0 0-4.601 3.644L4.43 7.582c2.09-.703 4.746-1.105 7.527-1.137 2.762-.03 5.414.309 7.531.953zm0 0"/>
        <path
            d="M8.234 8.137A3.806 3.806 0 0 0 12 11.445a3.803 3.803 0 0 0 3.773-3.386 28.822 28.822 0 0 0-7.539.078zm0 0M5.36 23.945h13.28v-7.922H5.36zM12 18.988l.996.996-.996.993-.996-.993zm0 0"/>
    </React.Fragment>
    , 'Crimes');