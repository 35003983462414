import React from 'react';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/Warning';
import { withStyles } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import classNames from 'classnames';
const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
};

const contentStyles = theme => ({
    success: {
        backgroundColor: '#fff',
        color: green[600]
    },
    error: {
        color: theme.palette.error.dark,
        backgroundColor: '#fff'
    },
    info: {
        backgroundColor: theme.palette.primary.dark,
    },
    warning: {
        color: amber[700],
        backgroundColor: '#fff'
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
    contentRoot:{
        boxShadow: '0px 0px 0px 0px',
        fontFamily: 'helvetica',
        fontSize: '14px',
        fontWeight: '400',
    },
    closeButton:{
        display: 'inline',
        padding: '0'
    },
    messageRoot:{
        display: 'inlineBlock'
    },
    actionRoot:{
        display: 'inlineBlock',
        padding: '0',
        [theme.breakpoints.up('md')]:{
            paddingLeft: '24px',
            marginRight: '-8px'
        }
    },
    subMessageRoot:{
        boxShadow: '0px 0px 0px 0px',
        fontFamily: 'helvetica',
        fontSize: '14px',
        fontWeight: '400',
        marginBottom: '40px'
    }
});


function CustomSnackbarContent(props){
    const {classes, message, link, variant, optionClass, onClose} = props;
    const Icon = variantIcon[variant];
    const linkContent = "  here";
    return(

        <SnackbarContent
            className={classNames(classes[variant], optionClass)}
            classes={link===undefined?{root: classes.contentRoot, message: classes.messageRoot,
                action: classes.actionRoot}:{root: classes.subMessageRoot, message: classes.messageRoot,
                action: classes.actionRoot}}
            message={
                <span id="client-snackbar" className={classes.message}>
                    <Icon className={classNames(classes.icon, classes.iconVariant)} />
                    <span>{message}</span>
                    {link!== undefined?<a href={'/subscription/'+link} style={{marginLeft: '10px'}}>{linkContent}</a>:null}
                </span>
            }
            action={[
                <IconButton
                    key="close"
                    aria-label="Close"
                    color="inherit"
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <CloseIcon className={classes.icon} />
                </IconButton>,
            ]}

        />
    )
}

const CustomSnackBarContentWrapper = withStyles(contentStyles)(CustomSnackbarContent);

const snackBarStyles = theme => ({
    margin: {
        margin: '0px',
    },
    snackBarRoot:{
    }
});

const MessageSnackBar = (props) => {

    const {variant, message, link, classes, vertical, horizontal, isMobile, closeSnackBarHandler} = props;
    return(
        <Snackbar
            anchorOrigin={{
                vertical: vertical||isMobile?'top':'bottom',
                horizontal: horizontal||'left',
            }}
            open={true}
            autoHideDuration={3000}
            classes={{root: classes.boxShadow}}
        >
            <CustomSnackBarContentWrapper
                variant = {variant}
                message = {message}
                link = {link}
                onClose = {closeSnackBarHandler}
            />
        </Snackbar>

    );
};

export default withStyles(snackBarStyles)(MessageSnackBar);
