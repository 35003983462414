import { fromJS } from "immutable";
import MAP_STYLE from "./map-style.json";
import {
  MAP_LEGEND_COLORS,
  TRAJECTORY_LEGEND_COLORS,
  MAP_LEGEND_OUTLIER_COLOR,
  MAP_LEGEND_INCOMPLETE_DATA_COLOR,
} from "./settings.js";
import {
  CORONAVIRUS_MAP_LEGEND_COLORS,
  AIR_QUALITY_MAP_LEGEND_COLORS,
} from "./settings";

// For more information on data-driven styles, see https://www.mapbox.com/help/gl-dds-ref/
export const dataLayer = fromJS({
  id: "partition-data",
  source: "partition",
  type: "fill",
  interactive: true,
  paint: {
    "fill-color": {
      property: "percentile",
      stops: MAP_LEGEND_COLORS,
    },

    "fill-opacity": 0.8,
    "fill-outline-color": "white",
  },
  // filter:["==", "datalayer", true]
});

export const trajectoryLayer = fromJS({
  id: "trajectory-data",
  source: "partition",
  type: "fill",
  interactive: true,
  paint: {
    "fill-color": {
      property: "difference",
      stops: TRAJECTORY_LEGEND_COLORS,
    },
    "fill-opacity": [
      "interpolate",
      ["exponential", 0.5],
      ["zoom"],
      10,
      1,
      14,
      0.6,
      18,
      0.2,
    ],
    "fill-outline-color": "#F3CCA6",
  },
});

export const coronavirusDataLayer = fromJS({
  id: "partition-data",
  source: "partition",
  type: "fill",
  interactive: true,
  paint: {
    "fill-color": {
      property: "percentile",
      stops: CORONAVIRUS_MAP_LEGEND_COLORS,
    },

    "fill-opacity": 0.8,
    "fill-outline-color": "white",
  },
  filter: ["==", "outlier", false],
});

export const coronavirusOutlierLayer = fromJS({
  id: "outlier-data",
  source: "partition",
  type: "fill",
  interactive: true,
  paint: {
    "fill-opacity": 0,
  },
  filter: ["==", "outlier", true],
});

export const airQualityDataLayer = fromJS({
  id: "partition-data",
  source: "partition",
  type: "fill",
  interactive: true,
  paint: {
    "fill-color": {
      property: "aqi_stops",
      stops: AIR_QUALITY_MAP_LEGEND_COLORS,
    },

    "fill-opacity": 0.8,
    "fill-outline-color": "white",
  },
  filter: ["==", "outlier", false],
});

export const airQualityOutlierLayer = fromJS({
  id: "outlier-data",
  source: "partition",
  type: "fill",
  interactive: true,
  paint: {
    "fill-opacity": 0,
  },
  filter: ["==", "outlier", true],
});

export const highlightLayer = fromJS({
  id: "highlight-data",
  source: "partition",
  type: "line",
  interactive: true,
  paint: {
    "line-color": "#FFFFFF",
    "line-width": 3,
  },
  filter: ["==", "zipcode", 0],
});

export const outlierLayer = fromJS({
  id: "outlier-data",
  source: "partition",
  type: "fill",
  interactive: true,
  paint: {
    "fill-color": MAP_LEGEND_OUTLIER_COLOR,
    "fill-opacity": 0.6,
    "fill-outline-color": MAP_LEGEND_OUTLIER_COLOR,
  },
  filter: ["==", "outlier", true],
});

export const incompleteDataLayer = fromJS({
  id: "incomplete-data",
  source: "partition",
  type: "fill",
  interactive: true,
  paint: {
    "fill-color": MAP_LEGEND_INCOMPLETE_DATA_COLOR,
    "fill-opacity": 0.8,
    "fill-outline-color": MAP_LEGEND_INCOMPLETE_DATA_COLOR,
  },
  filter: ["==", "complete", false],
});

export const tractsLayer = fromJS({
  id: "tracts-data",
  source: "tracts",
  type: "fill",
  interactive: true,
  paint: {
    "fill-color": {
      property: "percentile",
      stops: MAP_LEGEND_COLORS,
    },
    "fill-opacity": [
      "interpolate",
      ["exponential", 0.5],
      ["zoom"],
      11.999999,
      0,
      14,
      0.5,
    ],
    "fill-outline-color": "#F3CCA6",
  },
});

export const trajectoryTractsLayer = fromJS({
  id: "tracts-trajectory-data",
  source: "tracts",
  type: "fill",
  interactive: true,
  paint: {
    "fill-color": {
      property: "difference",
      stops: TRAJECTORY_LEGEND_COLORS,
    },
    "fill-opacity": [
      "interpolate",
      ["exponential", 0.5],
      ["zoom"],
      11.999999,
      0,
      12,
      0.8,
    ],
    "fill-outline-color": "#F3CCA6",
  },
});

export const highlightTractsLayer = fromJS({
  id: "tracts-highlight-data",
  source: "tracts",
  type: "line",
  interactive: true,
  paint: {
    "line-color": "#FFFFFF",
    "line-width": 5,
    "line-opacity": [
      "interpolate",
      ["exponential", 0.5],
      ["zoom"],
      11.999999,
      0,
      12,
      1,
    ],
  },
  filter: ["==", "label", 0],
});

export const outlierTractsLayer = fromJS({
  id: "tracts-outlier-data",
  source: "tracts",
  type: "fill",
  interactive: true,
  paint: {
    "fill-color": MAP_LEGEND_OUTLIER_COLOR,
    "fill-opacity": [
      "interpolate",
      ["exponential", 0.5],
      ["zoom"],
      11.999999,
      0,
      12,
      0.7,
    ],
    "fill-outline-color": MAP_LEGEND_OUTLIER_COLOR,
  },
  filter: ["==", "outlier", true],
});

export const incompleteTractsDataLayer = fromJS({
  id: "tracts-incomplete-data",
  source: "tracts",
  type: "fill",
  interactive: true,
  paint: {
    "fill-color": MAP_LEGEND_INCOMPLETE_DATA_COLOR,
    "fill-opacity": [
      "interpolate",
      ["exponential", 0.5],
      ["zoom"],
      11.999999,
      0,
      12,
      0.8,
    ],
    "fill-outline-color": MAP_LEGEND_INCOMPLETE_DATA_COLOR,
  },
  filter: ["==", "complete", false],
});

export const defaultMapStyle = fromJS(MAP_STYLE);
