export const CORONAVIRUS_MAP_TITLE = "Crosstown - Coronavirus LIVE Map";
export const TRAFFIC_MAP_TITLE = "Crosstown - CoronaTraffic Map";
export const SAFETY_MAP_TITLE = "Crosstown - Safety Map";
export const AIR_QUALITY_MAP_TITLE = "Crosstown - Air Quality Map";
export const CRIME_TRACKER_TITLE = "Crosstown - Crime Tracker";
export const NEIGHBORHOOD_TRACKER_TITLE = "Crosstown - Neighborhood Tracker";
export const AQI_TRACKER_TITLE = "Crosstown - Air Quality Tracker";
export const BUNDLING_TITLE = "Crosstown - Crime Description Bundling";
export const BUNDLES_TITLE = "Crosstown - Bundle Tool";
export const CROSSTOWN_TITLE = "Crosstown LA";

export const INCOMPLETE_CRIME_DATA_TEXT =
  "We do not have all the crime data for this area. We thus decided not to give it orange shading but rather shade it grey. Once we add the missing data, we will add it to our color scale.";
export const OUTLIER_CRIME_DATA_TEXT =
  "When developing the color scale for this map, we identified this area as an outlier, given its extraordinarily high crimes/population ratio. We thus decided not to give it orange shading but rather shade it green to indicate that it is a special case.";

export const AQI_TRACKER_DESCRIPTION_LONG =
  "The Air Quality Tracker is a one-of-a-kind tool that provides a prediction of air quality levels for any point in Los Angeles county at any time of day. By plugging in an address, users can see what the levels of PM2.5 concentrations are at that precise moment. It also offers a historical trend so users can see how the levels of PM2.5 change over the course of a day. The Air Quality Tracker relies on data produced by a machine-learning model developed at USC’s Spatial Sciences Institute. Having ready access to this information can be a crucial tool for asthma sufferers and others who must contend with respiratory illness.";
export const CRIME_TRACKER_DESCRIPTION_LONG =
  "The Crime Tracker employs clear data visualizations to allow users to understand how crime is changing across the City of Los Angeles. It ranks which crimes are the most common and shows have they have changed over time. It allows the user to explore different crimes or groups of crimes in order to better understand the evolving public safety picture in Los Angeles.";
export const NEIGHBORHOOD_TRACKER_DESCRIPTION_LONG =
  "The Neighborhood Tracker, accessible from the Safety Map, offers an analysis and breakdown of crime trends by neighborhood for more than 100 areas across Los Angeles. It ranks each neighborhood by crime rate. It also displays which crimes are the most common in that neighborhood and which ones have increased or decreased over a particular time period. It allows the user to change the time frame to understand how the public safety situation in that neighborhood may have evolved. It also links to our Crime Tracker feature, so users can understand which crimes are the most common in the city.";
export const CORONAVIRUS_MAP_DESCRIPTION_LONG =
  "The Coronavirus map provides regular updates on where confirmed cases of COVID-19 are in Los Angeles County. It breaks those cases down by city and by neighborhood, and shows the density of case clusters by including population data. The map is a resource that helps the public understand which communities are hardest hit by the virus by calculating the rate of infection, not just the total number of cases.";
export const SAFETY_MAP_DESCRIPTION_LONG =
  "The safety map measures crime rate for each of the more than 100 neighborhoods in Los Angeles. The color codes on the map illustrate show darker hues for neighborhoods with higher crime rates. Another view shows which neighborhoods are showing improvements in their crime rate. Users can view the map bounded by neighborhood, zip code or even census tract. Users can also change the time frame for the data.";

export const AQI_TRACKER_DESCRIPTION =
  "The Air Quality Tracker is a unique tool that provides a prediction of air quality levels for any point in Los Angeles county at any time of the day";
export const AIR_QUALITY_MAP_DESCRIPTION =
  "The Air Quality map is a provides regular updates on the air quality of Los Angeles County";
export const CRIME_TRACKER_DESCRIPTION =
  "The Crime Tracker employs clear data visualizations to allow users to understand how crime is changing across the City of Los Angeles.";
export const NEIGHBORHOOD_TRACKER_DESCRIPTION =
  "The Neighborhood Tracker offers an analysis and breakdown of crime trends by neighborhood for more than 100 areas across Los Angeles.";
export const CORONAVIRUS_MAP_DESCRIPTION =
  "The Coronavirus map provides regular updates on where confirmed cases of COVID-19 are in Los Angeles County.";
export const SAFETY_MAP_DESCRIPTION =
  "The safety map measures crime rate for each of the more than 100 neighborhoods in Los Angeles.";
export const TRAFFIC_MAP_DESCRIPTION =
  "The coronavirus traffic map provides regular updates on traffic patterns in Los Angeles.";

export const CRIME_TRACKER_KEYWORDS =
  "crimes, crime rate, safety, crime tracking, los angeles county, crosstown, los angeles";
export const CORONAVIRUS_MAP_KEYWORDS =
  "coronavirus, infection rate, los angeles county, crosstown";
export const NEIGHBORHOOD_TRACKER_KEYWORDS =
  "neighborhood, crime, trends, los angeles county, crosstown";
export const SAFETY_MAP_KEYWORDS =
  "crime, crime rate, safety, los angeles county, crosstown";
export const AQI_TRACKER_KEYWORDS =
  "aqi, air quality, air, health, los angeles county, crosstown";
export const TRAFFIC_MAP_KEYWORDS =
  "traffic, congestion, freeway speeds, los angeles county, crosstown";

export const CRIME_TRACKER_URL = "https://products.xtown.la/crime";
export const NEIGHBORHOOD_TRACKER_URL =
  "https://products.xtown.la/neighborhood";
export const AQI_TRACKER_URL = "https://products.xtown.la/aqi";
export const CORONAVIRUS_MAP_URL = "https://products.xtown.la/coronavirus";
export const SAFETY_MAP_URL = "https://products.xtown.la/map";
export const TRAFFIC_MAP_URL = "https://products.xtown.la/traffic";
export const AIR_QUALITY_MAP_URL = "https://products.xtown.la/aqi-map";

export const MAX_BOUNDS = {
  minLongitude: -119.479071,
  maxLongitude: -117.591883,
  minLatitude: 32.633992,
  maxLatitude: 34.889873,
};

export const MAX_BOUNDS_ARR = [-119.479071, 32.633992, -117.591883, 34.889873];

export let MAP_LEGEND_COLORS = [
  [0, "#00CC1C"],
  [1, "#66CC1C"],
  [2, "#FFFF28"],
  [3, "#FF9916"],
  [4, "#FF0607"],
  [5, "#A62A2A"],
];

export let TRAJECTORY_LEGEND_COLORS = [
  [0, "#00CC1C"],
  [1, "#66CC1C"],
  [2, "#FFFF28"],
  [3, "#FF9916"],
  [4, "#FF0607"],
  [5, "#A62A2A"],
];

export const CORONAVIRUS_MAP_LEGEND_COLORS = [
  [0, "#00CC1C"],
  [1, "#66CC1C"],
  [2, "#FFFF28"],
  [3, "#FF9916"],
  [4, "#FF0607"],
  [5, "#A62A2A"],
];

export const AIR_QUALITY_MAP_LEGEND_COLORS = [
  [0, "#00CC1C"],
  [1, "#FFFF28"],
  [2, "#FF9916"],
  [3, "#FF0607"],
  [4, "#A62A2A"],
];

export let MAP_LEGEND_OUTLIER_COLOR = "rgb(170, 193, 139)";
export let MAP_LEGEND_INCOMPLETE_DATA_COLOR = "#cecece";

export const TO_HOME = "TO_HOME_PAGE";
export const TO_AUTOCOMPLETE = "components.TO_AUTOCOMPLETE";
export const TO_MAP_FEATURE_TOOLTIP = "components.TO_MAP_FEATURE_TOOLTIP";
export const TO_MAPBOX = "components.TO_MAPBOX";
export const TO_RESPONSIVE_DIALOG = "components.TO_RESPONSIVE_DIALOG";
export const TO_NOTIFICATION_MESSAGE = "components.TO_NOTIFICATION_MESSAGE";
export const TO_BUNDLES = "components.TO_BUNDLES";

// Map Filter Topics
export const TOPIC_ALL_URL_CHANGED = "TOPIC_ALL_URL_CHANGED";
export const TOPIC_TIME_PERIOD_FILTER = "TIME_PERIOD_FILTER";
export const TOPIC_TIME_PERIOD_FILTER_USER_CHANGED = [
  TOPIC_TIME_PERIOD_FILTER,
  "USER_CHANGED",
].join(".");
export const TOPIC_TIME_PERIOD_FILTER_URL_CHANGED = [
  TOPIC_TIME_PERIOD_FILTER,
  "URL_CHANGED",
].join(".");

export const TOPIC_PARTITION_TYPE_FILTER = "PARTITION_TYPE_FILTER";
export const TOPIC_PARTITION_TYPE_FILTER_USER_CHANGED = [
  TOPIC_PARTITION_TYPE_FILTER,
  "USER_CHANGED",
].join(".");
export const TOPIC_PARTITION_TYPE_FILTER_URL_CHANGED = [
  TOPIC_PARTITION_TYPE_FILTER,
  "URL_CHANGED",
].join(".");

export const TOPIC_BIG_BUNDLES_FILTER = "BIG_BUNDLES_FILTER";
export const TOPIC_BIG_BUNDLES_FILTER_USER_CHANGED = [
  TOPIC_BIG_BUNDLES_FILTER,
  "USER_CHANGED",
].join(".");
export const TOPIC_BIG_BUNDLES_FILTER_URL_CHANGED = [
  TOPIC_BIG_BUNDLES_FILTER,
  "URL_CHANGED",
].join(".");

export const TOPIC_SMALL_BUNDLES_FILTER = "SMALL_BUNDLES_FILTER";
export const TOPIC_SMALL_BUNDLES_FILTER_USER_CHANGED = [
  TOPIC_SMALL_BUNDLES_FILTER,
  "USER_CHANGED",
].join(".");
export const TOPIC_SMALL_BUNDLES_FILTER_URL_CHANGED = [
  TOPIC_SMALL_BUNDLES_FILTER,
  "URL_CHANGED",
].join(".");

export const TOPIC_MODE_FILTER_USER_CHANGED = "TOPIC_MODE_FILTER_USER_CHANGED";
export const TOPIC_MODE_FILTER_URL_CHANGED = "TOPIC_MODE_FILTER_URL_CHANGED";

export const TOPIC_TRACT_FOR_NEIGHBORHOOD = "TOPIC_TRACT_FOR_NEIGHBORHOOD";
export const TOPIC_TRACT_FOR_NEIGHBORHOOD_SELECTED =
  "TOPIC_TRACT_FOR_NEIGHBORHOOD_SELECTED";

export const ROUTER_PATTERN =
  "/map/place(/mode/:mode)(/zipcode/:zipcode)(/neighborhood/:neighborhood)(/tract/:tract)(/@:lat,:lng)(/time/:time)(/from/:from)(/to/:to)(/bb/:bb)(/sb/:sb)(/)";

// added for aqi
export const SELECTED_PLACE_SUGGESTION = "components.SELECTED_PLACE_SUGGESTION";
