import useDataApi from "../libs/fetch";
import {API_URL} from "../../constants";

/**
 * Constructs the coronavirus cases url
 * @returns {string}
 */
export function getCoronavirusCasesUrl() {
    return `${API_URL}/api/health/coronavirus`;
}

/**
 * Get Coronavirus Cases
 */
function useCoronavirusCases() {
    const url = getCoronavirusCasesUrl;
    const [coronavirusCasesState, ,] = useDataApi(url, "", []);
    return [coronavirusCasesState];
}

export default useCoronavirusCases;