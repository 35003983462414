import React from 'react';
import { withStyles } from "@material-ui/core";
import {
    VictoryChart, 
    VictoryLine,
    VictoryScatter,
    VictoryTooltip,
    VictoryAxis,
    VictoryLabel,
    VictoryGroup
} from 'victory'

const styles = theme => ({
    lineGraph: {
        width: "325px",
        margin: 0,
        position: "relative"
    }
});

const LineGraph = (props) => {
    const { classes, data, xAxisLabel, yAxisLabel, chartTitle, colorScale } = props;
    const interpolation = "natural"
    return (
        <div className={classes.lineGraph}>
            <VictoryChart 
                height={400}
                minDomain={{ y: 0 }}
                domainPadding={{y: 40}}
                padding={{ top: 60, bottom: 80, left: 80, right: 60 }}
            >
                <VictoryLabel
                    text={chartTitle} 
                    x={225} 
                    y={30} 
                    textAnchor="middle"
                    style={{fontSize: 25, padding: 30}}
                />
                <VictoryAxis 
                    label={xAxisLabel} 
                    tickCount={4}
                    style={{
                        axisLabel: {fontSize: 18, padding: 30},
                    }}
                />
                <VictoryAxis 
                    label={yAxisLabel} 
                    dependentAxis
                    axisLabelComponent={<VictoryLabel dy={-20} />}
                    style={{
                        axisLabel: {fontSize: 18, padding: 30},
                        grid: {
                          fill: "none",
                          stroke: "#D3D3D3",
                          strokeDasharray: ["10"],
                          pointerEvents: "painted"
                        },
                    }}
                />
                <VictoryGroup>
                  {data.map((x, idx) => (
                    <VictoryGroup 
                      data={x} 
                      colorScale={colorScale} 
                      key={idx}>
                      <VictoryLine
                        interpolation={interpolation}
                        labelComponent={
                            <VictoryTooltip
                                constrainToVisibleArea
                                cornerRadius={20}
                            />
                        }
                        style={{ data: { stroke: "#000000" } }}
                      />
                      <VictoryScatter
                        size={({ active }) => active ? 10 : 5}
                        labelComponent={
                            <VictoryTooltip
                                constrainToVisibleArea
                                cornerRadius={10}
                                style={{fontSize: 20}}
                            />
                        }
                        events={[{
                            target: "data",
                            eventHandlers: {
                              onMouseOver: () => {
                                return [
                                  {
                                    target: "data",
                                    mutation: () => ({size: 10})
                                  }, {
                                    target: "labels",
                                    mutation: () => ({ active: true })
                                  }
                                ];
                              },
                              onMouseOut: () => {
                                return [
                                  {
                                    target: "data",
                                    mutation: () => ({size: 5})
                                  }, {
                                    target: "labels",
                                    mutation: () => ({ active: false })
                                  }
                                ];
                              }
                            }
                          }]}
                      />
                    </VictoryGroup>
                  ))}
                </VictoryGroup>
            </VictoryChart>
        </div>
    );
}


export default withStyles(styles)(LineGraph);