import React from 'react';

export class Loader extends React.Component{

    render(){
        return(
            <div id="loader">
                {!this.props.noMask ?
                    <div className='loader_mask'/>:null
                }
                <div className="loader"/>
            </div>
        );
    }
}