import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import GridList from '@material-ui/core/GridList';
import Typography from '@material-ui/core/Typography';
import PropTypes from "prop-types";

const styles = theme => ({
    paper: {
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    gridList: {
        flexWrap: 'nowrap',
        transform: 'translateZ(0)',
    },

});

const MapLegend = (props) => {
    const {classes, legendColors, leftLegendText, rightLegendText} = props;

    return (

        <Paper id="map-crime-legend" className={classes.paper} elevation={1}>
            <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                spacing={8}>

                <Grid item xs={4} sm={4}>
                    <Typography variant="caption">
                        {leftLegendText}
                    </Typography>
                </Grid>
                <Grid item xs={4} sm={4}>
                    <GridList cellHeight={10} className={classes.gridList} cols={legendColors.length}>
                        {legendColors.map((color) => <span key={color[0]}
                                                          style={{backgroundColor: color[1]}}></span>)}
                    </GridList>
                </Grid>
                <Grid item xs={4} sm={4}>
                    <Typography variant="caption">
                        {rightLegendText}
                    </Typography>
                </Grid>
            </Grid>
        </Paper>

    );
};

MapLegend.propTypes = {
    classes: PropTypes.object.isRequired,
    legendColors: PropTypes.array.isRequired,
    leftLegendText: PropTypes.string.isRequired,
    rightLegendText: PropTypes.string.isRequired,
};

export default withStyles(styles)(MapLegend);