import React from 'react';
import PropTypes from 'prop-types';
import {FormControl, FormLabel, ListItemText, MenuItem, OutlinedInput, Select} from "@material-ui/core";
import ViewQuiltIcon from '@material-ui/icons/ViewQuilt';
import useMapFilterStyles from "./MapFilter.style";

const PartitionTypeFilter = (props) => {
    const classes = useMapFilterStyles();
    const {partitions, selected, onChange} = props;

    return (
        <FormControl variant="outlined"
                     className={classes.formControl}
                     fullWidth={true}>

            <FormLabel className={classes.formLabel}
                       component="legend"
                       htmlFor="select-partition-type">
                Boundaries
            </FormLabel>

            <Select
                value={selected}
                onChange={onChange}
                IconComponent={ViewQuiltIcon}
                input={
                    <OutlinedInput
                        name="partition-type"
                        labelWidth={0}
                        id="select-partition-type"
                        classes={{input: classes.input}}
                    />
                }>

                {partitions.map(partition => (
                    <MenuItem key={partition.key} value={partition.value}>
                        <ListItemText classes={{primary: classes.listItem}}
                                      primary={partition.key}/>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );

};

PartitionTypeFilter.propTypes = {
    partitions: PropTypes.arrayOf(PropTypes.object).isRequired,
    selected: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
};

export default PartitionTypeFilter;