import { makeStyles } from '@material-ui/core/styles';

const useMapFilterStyles = makeStyles(theme => ({
    formControl: {
        marginBottom: theme.spacing(1),
        minWidth: 170,
        maxWidth: 170,
    },

    formLabel: {
        fontSize: 10
    },

    input: {
        padding: "8px 8px",
        fontSize: 12
    },

    listItem: {
        fontSize: 12
    }
}));

export default useMapFilterStyles;