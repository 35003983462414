import useDataApi from "../libs/fetch";
import {MAX_BOUNDS_ARR} from 'config/settings'

const url = 'https://api.tiles.mapbox.com/geocoding/v5/mapbox.places';

/**
 * Constructs the search place params
 * @returns {{access_token: string, types: string, proximity: string, bbox}}
 */
function getSearchParams() {
    let types = 'address,poi,neighborhood,place,postcode';//'postcode,place,address,poi,neighborhood';
    let proximity = '';
    let bbox = MAX_BOUNDS_ARR;
    return {
        access_token: process.env.REACT_APP_MAP_BOX_ACCESS_TOKEN,
        proximity: proximity,
        bbox: bbox,
        types: types
    }
}

/**
 * Constructs the search place url
 * @param query The search query.
 * @returns {string}
 */
export function getSearchUrl(query) {
    return `${url}/${encodeURIComponent(query)}.json?`;
}

/**
 * Search for places
 *
 * @see https://docs.mapbox.com/api/search/#forward-geocoding
 *
 * @param query The search query.
 */
function useSearchPlace(query) {
    const params = getSearchParams();
    const searchUrl = getSearchUrl(query);
    const [places, setUrl, setQuery] = useDataApi(searchUrl, params, [], false);
    return [places, setUrl, setQuery];
}

export default useSearchPlace;