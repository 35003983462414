import {
    useState,
    useEffect,
    useReducer,
} from 'react';
import axios from 'axios';
import querystring from 'querystring';
import Log from "../../../utils/Log";

/**
 * Reducer Hook for data fetch requests. Combines the states for data, isLoading and isError
 * @param state
 * @param action
 * @returns {{isLoading: boolean, isError: boolean, data: *}|{isLoading: boolean, isError: boolean}}
 */
const dataFetchReducer = (state, action) => {
    switch (action.type) {
        case 'FETCH_INIT':
            return {
                ...state,
                isLoading: true,
                isError: false
            };
        case 'FETCH_SUCCESS':
            return {
                ...state,
                isLoading: false,
                isError: false,
                data: action.payload,
            };
        case 'FETCH_FAILURE':
            return {
                ...state,
                isLoading: false,
                isError: true,
            };
        default:
            throw new Error();
    }
};

const useDataApi = (initialUrl, initialQuery, initialData, unwrap=true) => {
    const [url, setUrl] = useState(initialUrl);
    const [query, setQuery] = useState(initialQuery);

    const [state, dispatch] = useReducer(dataFetchReducer, {
        isLoading: false,
        isError: false,
        data: initialData,
    });

    useEffect(() => {
        let didCancel = false;

        const fetchData = async () => {
            dispatch({type: 'FETCH_INIT'});
            try {
                let queryStr = (typeof query === 'object') ? querystring.stringify(query) : encodeURIComponent(query);

                let url_query = url + queryStr;

                Log.info("Fetching data: " + JSON.stringify(url_query), useDataApi.name);

                const result = await axios(url_query);

                if (!didCancel) {
                    dispatch({type: 'FETCH_SUCCESS', payload: unwrap ? result.data.result.data: result.data});
                }
            } catch (error) {
                if (!didCancel) {
                    dispatch({type: 'FETCH_FAILURE'});
                }
            }
        };

        fetchData();

        // Clean-up executes when component unmounts to let our data fetching logic know about the state
        // (mounted/unmounted) of the component
        return () => {
            didCancel = true;
        };
    }, [url, query, unwrap]);
    return [state, setUrl, setQuery];
};

export default useDataApi