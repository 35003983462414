import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import NSideBar from '../components/neighborhood/NSideBar'
import PubSub from 'pubsub-js';

import Grid from '@material-ui/core/Grid';
// import {Loader} from "../components/neighborhood/Loader";
import Footer from "../components/tracker/Footer"
import NavBar from "../components/tracker/NavBar";
import TrackerContent from "../components/tracker/TrackerContent"
import TabBar from '../components/tracker/TabBar';
import {UPDATE_AREA} from "../components/constants";
import {Helmet} from "react-helmet";
import {AQI_TRACKER_TITLE, AQI_TRACKER_DESCRIPTION, AQI_TRACKER_URL, AQI_TRACKER_KEYWORDS, CROSSTOWN_TITLE} from "../config/settings";
import Log from "../utils/Log";
import About from "../components/tracker/About";

const styles = theme => ({
    root: {
        width: '100%'
    },
    body: {
        [theme.breakpoints.up('md')]: {
            width: '70%',
        },
        width: '90%',
        margin: 'auto',
        padding: '10px 0'
    },
    footer: {
        backgroundColor: '#3C3C3C',
        color: '#eee'
    },
    mobileGrid: {
        padding: '5px 0'
    }
});

class AQITracker extends Component {
    constructor(props) {
        super(props);

        // realName is the original name selected from input box, without being modified with url format
        this.state = {
            place: this.props.match.params.place ? this.props.match.params.place : 'Los Angeles',
            coordinates: this.props.match.params.coordinates ? this.props.match.params.coordinates : '-118.283221,34.020818',
            realName: this.props.match.params.place ? this.props.match.params.place : 'Los Angeles',
            isMobile: false,
            suggestions: []
        };

        this.rankDataFlag = false;
        this.rankTableDataFlag = false;
        this.rankFlag = false;

        // used to fill the search bar if user made selection
        this.searchBarPrefix = '';
    }


    componentDidMount() {
        PubSub.subscribe(UPDATE_AREA, this.subscribe);
        window.addEventListener('resize', this._resize);
    }

    componentWillUnmount() {
        PubSub.clearAllSubscriptions();
    }

    subscribe = (msg, data) => {
        switch(msg){
            case UPDATE_AREA:
                this.setState(prev => ({
                    ...prev,
                    areaName: data,
                    realName: data
                }));
                break;
            default:
                Log.warn(msg);
        }
    };

    render() {
        const {classes} = this.props;
        return (
            <div>
                <Helmet>
                    <title>{AQI_TRACKER_TITLE}</title>
                    <meta name="description"
                        content={AQI_TRACKER_DESCRIPTION}/>
                    <meta name="keywords" content={AQI_TRACKER_KEYWORDS}/>
                    <meta property="og:title" content={AQI_TRACKER_TITLE} />
                    <meta property="og:site_name" content={CROSSTOWN_TITLE} />
                    <meta property="og:description" content={AQI_TRACKER_DESCRIPTION} />
                    <meta property="og:type" content="website" />
                    <meta property="og:url" content={AQI_TRACKER_URL} />
                </Helmet>
                <div>
                    <div className={classes.body}>
                        {/*{this.state.monthlyAreaData.length === 0 && <Loader/>}*/}
                        <Grid container spacing={this.state.isMobile ? 0 : 24}>
                            <Grid item xs={12} className={classes.mobileGrid}>
                                <NavBar mode={'aqi'}/>
                                <TabBar mode={'aqi'}/>
                            </Grid>
                            <Grid item xs={12} md={4} className={classes.mobileGrid}>
                                <NSideBar searchfor="Location" areaName={this.state.realName}
                                          coordinates={this.state.coordinates}/>
                            </Grid>

                            <Grid item xs={12} md={8} className={classes.mobileGrid}>
                                <TrackerContent mode="aqi"/>
                            </Grid>
                        </Grid>
                        <div className={classes.aboutOurdata}><About tracker={"Air Quality"}/></div>
                    </div>
                    <div className={classes.footer}>
                        <Footer/>
                    </div>
                </div>
            </div>
        )
    }

}

AQITracker.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles, {withTheme: true})(AQITracker);