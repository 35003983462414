export function updateAirQualityData(data, result, id = "id") {
  const { features } = data;

  let stats = {
    "PM2.5": "N/A",
  };

  // Initialize all features (some may not appear in result)
  features.forEach((f) => {
    f.properties.caption = "No data reported";
    f.properties.outlier = true;
    f.properties.stats = Object.assign({}, stats);
  });

  // Add total cases counts
  result.forEach((r) => {
    let f = features.find((f) => f.properties[id] === r[id]);
    if (f) {
      const aqi = Math.round(r.avg_aqi);
      const pmi = Math.round(r.avg_pm25);
      f.properties.aqi = aqi;
      f.properties.aqi_stops =
        aqi % 50 === 0 ? Math.floor(aqi / 50) - 1 : Math.floor(aqi / 50);
      f.properties.pmi = pmi;
      f.properties.ranking = r.ranking;
      f.properties.date = r.date;
      f.properties.caption = `Current PM2.5 prediction: AQI of ${aqi}`;
      f.properties.outlier = false;
      f.properties.stats = {
        AQI: aqi,
        "PM2.5": pmi,
      };
    }
  });
}
