import React from 'react';
import Grid from '@material-ui/core/Grid';
import {withStyles} from "@material-ui/core/styles/index";
import Typography from '@material-ui/core/Typography';
import Icon from '@mdi/react';
import { mdiFacebook, mdiTwitter } from '@mdi/js';
import {FACEBOOK_URL, THEME_COLOR, TWITTER_URL, MONTHNAMES} from "../constants";


const styles = theme => ({
    titleContainer: {
        borderBottomWidth: '0.5px',
        borderBottomColor: '#d1d5da',
        borderBottomStyle: 'solid',
    },
    titleRoot:{
        fontFamily: 'Rambla',
        fontSize: '22px',
        color: '#383838',
        letterSpacing: '1.1px',
        fontWeight: '700'
    },
    facebookIcon:{
        display: 'inline-block',
        position: 'relative',
        top: '6px',
        left: '6.2px'
    },
    twitterIcon:{
        display: 'inline-block',
        position: 'relative',
        top: '6.7px',
        left: '7px'
    },

    iconContainer:{
        borderRadius: '50%',
        width: '30px',
        height: '30px',
        backgroundColor: THEME_COLOR,
        margin: '10px 5px',
        display: 'inline-block'
    }

});

const SubTitle = (props) => {

    function generateTitle(mode){
        let title;
        if(mode === 'aqi'){
            title = 'Air Quality Tracker'
        }
        else if(mode === 'neighborhood' || mode === 'crime'){
            title = mode.charAt(0).toUpperCase() + mode.slice(1) + " Tracker:"
        }
        return title
    }

    const {mode, classes} = props;
    const titleName = generateTitle(mode);
    const date = MONTHNAMES[new Date().getMonth()] + ' ' + new Date().getFullYear();

    return(
        <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            className={classes.titleContainer}
        >
            <Typography classes={{root: classes.titleRoot}} >
                {titleName + " " + date}
            </Typography>
            <div>
                <div className={classes.iconContainer}>
                    <a className={classes.facebookIcon} href={FACEBOOK_URL}>
                        <Icon path={mdiFacebook}
                              size={0.7}
                              color={'#fff'}
                              className={classes.socialIcon}
                        />
                    </a>
                </div>
                <div className={classes.iconContainer}>
                    <a className={classes.twitterIcon} href={TWITTER_URL}>
                        <Icon path={mdiTwitter}
                              size={0.7}
                              color={'#fff'}
                              className={classes.socialIcon}
                        />
                    </a>
                </div>
            </div>
        </Grid>
    );
};


export default withStyles(styles, {withTheme: true})(SubTitle)