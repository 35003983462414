import React, { useCallback, useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import useSearchPlace, {
  getSearchUrl,
} from "../api-hooks/hooks/useSearchPlace.js";
import { withStyles } from "@material-ui/core/styles";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import { debounce } from "lodash";

const styles = (theme) => ({
  option: {
    flexGrow: 1,
    marginLeft: 0,
    width: "100%",
    display: "block",
    border: "black solid 1px",
  },
  listbox: {
    backgroundColor: theme.palette.common.white,
    position: "absolute",
    zIndex: 10000,
    top: 0,
    left: 0,
    width: "18rem",
    fontSize: "12px",
    margin: 0,
    padding: 0,
  },
});

const SearchAddress = (props) => {
  const { onSearchAddressChanged, classes } = props;
  const [open, setOpen] = useState(false);
  const [options, setOptions] = React.useState([]);
  const [searchQueryState, setSearchQuery] = React.useState("");
  const [searchPlacesState, setSearchPlacesUrl] = useSearchPlace(
    searchQueryState
  );
  const sendUrl = useCallback(
    debounce((q) => setSearchPlacesUrl(q), 500),
    []
  );

  const loading = open && options.length === 0;

  useEffect(() => {
    if (searchQueryState && searchQueryState.length > 0) {
      var url = getSearchUrl(searchQueryState);
      sendUrl(url);
    }
  }, [searchQueryState, sendUrl]);

  useEffect(() => {
    var features = searchPlacesState.data.features;
    if (features && features.length > 0) {
      setOptions(features);
    }
  }, [searchPlacesState]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Autocomplete
      id="autocomplete"
      classes={{
        listbox: classes.listbox,
        option: classes.option,
      }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onInputChange={(event) => setSearchQuery(event.target.value)}
      onChange={(event, value, reason) => onSearchAddressChanged(value)}
      getOptionLabel={(option) => option.place_name}
      autoComplete
      options={options}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Search..."
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
      renderOption={(option, { inputValue }) => {
        const matches = match(option.place_name, inputValue);
        const parts = parse(option.place_name, matches);

        return (
          <div>
            {parts.map((part, index) => (
              <span
                key={index}
                style={{ fontWeight: part.highlight ? 700 : 400 }}
              >
                {part.text}
              </span>
            ))}
          </div>
        );
      }}
    />
  );
};

export default withStyles(styles)(SearchAddress);
