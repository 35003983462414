import useDataApi from "../libs/fetch";
import {API_URL} from "../../constants";

/**
 * Constructs the neighborhood trend url
 * @returns {string}
 */
export function getNeighborhoodTrendUrl(propertyName, property) {
    return `${API_URL}/api/${propertyName}s/${property}/monthly`;
    
}

/**
 * Get Neighborhood Trend 
 */
function useNeighborhoodTrend(propertyName, property) {
    const url = getNeighborhoodTrendUrl(propertyName, property);
    const [neighborhoodTrendState, setNeighborhoodTrendUrl, ] = useDataApi(url, "", []);
    return [neighborhoodTrendState, setNeighborhoodTrendUrl];
}

export default useNeighborhoodTrend;