import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles/index";

const styles = () => ({
    inputWrapper:{
        width: '190px',
        backgroundColor: 'rgb(248, 248, 248)',
        borderRadius: '10px',
    },
    inputStyle: {
        width: '100%',
        height: '30px',
        border: '1px solid',
        borderRadius: '10px',
        borderColor: 'rgb(234, 234, 234)',
        backgroundColor: 'rgb(248, 248, 248)',
        color: '#9a9a9a',
        fontSize: '14px',
        fontWeight: '400',
        fontFamily: 'helvetica',
        letterSpacing: '0.9px',
        WebkitTransition: 'rgba(0, 123, 255, 0.25) .15s ease-in-out,box-shadow .15s ease-in-out',
        transition: 'rgba(0, 123, 255, 0.25) .15s ease-in-out,box-shadow .15s ease-in-out',
        textIndent: '15px',
        outline: 'none',
    }
});

const defaultValue = 'Enter your email';

class InputBar extends Component{


    constructor(props){
        super(props);
        this.state = {
            inputValue: defaultValue
        }
    }

    changeHandler = (e) =>{
        this.setState({
            inputValue: e.target.value
        })
    };

    focusHandler = (e) =>{
        if(e.target.value === defaultValue){
            this.setState({
                inputValue: ''
            })
        }
    };

    blurHandler = (e) =>{
        if(e.target.value === ''){
            this.setState({
                inputValue: defaultValue
            })
        }
        this.props.updateHandler(e.target.value);
    };

    render() {
        const {classes, width, fixedWidth} = this.props;
        const md = Math.min(window.innerWidth, window.outerWidth) > 600;

        let customStyles = {
            width: fixedWidth?width:md?'80%':(width === undefined) ? '190px' : this.props.width,
            display: (this.props.display === undefined) ? 'block' : this.props.display
        };

        return(
            <div className={classes.inputWrapper}
                 style={customStyles}
            >
                <input type={'email'} className={classes.inputStyle}
                       value={this.state.inputValue}
                       onChange={this.changeHandler}
                       onFocus={this.focusHandler}
                       onBlur={this.blurHandler}
                />
            </div>
        )
    }
}

export default withStyles(styles)(InputBar)