import React from "react";
import PropTypes from "prop-types";
import {FormControl, FormLabel, Hidden, ListItemText, MenuItem, OutlinedInput, Select} from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import useMapFilterStyles from "./MapFilter.style";

const ModeFilter = (props) => {
    const classes = useMapFilterStyles();
    const {modes, selected, onChange} = props;

    return (
        <FormControl variant="outlined"
                     className={classes.formControl}
                     fullWidth={true}>

            <Hidden smDown implementation="css">
                <FormLabel
                    className={classes.formLabel}
                    component="legend"
                    htmlFor="select-mode">
                    Mode
                </FormLabel>
            </Hidden>

            <Select
                value={selected}
                onChange={onChange}
                IconComponent={SettingsIcon}
                input={
                    <OutlinedInput
                        name="Mode"
                        labelWidth={0}
                        id="select-mode"
                        classes={{input: classes.input}}
                    />
                }

            >
                {/* Modes */}
                {modes.map(mode => (
                    <MenuItem key={mode.key} value={mode.value}>
                        <ListItemText classes={{primary: classes.listItem}}
                                      primary={mode.key}/>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
};

ModeFilter.propTypes = {
    modes: PropTypes.arrayOf(PropTypes.object).isRequired,
    selected: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
};

export default ModeFilter;