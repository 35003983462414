import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import {withStyles} from "@material-ui/core/styles/index";
import PubSub from "pubsub-js";
import HighchartsReact from 'highcharts-react-official'
import Typography from '@material-ui/core/Typography';
import Highcharts from "highcharts";
import {SELECTED_PLACE_SUGGESTION} from "../../config/settings";
import Log from "../../utils/Log";
import {API_URL} from "../constants";

const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

const styles = theme => ({
    container:{
        margin: '20px 0px',
        [theme.breakpoints.up('md')]:{
            margin: '2rem 0rem',
        }
    },
    subTitle:{
        fontFamily: 'Rambla',
        fontSize: '16px',
        fontWeight: '700',
        color: '#383838',
        letterSpacing: '1.2px',
        [theme.breakpoints.up('md')]:{
            display: 'none',
        }
    }
});

class AQIRankChart extends Component{

    constructor(props){
        super(props);
        this.state = {
            hasData: false,
            chartXData: [],
            chartYData: []
        }
    }

    componentDidMount(){
        PubSub.subscribe(SELECTED_PLACE_SUGGESTION, this.subscribe);
        // this.subscribeToken = PubSub.subscribe(CRIME_CHART_FINISHED, this.subscribe);
    }

    componentWillUnmount(){
        PubSub.clearAllSubscriptions();
        // PubSub.unsubscribe(this.subscribeToken);
    }

    _fetchAQIHistoryData(){
        const {center, default_place} = this.state;

        const lat = center[1];
        const long = center[0];
        let date = new Date();
        // date.setFullYear( date.getFullYear() - 1 );
        date = date.toISOString().split(".")[0];
        let datetime = date.split("T")[0] + " " + date.split("T")[1];
        let sensors = default_place ? 5000 : 4; // total number of sensors available in the database
        let url = API_URL + '/api/air-quality/aqi-history?lat=' + lat +'&long=' + long + '&datetime=' + datetime + '&sensors=' + sensors;
        fetch(url)
            .then(response => response.json())
            .then(json => {
                let tmp = [];
                let xAxis = [];
                let yAxis = [];
                let trends = json.result.data;
                let datetime, aqi;

                if(trends.length < 1){
                    this.setState({
                        hasData: false
                    });
                }

                trends.forEach(function (ele, idx) {
                    datetime = new Date(ele.datetime);
                    aqi = parseFloat(ele.aqi);
                    tmp.push({
                        x: datetime,
                        y: aqi,
                    });

                    xAxis.push(monthNames[datetime.getMonth()]+ "." + datetime.getDate() + "<br>" + datetime.toLocaleString('en-US', { hour: 'numeric', hour12: true }));
                    yAxis.push(aqi);
                });
                this.setState({
                    highchartData: tmp,
                    chartXData: xAxis,
                    chartYData: yAxis
                });
            });
    }

    subscribe = (msg, data)=>{
        switch(msg){
            case SELECTED_PLACE_SUGGESTION:
                if(data.label === undefined){
                    this.setState({
                        hasData: false
                    })
                }
                else{
                    this.setState({
                        hasData: true,
                        center: data.center,
                        default_place: data.default_place
                    }, function(){
                        this._fetchAQIHistoryData();
                    })
                }
                break;
            default:
                Log.warn(msg);
        }
    };


    render(){

        const {classes} = this.props;
        const chartOptions = {
            chart: {
                type: 'line',
                scrollablePlotArea: {
                    minWidth: 200,
                    scrollPositionX: 0
                }
            },
            title: {
                text: ''
            },
            xAxis: {
                categories: this.state.chartXData,
                labels: {
                    autoRotation: [-15]
                },
                scrollbar: {
                    enabled: true
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: ''
                }
            },
            plotOptions: {
                column: {
                    pointPadding: 0.1,
                    groupPadding: 0.1
                }
            },
            series: [{
                type: 'line',
                name: 'Air Quality Index',
                data: this.state.chartYData,
                // colorByPoint: true,
                color: '#FF9700'
            }],
            showInLegend: true
        };

        const title2 = 'Last 48 hours of Air Quality Index';
        return(
            <Grid container spacing={0} className={classes.container} alignItems={'flex-start'}>
                <Grid item xs={12}>
                    <Typography variant={'subtitle2'} align={'left'} className={classes.subTitle}>
                        {title2}
                    </Typography>
                </Grid>

                <Grid item xs={12} align={'left'}>
                    {this.state.hasData ?
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={chartOptions}
                        /> : "No historic data available" // for the selected location
                    }
                </Grid>
            </Grid>
        );
    }
}


export default withStyles(styles, {withTheme: true})(AQIRankChart)