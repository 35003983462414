import {scaleQuantile} from "d3-scale";
import {range} from "d3-array";

import {TRAJECTORY_LEGEND_COLORS} from '../config/settings'

export function updateDifference(data, result, id) {
    if (data === null) return;
    if (!('features' in data)) return;
    const {features} = data;
    let max_change = Number.NEGATIVE_INFINITY, min_change = Number.POSITIVE_INFINITY;

    // Reset feature crime counts
    features.forEach(f => {
        f.properties.complete = false;
        f.properties.compared_total_crime_count = 0;
        f.properties.compared_total_crime_rate = 0;
        f.properties.change = 0;
        f.properties.outlier = false;
    });

    result = result.filter(r => r !== null);
    // Add crime counts
    result.forEach(r => {

        let f = features.find(f => f.properties[id] === r[id]);
        if (f) {
            f.properties.population = parseInt(r.population, 10);
            f.properties.complete = r.complete;
            f.properties.compared_total_crime_count = parseInt(r.compared_total_crime_count, 10);
            f.properties.compared_total_crime_rate = parseFloat(r.compared_total_crime_rate);
            f.properties.change = parseFloat(r.change);

            min_change = Math.min(min_change, f.properties.change);
            max_change = Math.max(max_change, f.properties.change);
        }
    });


    let domain = [];
    features.forEach(f => {
        domain.push(parseFloat(f.properties.change));
    });

    const scale = scaleQuantile().domain(domain).range(range(TRAJECTORY_LEGEND_COLORS.length));

    // Add percentile property
    features.forEach(f => {
        if (f.properties.complete) {
            if (f.properties.change) {
                f.properties.difference = scale(f.properties.change);

            } else {
                f.properties.difference = 0;
            }
        }
    });


}