import PropTypes from "prop-types";
import {Popup} from "react-map-gl";
import Typography from "@material-ui/core/Typography";
import React from "react";


const MapHoverPopup = (props) => {
    const {hoveredData, partitionType} = props;
    const {hoveredFeature, lat, lng} = hoveredData;

    // Nothing is hovered
    if (!hoveredData.hoveredFeature){
        return "";
    }

    /**
     * TODO improve
     * Returns the label name
     * @param partitionType
     * @param hoveredFeature
     * @returns {string}
     */
    function getLabelName(partitionType, hoveredFeature) {
        if (hoveredFeature.tract) {
            return "Tract: ";
        } else if (partitionType === "neighborhoods") {
            return "Neighborhood: ";
        } else {
            return "Zipcode: ";
        }
    }

    /**
     * TODO improve
     * Returns an explanation for the selected feature
     * @param complete
     * @param outlier
     * @returns {string}
     */
    function getExplanation(caption, complete, outlier) {

        if (caption)
            return caption;

        if (!complete && outlier) {
            return "(incomplete data & outlier)"
        } else if (!complete) {
            return "(incomplete data)"
        } else if (outlier) {
            return "(outlier)"
        } else {
            return ""
        }
    }

    const label = hoveredFeature.properties["label"];
    const complete = hoveredFeature.properties["complete"];
    const outlier = hoveredFeature.properties["outlier"];
    const caption = hoveredFeature.properties["caption"];
    const population = hoveredFeature.properties.total_population;
    const infectionRatePer100K = hoveredFeature.properties.infection_rate_per_100K;
    const labelName = getLabelName(partitionType, hoveredFeature);
    const explanation = getExplanation(caption, complete, outlier);

    return (
        <Popup className={"xtown-popup"}
               latitude={lat}
               longitude={lng}
               closeButton={false}
               closeOnClick={true}
               anchor="bottom">

            <Typography variant="body1" display="block" gutterBottom align="left">
                {labelName} {label}
            </Typography>
            <Typography variant="caption" display="block" align="center">
                {explanation}
            </Typography>
            {
                (typeof population !== 'undefined') ? (
                    <Typography variant="caption" display="block" align="center">
                        Population: {population}
                    </Typography>
                ) : ''
            }
           {
               (typeof infectionRatePer100K !== 'undefined')  ? (
                    <Typography variant="caption" display="block" align="center">
                        {infectionRatePer100K.toFixed(2)} per 100K population
                    </Typography>
                ) : ''
            }
        </Popup>
    );

};

MapHoverPopup.propTypes = {
    hoveredData: PropTypes.object.isRequired,
    partitionType: PropTypes.string.isRequired,
};

export default MapHoverPopup;