export let API_URL = process.env.REACT_APP_API_URL;

export const AUTOCOMPLETE_FINISHED = "components.searchBar";

export const MAPSTYLE_FINISHED = "components.areaMap";

export const TO_CRIMERANK = "components.dataView.rank";

export const CRIME_CHART_FINISHED = "components.dataView.rankChart";

export const CRIME_TABLE_FINISHED = "components.dataView.rankTable";

export const CRIME_DATE_FINISHED = "components.dataView.title";

export const CORONAVIRUS_MAP_URL = '/coronavirus';

export const TRAFFIC_MAP_URL = '/traffic';

export const AIR_QUALITY_MAP_URL = '/aqi-map';

export const MAP_URL = '/map';

export const CRIME_TRACKER_URL = '/crime/burglary';

export const NEIGHBORHOOD_URL = '/neighborhood/downtown';

export const AQI_URL = '/aqi';

export const DEFAULT_NEIGHBORHOOD_NAME = 'Downtown';

export const DEFAULT_NEIGHBORHOOD_Id = 'downtown';

export const DEFAULT_CRIME = 'Burglary';

export const TO_NEIGHBORHOODTRACKER = 'component.NeighborhoodTracker';

export const UPDATE_AREA = 'components.searchBar.areaName';

export const STORY_FINISHED = 'components.contnet.story';

export const MENU_TOGGLED = 'components.searchBarIsOpen';

export const THEME_COLOR = '#FF9700';

export const IMAGE_PATH = window.location.protocol + '//' + window.location.host + "/images";

export const TWITTER_URL = 'https://twitter.com/CrosstownLA';

export const FACEBOOK_URL = 'https://www.facebook.com/CrosstownLA/';

export const TO_TITLE = 'components.title';

export const SUBSCRIBE_USER = 'user subscribe';

export const TO_CRIMETRACKER = 'component.CrimeTracker';

export const TO_SUBSCRIPTION = 'component.Subscription';

export const SUB_NEIGHBOR_BAR = 'neighborhood sub bar';

export const UNSUB_NEIGHBOR_NAMES = 'unsubscribed neighborhood names';

export const SUB_SEARCH_BAR_FOCUS = 'focus action on the search bar';

export const TO_CRIMECHART = 'component.crimechart';

export const TO_CRIMETABLE = 'component.crimetable';

export const TO_CRIMESEARCHBAR = 'component.csearchbar';

export const TO_CRIMETITLE = 'component.ctitle';

export const UPDATE_CRIME = 'new crime';

export const UPDATE_TABLE = 'new crime table';

export const TO_CRIMEDATAVIEW = 'component.crimedataview';

export const MONTHNAMES = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];