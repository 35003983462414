import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import Typography from '@material-ui/core/Typography';
import {MAP_LEGEND_INCOMPLETE_DATA_COLOR, MAP_LEGEND_OUTLIER_COLOR} from '../../config/settings'

const styles = theme => ({
    paper: {
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    gridList: {
        flexWrap: 'nowrap',
        transform: 'translateZ(0)',
    },

});

const OutlierIncompleteMapLegend = (props) => {
    const {classes} = props;

    return (
        <Paper id="map-outlier-incomplete-legend" className={classes.paper} elevation={1}>
            <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center">

                <Grid item xs={6} sm={6}>
                    <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="center">

                        <Grid item xs={11} sm={11}>
                            <Typography variant="caption">
                                Incomplete Data
                            </Typography>
                        </Grid>

                        <Grid item xs={1} sm={1}>
                            <GridList cellHeight={10} className={classes.gridList} cols={1}>
                                <GridListTile cols={1}
                                              style={{backgroundColor: MAP_LEGEND_INCOMPLETE_DATA_COLOR}}/>
                            </GridList>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6} sm={6}>
                    <Grid
                        container
                        direction="row"
                        justify="flex-end"
                        alignItems="center">

                        <Grid item xs={11} sm={11}>
                            <Typography variant="caption">
                                Outliers
                            </Typography>
                        </Grid>

                        <Grid item xs={1} sm={1}>
                            <GridList cellHeight={10} className={classes.gridList} cols={1}>
                                <GridListTile cols={1} style={{backgroundColor: MAP_LEGEND_OUTLIER_COLOR}}/>
                            </GridList>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default withStyles(styles)(OutlierIncompleteMapLegend);