import React, {Component} from 'react';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import Grid from '@material-ui/core/Grid';
import arrayMove from 'array-move';

const SortableItem = SortableElement(({value}) => <div key={value}>{value}</div>);

const SortableList = SortableContainer(({items}) => {
    return (
        <Grid container>
            {items.map((value, index) => (
                <Grid key={index} item xs={6}>
                    <SortableItem key={index} index={index} value={value} />
                </Grid>
            ))}
        </Grid>
    );
});

export default class SortableComponent extends Component {

    constructor(props){
        super(props);
        this.subscribedNeighbors = props.subscribedNeighbors;
        this.visualCategories = props.visualCategories;
        this.state = {
            items:[]
        }
    }

    componentWillReceiveProps(props){
        this.subscribedNeighbors = props.subscribedNeighbors;
        this.visualCategories = props.visualCategories;
        let itemList = [];
        // console.log(this.subscribedNeighbors);
        // console.log(this.visualCategories);

        const imgUrlPrefix = "https://data-visualization-image.s3-us-west-1.amazonaws.com/2020_1";
        this.subscribedNeighbors.forEach((neighbor, key)=>{
            itemList.push(
                <table className="table text-center w-100 bg-white"
                       id="subscribedNeighborTable"
                       key={key}
                >
                    <tbody>
                    <tr>
                        <td>
                            <a href={`../neighborhood/${neighbor.id}`}>{neighbor.name}</a>
                        </td>
                    </tr>
                    <tr>
                        {neighbor.visualCategories.map((categoryId, id)=> {
                            return <td key={id}>
                                <span>{this.visualCategories.get(categoryId).category + '  '}</span>
                            </td>
                        })}
                    </tr>
                    <tr>
                        {neighbor.visualCategories.map((categoryId, id)=> {
                            return <td key={id}>
                                <img alt={''}
                                     src={`${imgUrlPrefix}/${neighbor.id}/${neighbor.id}_${categoryId}.jpeg`}
                                     style={{width: '450px', height: '300px'}}
                                />
                            </td>
                        })}
                    </tr>
                    </tbody>
                </table>
            )
        });
        this.setState({
            items: itemList
        })

    }

    // state = {
    //     items: ['Item 1', 'Item 2', 'Item 3', 'Item 4', 'Item 5', 'Item 6'],
    // };
    onSortEnd = ({oldIndex, newIndex}) => {
        this.setState(({items}) => ({
            items: arrayMove(items, oldIndex, newIndex),
        }));
    };
    render() {
        return <SortableList axis={'x'} items={this.state.items} onSortEnd={this.onSortEnd} />;
    }
}

// render(<SortableComponent />, document.getElementById('root'));