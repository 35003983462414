import React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  reportFAQHeading: {
    fontFamily: "Open Sans",
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "35px",
    color: "#4f4f4f",
  },
  reportFAQDescription: {
    height: "240px",
    fontFamily: "Open Sans",
    fontSize: "12px",
    lineHeight: "16px",
    alignItems: "center",
    color: "#444444",
  },
  LearnMoreBtn: {
    marginTop: "55px",
    width: "100px",
    height: "24px",
    color: "#FFFFFF",
  },
  learnMoreBtnText: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "10px",
    color: "#4F4F4F",
  },
}));

const FaqReports = (props) => {
  const classes = useStyles();
  const hrefLACounty =
    "http://www.publichealth.lacounty.gov/media/Coronavirus/locations.htm";
  const hrefCensus2018 =
    "https://www.census.gov/programs-surveys/acs/news/updates/2019.html";
  const hrefLATimes = "http://maps.latimes.com/neighborhoods/";
  const aqifaq = (
    <div>
      <div className={classes.reportFAQHeading}>
        Why should we care about PM 2.5?
      </div>
      <div className={classes.reportFAQDescription}>
        <br />
        PM 2.5 is invisible tiny particulate matter that is one of the most
        harmful parts of air pollution for humans.
        <br />
        <br />
        • The particulates lodge in our lungs, causing harm. We get worse with
        prolonged exposure.
        <br />
        <br />
        • Short-term health impacts: irritation, coughing and shortness of
        breath.
        <br />
        <br />
        • Long-term health impacts: reduced lung function, irregular heartbeats,
        asthma attacks. Prolonged exposure can lead to premature death.
        <br />
        <br />• PM 2.5 is caused by humans: tailpipe and smokestack emissions,
        burning wood, chemicals mixing in the air and baking in the sun.
      </div>
      <Button variant="contained" className={classes.LearnMoreBtn}>
        <div className={classes.learnMoreBtnText}>LEARN MORE</div>
      </Button>
    </div>
  );

  const coronafaq = (
    <div>
      <div className={classes.reportFAQDescription}>
        This map illustrates the most recent count of confirmed COVID-19 cases
        in Los Angeles County. It is based on data that the Los Angeles County
        Department of Public Health began releasing on March 25, 2020. The
        department's data is updated once a day and can be accessed{" "}
        <Link href={hrefLACounty} target="_blank" rel="noopener noreferrer">
          here
        </Link>
        <br />
        <br />• We used population data from the 2018{" "}
        <Link href={hrefCensus2018} target="_blank" rel="noopener noreferrer">
          American Community Survey
        </Link>{" "}
        to calculate rate of infection per 100,000 residents.
        <br />
        <br />• For the city of Los Angeles, Crosstown uses a library of
        neighborhood boundaries developed by the{" "}
        <Link href={hrefLATimes} target="_blank" rel="noopener noreferrer">
          Los Angeles Times
        </Link>
        . In several cases, we modified the neighborhood definitions provided by
        the L.A. County Department of Public Health to conform to our
        boundaries. In several cases, we aggregated smaller areas into larger
        ones to conform to with our boundaries. The revised boundaries may
        result in totals that, in some cases, vary slightly from numbers
        published by the county.
        <br />
        <br />• For questions about our data, write to us at{" "}
        <Link href={"mailto:askus@xtown.la"}>askus@xtown.la</Link>
        <br />
      </div>
    </div>
  );

  const crimefaq = <div></div>;

  return (
    <div>
      {props.reportTitle === "Air Quality Report"
        ? aqifaq
        : props.reportTitle === "Crime Report"
        ? crimefaq
        : coronafaq}
    </div>
  );
};

export default FaqReports;
