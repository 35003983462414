import React from 'react';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";

const styles = theme => ({
    transitionContainer: {
        margin: '20px',
    },
    transitionGroup:{
        color: '#767676',
        '&:hover':{
            color: '#4fc3f7',
        }
    },
    transitionText: {
        fontFamily: 'IBMPlex',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '30px',
        cursor: 'pointer',
        transition: '.25s',
    },
    downArrowStyle: {
        fontSize: '20px',
        cursor: 'pointer',
        transition: '.25s',
    }
});



function STransition(props){
    const {classes} = props;
    const clickHandler = ()=>{
        window.scrollTo({
            top: 950,
            left: 0,
            behavior: 'smooth'
        })};

    return<Grid
        container
        direction="column"
        justify="flex-end"
        alignItems="center"
        classes={{root: classes.transitionContainer}}
    >
        <Grid item className={classes.transitionGroup}>
            <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
            >
                <Grid item>
                    <Typography
                        className={classes.transitionText}
                            onClick={clickHandler}>manage your subscription</Typography>
                </Grid>
                <Grid item>
                    <ArrowDownwardIcon
                        classes={{root: classes.downArrowStyle}}
                        onClick={clickHandler}/>
                </Grid>
            </Grid>
        </Grid>
    </Grid>
}

export default withStyles(styles, {withTheme: true})(STransition);