import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { CORONAVIRUS_MAP_LEGEND_COLORS } from "../../config/settings";
import MapLegend from "./MapLegend";
import PropTypes from "prop-types";

const styles = (theme) => ({
  mapLegend: {
    position: "absolute",
    bottom: 35,
    left: 400,
    [theme.breakpoints.down("sm")]: {
      right: 0,
      bottom: 50,
      left: 0,
    },
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  gridList: {
    flexWrap: "nowrap",
    transform: "translateZ(0)",
  },
});

const CoronavirusMapLegend = (props) => {
  const { classes } = props;

  return (
    <div id="coronavirus-map-legend" className={classes.mapLegend}>
      <Grid
        container
        direction="column"
        justify="space-evenly"
        alignItems="stretch"
      >
        <Grid item xs={12} sm={12} xl={12}>
          <MapLegend
            legendColors={CORONAVIRUS_MAP_LEGEND_COLORS}
            leftLegendText={"Lower Infection Rate"}
            rightLegendText={"Higher Infection Rate"}
          />
        </Grid>
      </Grid>
    </div>
  );
};

CoronavirusMapLegend.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CoronavirusMapLegend);
