import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import OutlierIncompleteMapLegend from "./OutlierIncompleteMapLegend";
import { SAFETY_MAP_MODE_VALUE } from "../MapFilter";
import {
  MAP_LEGEND_COLORS,
  TRAJECTORY_LEGEND_COLORS,
} from "../../config/settings";
import MapLegend from "./MapLegend";
import PropTypes from "prop-types";

const styles = (theme) => ({
  mapLegend: {
    position: "absolute",
    bottom: 62,
    left: 400,
    [theme.breakpoints.down("sm")]: {
      right: 0,
      bottom: 50,
      left: 0,
    },
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  gridList: {
    flexWrap: "nowrap",
    transform: "translateZ(0)",
  },
});

const SafetyMapLegend = (props) => {
  const { selectedMode, classes } = props;

  return (
    <div id="safety-map-legend" className={classes.mapLegend}>
      <Grid
        container
        direction="column"
        justify="space-evenly"
        alignItems="stretch"
      >
        <Grid item xs={12} sm={12} xl={12}>
          <OutlierIncompleteMapLegend />
        </Grid>

        <Grid item xs={12} sm={12} xl={12}>
          <MapLegend
            legendColors={
              selectedMode === SAFETY_MAP_MODE_VALUE
                ? MAP_LEGEND_COLORS
                : TRAJECTORY_LEGEND_COLORS
            }
            leftLegendText={
              selectedMode === SAFETY_MAP_MODE_VALUE
                ? "Lower Crime Rate"
                : "Trending lower"
            }
            rightLegendText={
              selectedMode === SAFETY_MAP_MODE_VALUE
                ? "Higher Crime Rate"
                : "Trending higher"
            }
          />
        </Grid>
      </Grid>
    </div>
  );
};

SafetyMapLegend.propTypes = {
  classes: PropTypes.object.isRequired,
  selectedMode: PropTypes.string.isRequired,
};

export default withStyles(styles)(SafetyMapLegend);
