import {
    dataLayer,
    defaultMapStyle,
    highlightLayer,
    incompleteDataLayer,
    outlierLayer,
    trajectoryLayer,
    tractsLayer, coronavirusDataLayer, coronavirusOutlierLayer,
    airQualityDataLayer, airQualityOutlierLayer
} from "../../../config/map-style";
import {SAFETY_MAP_MODE_VALUE, TRAJECTORY_MODE_VALUE} from "../../MapFilter";
import Log from "../../../utils/Log";
import {fromJS} from "immutable";

const emptySource = {
    'type': 'geojson',
    'data': {
        'type': 'FeatureCollection',
        'features': []
    }
};

export const getAirQualityMapStyle = () => {
    let layers = defaultMapStyle.get('layers').push(airQualityDataLayer, airQualityOutlierLayer, highlightLayer);
    return defaultMapStyle
        .setIn(['sources', 'partition'], fromJS(emptySource))
        .set('layers', layers);
};

export const getCoronavirusMapStyle = () => {
    let layers = defaultMapStyle.get('layers').push(coronavirusDataLayer, coronavirusOutlierLayer, highlightLayer);
    return defaultMapStyle
        .setIn(['sources', 'partition'], fromJS(emptySource))
        .set('layers', layers);
};

const getMapStyleWithLayers = (mode) => {
    let layers;
    switch (mode) {
        case SAFETY_MAP_MODE_VALUE:
            layers = defaultMapStyle.get('layers').push(dataLayer, outlierLayer, incompleteDataLayer, tractsLayer, highlightLayer);
            break;
        case TRAJECTORY_MODE_VALUE:
            layers = defaultMapStyle.get('layers').push(trajectoryLayer, outlierLayer, incompleteDataLayer, tractsLayer, highlightLayer);
            break;
        default:
            Log.warn(`Unrecognized mode ${mode}`, getMapStyleWithLayers.name);
            layers = defaultMapStyle.get('layers').push(dataLayer, outlierLayer, incompleteDataLayer, tractsLayer, highlightLayer);
    }
    return defaultMapStyle
        .setIn(['sources', 'tracts'], fromJS(emptySource))
        .setIn(['sources', 'partition'], fromJS(emptySource))
        .set('layers', layers);
};

export default getMapStyleWithLayers