import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  button: {
    margin: 0,
    textTransform: "inherit",
    padding: 0,
  },
  flex: {
    flex: 1,
  },
  grow: {
    flexGrow: 1,
  },
  logo: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    height: 24,
  },
  appbar: {
    background: "#001E36",
  },
  toolbar: {
    minHeight: 52,
  },
  site_name: {
    color: "#fff",
    fontWeight: 900,
    fontSize: 20,
    lineHeight: "24px",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
});

class Header extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <AppBar
          id="topbar"
          title="Safety Map"
          className={classes.appbar}
          position="static"
        >
          <Toolbar className={[classes.toolbar, "header-toolbar"].join(" ")}>
            <Button href="https://xtown.la" className={classes.button}>
              <img
                alt="crosstown logo"
                src="/images/crosstown-logo.png"
                className={classes.logo}
              />
              <Typography
                variant="h6"
                className={[classes.flex, classes.site_name, "site_name"].join(
                  " "
                )}
              >
                Safety Map
              </Typography>
            </Button>

            <div className={classes.grow} />

            {/* {showAutocomplete ? <Autocomplete/> : ""} */}
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Header);
