import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Fade, LinearProgress, withStyles } from "@material-ui/core";
import { Helmet } from "react-helmet";
import {
  CORONAVIRUS_MAP_TITLE,
  CORONAVIRUS_MAP_DESCRIPTION,
  CORONAVIRUS_MAP_KEYWORDS,
  CROSSTOWN_TITLE,
  CORONAVIRUS_MAP_URL,
} from "../config/settings";
import NavBar from "../components/tracker/NavBar";
import Map from "../components/Map";
import { fromJS } from "immutable";
import _ from "lodash";
import Log from "../utils/Log";
import usePartitionData from "../components/api-hooks/hooks/usePartitionData";
import { getCoronavirusMapStyle } from "../components/api-hooks/hooks/getMapStyleWithLayers";
import useCoronavirusCases from "../components/api-hooks/hooks/useCoronavirusCases";
import { updateCoronavirusPercentiles } from "../utils/UpdateCoronavirusPercentiles";
import ReactGA from "react-ga";
import union from "@turf/union";
import LeftPanel from "components/LeftPanel";
import useCoronavirusTrend, {
  getCoronavirusTrendUrl,
} from "components/api-hooks/hooks/useCoronavirusTrend";
import moment from "moment";
import CoronavirusMapLegend from "components/MapLegend/CoronavirusMapLegend";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  placeholder: {
    position: "absolute",
    zIndex: 1000,
    height: 10,
    width: "100%",
  },
  thisNav: {
    padding: 100,
  },
});

const CoronavirusMapContainer = (props) => {
  const { classes } = props;

  // Map style
  const initialMapStyle = getCoronavirusMapStyle();

  // Map style state
  const [mapStyle, setMapStyle] = useState(initialMapStyle);

  const selectedPartitionTypeFilter = "neighborhoods";
  const layerIds = ["partition-data", "outlier-data"];

  // Partition data state
  const [partitionDataState] = usePartitionData(selectedPartitionTypeFilter);
  useEffect(() => {
    const { isLoading, data } = partitionDataState;

    if (!isLoading && !_.isEmpty(data)) {
      const neighborhood_pairs = [
        ["beverly-grove", "carthay"],
        ["sawtelle", "west-los-angeles"],
        ["ridge-route", "castaic"],
        ["griffith-park", "los-feliz"],
      ];

      neighborhood_pairs.forEach((neighborhood_pair) => {
        let feature_replaced = data.features.find((ele) => {
          return neighborhood_pair[0] === ele.properties.id;
        });
        let feature = data.features.find((ele) => {
          return neighborhood_pair[1] === ele.properties.id;
        });

        let merged = null;
        if (feature_replaced && feature) {
          merged = union(feature_replaced, feature);
          feature.geometry = merged.geometry;
          data.features.splice(
            data.features.findIndex(
              (f) => f.properties.id === neighborhood_pair[0]
            ),
            1
          );
        }
      });

      Log.info(
        "Updating map style with partition source",
        CoronavirusMapContainer.name
      );
      setMapStyle((previousMapStyle) =>
        previousMapStyle.setIn(
          ["sources", "partition"],
          fromJS({
            type: "geojson",
            data: data,
          })
        )
      );
    }
  }, [partitionDataState]);

  const [coronavirusTotalCasesState] = useCoronavirusCases();
  useEffect(() => {
    const { isLoading, data } = coronavirusTotalCasesState;

    if (
      !isLoading &&
      !partitionDataState.isLoading &&
      !_.isEmpty(data) &&
      !_.isEmpty(partitionDataState.data)
    ) {
      setTotalCases(data[0].total_cases.toLocaleString());
      Log.info(
        "Updating map style with coronavirus data",
        CoronavirusMapContainer.name
      );
      updateCoronavirusPercentiles(
        partitionDataState.data,
        data,
        "neighborhood"
      );
      setMapStyle((previousMapStyle) =>
        previousMapStyle.setIn(
          ["sources", "partition"],
          fromJS({
            type: "geojson",
            data: partitionDataState.data,
          })
        )
      );
    }
  }, [coronavirusTotalCasesState, partitionDataState]);

  const [selectedFeature, setSelectedFeature] = useState(null);
  const [searchPlaceState, setSearchPlace] = useState(null);

  // Search place
  useEffect(() => {
    ReactGA.event({
      category: "Coronavirus Map",
      action: "Search place changed",
      label: JSON.stringify(searchPlaceState),
    });
  }, [searchPlaceState]);

  const isLoading =
    partitionDataState.isLoading || coronavirusTotalCasesState.isLoading;

  /* LEFT PANEL DATA */
  const [rank, setRank] = useState(0);
  const [totalCases, setTotalCases] = useState(0);
  const [population, setPopulation] = useState(0);
  const [searchName, setSearchName] = useState("");
  const [infectionRate, setInfectionRate] = useState(0);
  const [featureId, setFeatureId] = useState(0);

  const corona_statistics = [
    {
      key: "Coronavirus Case Ranking",
      value: rank,
    },
    {
      key: "Total Cases",
      value: totalCases,
    },
    {
      key: "Population",
      value: population,
    },
    {
      key: "Infection Rate",
      value: infectionRate,
    },
  ];

  useEffect(() => {
    if (selectedFeature) {
      setRank(selectedFeature.properties.ranking);
      setPopulation(selectedFeature.properties.total_population);
      setInfectionRate(
        Math.round(
          (selectedFeature.properties.infection_rate_per_100K +
            Number.EPSILON) *
            100
        ) /
          100 +
          " per 100K"
      );
      const featureProperties = selectedFeature.properties;
      setFeatureId(featureProperties.id);
      setSearchName(featureProperties.label);
    }
  }, [selectedFeature]);

  /* TREND DATA */

  const [coronavirusTrendState, setCoronavirusTrendUrl] =
    useCoronavirusTrend(featureId);
  const [chartData, setChartData] = useState([]);
  const mapXaxisLabel = "Last 255 days";
  const mapYaxisLabel = "Number of Cases";

  useEffect(() => {
    const { isLoading, data } = coronavirusTrendState;
    let trendData = [];
    if (!isLoading && data != null && data.length > 0) {
      data[0].trends.forEach(function (ele, idx) {
        let x_data = moment(ele.date, ["YYYY-MM-DDTHH:mm:ss.SSSSZ"]).utc();
        // x_data = moment(ele.date_observed, ["YYYY-MM-DDTHH:mm:ss.SSSSZ"]).utc();
        x_data = x_data.format("MMM DD YY");

        let y_data = parseInt(ele.case_count, 10);

        trendData.push({
          x: x_data,
          y: y_data,
        });
      });
    }
    setChartData([trendData]);
  }, [coronavirusTrendState]);

  useEffect(() => {
    const url = getCoronavirusTrendUrl(featureId);
    setCoronavirusTrendUrl(url);
  }, [featureId, setCoronavirusTrendUrl]);

  return (
    <div>
      <Helmet>
        <title>{CORONAVIRUS_MAP_TITLE}</title>
        <meta name="description" content={CORONAVIRUS_MAP_DESCRIPTION} />
        <meta name="keywords" content={CORONAVIRUS_MAP_KEYWORDS} />
        <meta property="og:title" content={CORONAVIRUS_MAP_TITLE} />
        <meta property="og:site_name" content={CROSSTOWN_TITLE} />
        <meta property="og:description" content={CORONAVIRUS_MAP_DESCRIPTION} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={CORONAVIRUS_MAP_URL} />
        <meta property="og:image" content="%PUBLIC_URL%/coronavirus-map.png" />
      </Helmet>

      <div className={classes.root}>
        <NavBar mode={"coronavirus-map"} />

        <div id="progressbar" className={classes.placeholder}>
          <Fade
            in={isLoading}
            style={{
              transitionDelay: isLoading ? "800ms" : "0ms",
            }}
            unmountOnExit
          >
            <LinearProgress color="secondary" />
          </Fade>
        </div>

        {/* <Share /> */}

        <Map
          mapStyle={mapStyle}
          onSelectedFeature={setSelectedFeature}
          interactiveLayerIds={layerIds}
          partitionType={selectedPartitionTypeFilter}
          searchAddress={searchPlaceState}
        />
        <CoronavirusMapLegend />
        <LeftPanel
          reportTitle="Coronavirus Report"
          reportTitleDesc="This map measures the current coronavirus infection cases in the Los Angeles county area."
          onSearchAddressChanged={(value) => setSearchPlace(value)}
          selectedFeature={selectedFeature}
          isLoadingData={isLoading}
          statistics={corona_statistics}
          searchName={searchName}
          chartData={chartData}
          mapXaxisLabel={mapXaxisLabel}
          mapYaxisLabel={mapYaxisLabel}
          showFaq={true}
        ></LeftPanel>
      </div>
    </div>
  );
};

CoronavirusMapContainer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CoronavirusMapContainer);
