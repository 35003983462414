import React, {Component} from 'react';
import Subscription from './Subscription';
import {createMuiTheme, MuiThemeProvider} from "@material-ui/core/styles";
import {withStyles} from "@material-ui/core/styles/index";

let theme = createMuiTheme({
    breakpoints: {
        // Define custom breakpoint values.
        // These will apply to Material-UI components that use responsive
        // breakpoints, such as `Grid` and `Hidden`. You can also use the
        // theme breakpoint functions `up`, `down`, and `between` to create
        // media queries for these breakpoints
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1400
        }
    }
});

const styles = (theme) =>{

};


class SubscriptionWrap extends Component{

    render(){
        return(
            <MuiThemeProvider theme={theme}>
                <Subscription areaName = {this.props.match.params.area}/>
            </MuiThemeProvider>
        );

    }

}

export default withStyles(styles, {withTheme: true})(SubscriptionWrap);