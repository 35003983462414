import React from 'react';
import {withStyles} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import SubTitle from "./SubTitle";
import NeighborMap from "./NeighborMap";
import Typography from "@material-ui/core/Typography";
import CrimeRateRank from "./CrimeRateRank";
import CrimeChart from "./CrimeChart";
import CCrimeTable from "./CCrimeTable";
import NCrimeTable from "./NCrimeTable";
import AQIRanking from "../air-quality/AQIRanking";
import AQIRankChart from "../air-quality/AQIRankChart";
import AQIRankTable from "../air-quality/AQIRankTable";
const styles = theme => ({
    contentContainer:{
        paddingTop: '2px'
    },
    subTitleRoot:{
        fontFamily: 'Rambla',
        fontSize: '16px',
        fontWeight: '700',
        color: '#383838',
        letterSpacing: '1.2px'
    },
    container:{
        margin: '10px 0px',
        [theme.breakpoints.up('md')]:{
            marginTop: '30px'
        }
    }

});

const TrackerContent = (props) => {
    const {classes, mode, mapStyle, crimeRateData, crimeChartData, crimeCTableData,
        crimeNTableData, CTableMode, NTableMode, changeHistoryRangeHandler, changePartitionHandler,
        restoreCrimeType, restoreNeighborURLName, dateTitle} = props;

    function renderContent(mode){
        if(mode === 'neighborhood'){
            return <div>
                <NeighborMap mapFeature={mapStyle}/>
                <Grid
                    container
                    direction="row"
                    justify="space-around"
                    alignItems="center"
                    spacing={2}
                    className={classes.container}
                >
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={4}>
                            <Typography variant={'subtitle2'}
                                        align={'left'}
                                        className={classes.subTitle}
                                        classes={{root: classes.subTitleRoot}}>
                                Crime Rate Rank
                            </Typography>
                        </Grid>
                        <Grid item xs={1}/>
                        <Grid item xs={7}>
                            <Typography variant={'subtitle2'}
                                        align={'left'}
                                        className={classes.subTitle2}
                                        classes={{root: classes.subTitleRoot}}>
                                Last 12 Months of All Crimes
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <CrimeRateRank rateRankData={crimeRateData}/>
                    </Grid>
                    {/* separate column in desktop mode */}
                    <Grid item md={1}/>
                    <Grid item xs={12} md={7}>
                        <CrimeChart chartData={crimeChartData}/>
                    </Grid>

                    <Grid item xs={12}>
                        <NCrimeTable
                            rankTableData={crimeNTableData}
                            changeHistoryRangeHandler={changeHistoryRangeHandler}
                            mode={NTableMode}
                            restoreCrimeType={restoreCrimeType}
                        />
                    </Grid>
                </Grid>
            </div>;
        }
        else if(mode === 'crime'){
            return <Grid
                container
                direction="column"
                justify="space-around"
                alignItems="center"
                className={classes.container}>
                <CrimeChart chartData={crimeChartData}/>
                <CCrimeTable
                    rankTableData={crimeCTableData}
                    changeHistoryRangeHandler={changeHistoryRangeHandler}
                    changePartitionHandler={changePartitionHandler}
                    mode={CTableMode}
                    restoreCrimeType={restoreCrimeType}
                    restoreNeighborURLName={restoreNeighborURLName}
                    dateTitle={dateTitle}
                />
            </Grid>
        }
        else if(mode === 'aqi'){
            return <Grid
                container
                direction="row"
                justify="space-around"
                alignItems="center"
                spacing={2}
                className={classes.container}
            >
                <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                        <Typography variant={'subtitle2'}
                                    align={'left'}
                                    className={classes.subTitle}
                                    classes={{root: classes.subTitleRoot}}>
                            Crime Rate Rank
                        </Typography>
                    </Grid>
                    <Grid item xs={1}/>
                    <Grid item xs={7}>
                        <Typography variant={'subtitle2'}
                                    align={'left'}
                                    className={classes.subTitle2}
                                    classes={{root: classes.subTitleRoot}}>
                            Last 12 Months of All Crimes
                        </Typography>
                    </Grid>
                </Grid>

                <Grid item xs={12} md={4}>
                    <AQIRanking/>
                </Grid>
                {/* separate column in desktop mode */}
                <Grid item md={1}/>
                <Grid item xs={12} md={7}>
                    <AQIRankChart/>
                </Grid>
                <Grid item xs={12}>
                    <AQIRankTable/>
                </Grid>
            </Grid>
        }
    }

    return(
        <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="flex-start"
            classes={{root: classes.contentContainer}}
        >
            <SubTitle mode={mode}/>
            {renderContent(mode)}
        </Grid>
    );
};

export default withStyles(styles, {withTheme: true})(TrackerContent);