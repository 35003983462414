import PubSub from "pubsub-js";
import {
    TO_SUBSCRIPTION,
} from "../constants";
import React, {Component} from "react";
import {withStyles} from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import {Typography} from "@material-ui/core";
import Button from "@material-ui/core/Button";


const styles = theme =>({
    neighborCardContainer:{
        width: '346px',
        height: '346px',
    },
    neighborImageContainer:{
        width: '346px',
        height: '188px',
        borderRadius: '10px 10px 0px 0px'
    },
    neighborImage:{
        width: '346px',
        height: '188px',
    },
    neighborCard:{
        backgroundColor: '#F8F8F8',
        width: '100%',
        borderRadius: '0px 0px 10px 10px'
    },
    neighborName:{
        fontFamily: 'Open Sans',
        fontWeight: 600,
        fontSize: '24px',
        lineHeight: '33px',
        color: '#000',
        margin: '27px 28px 10px 28px'
    },
    neighborSubscriptionDate:{
        fontFamily: 'IBMPlex',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '16px',
        color: '#767676',
        margin: '10px 28px 10px 28px'
    },
    neighborCardButtonContainer:{
        padding: '10px 28px 18px 28px'
    },
    neighborCardButton:{
        backgroundColor: '#C4C4C4',
        borderRadius: '10px',
        '&:hover':{
            backgroundColor: '#4fc3f7',
            boxShadow: 'none',
        },
        boxShadow: 'none',
    },
    neighborCardButtonLabel:{
        fontFamily: 'Open Sans',
        fontSize: '16px',
        fontWeight: '600',
        color: '#fff',
        letterSpacing: '0.9px',
        textTransform: 'none',
        lineHeight: '22px',
        margin: '0 10px 0 10px'
    },
});

class SSubscribedNeighbor extends Component{

    shareHandler = ()=>{
        console.log('share...')
    };

    unsubscribeNeighborHandler = (neighborId)=>{
        PubSub.publish(TO_SUBSCRIPTION, {action: 'unsubscribe',...neighborId})
    };

    render(){
        const {classes, neighborName, neighborId}  = this.props;
        const neighborImgUrl = `https://neighborhood-image.s3-us-west-1.amazonaws.com/${neighborId}/${neighborId}1.jpg`;
        return(
            <Grid container
                  direction="column"
                  justify="flex-start"
                  alignItems="center"
                  classes={{root: classes.neighborCardContainer}}>
                <Grid item className={classes.neighborImageContainer}>
                    <img src={neighborImgUrl}
                         alt={''}
                         className={classes.neighborImage}/>
                </Grid>

                <Grid item classes={{root: classes.neighborCard}} >
                    <Typography className={classes.neighborName}>{neighborName}</Typography>
                    <Typography className={classes.neighborSubscriptionDate}>
                        {"Last delivered on March 10, 2020"}</Typography>
                    <Grid
                        container
                        direction="row"
                        justify="space-between"
                        alignItems="center"
                        classes={{root: classes.neighborCardButtonContainer}}
                    >
                        <Grid item>
                            <Button variant="contained" classes={{root:classes.neighborCardButton,
                                label: classes.neighborCardButtonLabel}}
                                    onClick = {this.shareHandler}
                            >
                                Share
                            </Button>
                        </Grid>

                        <Grid item>
                            <Button variant="contained"
                                    classes={{root:classes.neighborCardButton, label: classes.neighborCardButtonLabel}}
                                    onClick = {()=>this.unsubscribeNeighborHandler({neighborId})}
                            >
                                Unsubscribe
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
        )
    }
}


export default withStyles(styles, {withTheme: true})(SSubscribedNeighbor);