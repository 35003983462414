//AirQuality Map Legend implementation
import React from "react";
import StopRoundedIcon from "@material-ui/icons/StopRounded";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
// import { AIR_QUALITY_MAP_LEGEND_COLORS } from "../../config/settings";

const useStyles = makeStyles(() => ({
  legendContainer: {
    position: "absolute",
    width: "193px",
    height: "233px",
    left: "388px",
    bottom: "50px",
    background: "#FFFFFF",
    borderRadius: "10px",
  },
  heading: {
    fontFamily: "Roboto Mono",
    fontWeight: "600",
    fontSize: "13px",
    lineHeight: "16px",
    color: "#000000",
    margin: "10px",
  },
  legendTypeContainer: {
    wordWrap: "normal",
  },
  legendType: {
    wordWrap: "normal",
    fontFamily: "Roboto Mono",
    fontWeight: "500",
    fontSize: "10px",
    lineHeight: "13px",
    color: "#4F4F4F",
  },
}));

const AQMapLegend = (props) => {
  const classes = useStyles();
  const heading = props.title;
  const legendTypes = [
    "Good",
    "Moderate",
    "Unhealthy for sensitive",
    "Very Unhealthy",
    "Stay Inside",
  ];
  const legendVal = ["(0-50)", "(51-100)", "(101-150)", "(151-200)", "(200+)"];
  const legendColor = props.colors;
  return (
    <div className={classes.legendContainer}>
      <div className={classes.heading}>{heading}</div>
      {legendTypes.map((legend, index) => (
        <div>
          <Grid container className={classes.legendTypeContainer}>
            <Grid item>
              <StopRoundedIcon
                style={{ color: legendColor[index][1], fontSize: "35px" }}
              />
            </Grid>
            <Grid item className={classes.legendType}>
              <div>{legend}</div>
              <div>{legendVal[index]}</div>
            </Grid>
          </Grid>
        </div>
      ))}
    </div>
  );
};

export default AQMapLegend;
