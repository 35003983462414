import React from 'react';
import Grid from '@material-ui/core/Grid';
import {withStyles} from "@material-ui/core/styles/index";
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Switch from '@material-ui/core/Switch';

const styles = theme => ({
    tableContainer:{
        overflow: 'auto',
        position: 'relative',
        width: '100%'
    },
    cellContainer:{
        fontFamily: 'helvetica',
        fontSize: '14px',
        fontWeight: '400',
        lineHeight: '1.5',
        padding: '4px 5px 4px 5px',
        letterSpacing: '1px',
        textAlign: 'center',
        [theme.breakpoints.up('md')]:{
            minWidth: '100px',
            padding: '4px 30px 4px 30px'
        }
    },
    cellTitleContainer:{
        fontFamily: 'helvetica',
        fontSize: '14px',
        fontWeight: '400',
        lineHeight: '1.2',
        padding: '4px 5px 4px 5px',
        letterSpacing: '1.2px',
        color: 'rgba(0, 0, 0, 0.87)',
        textAlign: 'center',
        [theme.breakpoints.up('md')]:{
            minWidth: '100px',
            padding: '4px 30px 4px 30px'
        },

    },
    rankingColumn:{
        width: '50px',
        paddingLeft: '0',
    },
    tableTitleRoot:{
        fontFamily: 'Rambla',
        fontSize: '16px',
        fontWeight: '700',
        color: '#383838',
        letterSpacing: '1.2px'
    },
    selectorGroupContainer:{
        width: 'auto',
    },
    selectorLabel:{
        fontFamily: 'Rambla',
        fontSize: '14px',
        fontWeight: '400',
        letterSpacing: '0.8px',
        color: 'rgba(0, 0, 0, 0.54)'
    },
    selectorRightLabel:{
        marginLeft: '-5px',
    },
    selectorSwitch:{
        '&$selectorChecked':{
            color: '#fff',
            '&+$selectorBar':{
                backgroundColor: 'rgba(0, 0, 0, 0.5)'
            }
        }
    },
    selectorChecked:{
        fontWeight: '700'
    },
    selectorBar:{
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        opacity: 1
    }
});

const NCrimeTable = (props) => {

    const {classes, rankTableData, changeHistoryRangeHandler, mode, restoreCrimeType} = props;

    function restoreCrimeUrl(crimeType) {
        try{
            return crimeType.trim().split(' ').join('%20');
        }
        catch(e){
            console.error("invalid url!")
        }
    }

    const title = 'Crime Ranking';
    const crimeUrl = window.location.protocol+'//'+window.location.host;

    return(
        <Grid
            container
            classes={{container:classes.tableContainer}}
        >
            <Grid item xs={12}>
                <Typography
                    variant={'subtitle2'}
                    align={'left'}
                    classes={{root:classes.tableTitleRoot}}>
                    {title}
                </Typography>
            </Grid>
            {/*switch row for neighborhood table*/}
            <Grid
                container
                direction="row"
                justify="flex-end"
                alignItems="center"
            >

                <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justify="flex-start"
                    classes={{container: classes.selectorGroupContainer}}
                >
                    <Typography variant={'subtitle1'}
                                classes={{subtitle1:`${classes.selectorLeftLabel} ${classes.selectorLabel}
                                ${mode ==='monthly'?classes.selectorChecked:null}`}}>
                        Last Month </Typography>
                    <Switch classes={{
                        root:classes.selectorSwitch,
                        switchBase:classes.selectorSwitch,
                        checked: classes.selectorChecked
                    }} onChange={changeHistoryRangeHandler}/>
                    <Typography variant={'subtitle1'}
                                classes={{subtitle1:`${classes.selectorRightLabel} ${classes.selectorLabel}
                                ${mode ==='yearly'?classes.selectorChecked:null}`}}>
                        Last Year</Typography>
                </Grid>

            </Grid>
            <div className={classes.tableContainer}>
                {/*{this.state.updating&&<Loader noMask={true}/>}*/}
                {rankTableData &&
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    align="center"
                                    className={`${classes.cellTitleContainer} ${classes.rankingColumn}`}> Rank
                                </TableCell>
                                <TableCell align="center" className={classes.cellTitleContainer}>Crime Type</TableCell>
                                <TableCell align="center" className={classes.cellTitleContainer}>Crime Count</TableCell>
                                <TableCell align="center"
                                           className={classes.cellTitleContainer}>
                                    Change in Rank
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rankTableData.map((row, idx) => (
                                <TableRow key={idx}>
                                    <TableCell
                                        className={`${classes.cellTitleContainer} ${classes.rankingColumn}`}
                                        component="th" scope="row">
                                        {row.ranking}
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                        className={classes.cellContainer}>
                                        <a href={`${crimeUrl}/crime/${restoreCrimeUrl(row.small_bundle_name)}`}
                                           style={{textDecoration: 'none', color: '#007bff'}}
                                        >
                                            {restoreCrimeType(row.small_bundle_name)}
                                        </a></TableCell>
                                    <TableCell
                                        align="center"
                                        className={classes.cellContainer}>
                                        {row.total_crimes}
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                        className={classes.cellContainer}>
                                        {(row.diff<0?"":"+")+row.diff}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                }
            </div>
        </Grid>
    );
};


export default withStyles(styles, {withTheme: true})(NCrimeTable)