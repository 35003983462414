import React from 'react';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';

const styles = theme => ({
    introTitle: {
        fontSize: '46px',
        fontFamily: 'Open Sans',
        fontWeight: 700,
        lineHeight: '63px',
        color: '#000',
        margin: '10px'
    },
    introBody: {
        fontFamily: 'Open Sans',
        fontStyle: 'italic',
        fontWeight: 400,
        fontSize: '20px',
        lineHeight: '27px',
        margin: '10px'
    }

});



function SIntroduction(props){

    const {classes} = props;
    return <div>
        <Typography className={classes.introTitle}>Subscribe to our neighborhood newsletters</Typography>
        <Typography className={classes.introBody}>Our newsletters are blah blah blah</Typography>
    </div>
}

export default withStyles(styles, {withTheme: true})(SIntroduction);



