import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { withStyles } from "@material-ui/core";
import ReactGA from "react-ga";
import MapGL, {
  FlyToInterpolator,
  GeolocateControl,
  NavigationControl,
  WebMercatorViewport,
} from "react-map-gl";
import useMapViewport from "../api-hooks/hooks/useMapViewport";
import Hidden from "@material-ui/core/Hidden";
import useMapHoveredData from "../api-hooks/hooks/useMapHoveredData";
import MapHoverPopup from "./MapHoverPopup";
import bbox from "@turf/bbox";
import Log from "../../utils/Log";

import booleanPointInPolygon from "@turf/boolean-point-in-polygon";
import { multiPolygon, point, polygon } from "@turf/helpers";
import * as d3 from "d3-ease";

const styles = (theme) => ({
  map: {
    left: "0px",
    position: "relative",
    width: "100%",
    height: "100%",
  },

  nav: {
    position: "absolute",
    bottom: 25,
    left: 0,
    margin: 10,
  },

  geolocate: {
    position: "absolute",
    bottom: 120,
    left: 0,
    margin: 10,
  },
});

// const bounds = [
//     [-119.5, 32.0], // Southwest coordinates
//     [-117.0, 35.0]  // Northeast coordinates
// ];

const Map = (props) => {
  const {
    classes,
    mapStyle,
    onSelectedFeature,
    interactiveLayerIds,
    partitionType,
    searchAddress,
  } = props;

  // Map load state
  const [isLoaded, setIsLoaded] = useState(false);
  const [selectedFeature, setSelectedFeature] = useState(null);

  // Map reference to edit style
  const mapRef = useRef(null);
  const offset = 0.07;

  // Map viewport
  const [viewport, setViewport] = useMapViewport(
    34.022687,
    -118.285938,
    9,
    8,
    20,
    0,
    0,
    window.innerWidth,
    window.innerHeight
  );

  // Map hovered data when hovering on features
  const [hoveredData, setHoveredData] = useMapHoveredData();

  //Commenting the marker implementation, as discussed on removing location pin
  const setMarker = (center = null) => {
    // let map = mapRef.current.getMap();
    // if (!map.hasImage('marker')) {
    //     map.loadImage('/images/marker.png', function (error, image) {
    //         map.addImage('marker', image);
    //     });
    // }
    // if (map.getSource('marker-point')) {
    //     map.removeSource('marker-point');
    // }
    // if (map.getLayer('marker-point')) {
    //     map.removeLayer('marker-point');
    // }
    // if (center === null) {
    //     return;
    // }
    // const markerLayer = {
    //     id: 'marker-point',
    //     type: "symbol",
    //     source: {
    //         type: "geojson",
    //         data: {
    //             "type": "FeatureCollection",
    //             "features": [
    //                 {
    //                     "type": "Feature",
    //                     "geometry": {
    //                         "type": "Point",
    //                         "coordinates": center
    //                     }
    //                 }
    //             ]
    //         }
    //     },
    //     "layout": {
    //         "icon-image": "marker",
    //         "icon-anchor": "bottom"
    //     }
    // };
    // if (map.getSource('marker-point')) {
    //     map.removeSource('marker-point');
    //     map.addLayer(markerLayer);
    // } else {
    //     map.addLayer(markerLayer);
    // }
  };

  // Map highlighted feature when clicking on feature
  const [highlightedFeature, setHighlightedFeature] = useState(null);
  useEffect(() => {
    if (isLoaded) {
      const map = mapRef.current.getMap();
      if (highlightedFeature) {
        Log.info(
          `Highlight feature: ${highlightedFeature.properties["id"]}`,
          Map.name
        );
        map.setFilter("highlight-data", [
          "==",
          "id",
          highlightedFeature.properties["id"],
        ]);
      } else {
        map.setFilter("highlight-data", ["==", "id", ""]);
      }
    }
  }, [highlightedFeature, isLoaded, mapStyle]);

  useEffect(() => {
    if (selectedFeature) {
      const map = mapRef.current.getMap();
      // TODO: get features without having to stringify somehow
      const data = map.getSource("partition")._data.features;
      let features = data.filter((ele) => {
        return ele.properties.name === selectedFeature.properties.name;
      });
      if (features.length > 0) {
        let feature = features[0];
        for (let key in feature.properties) {
          if (
            feature.properties.hasOwnProperty(key) &&
            typeof feature.properties[key] === "object"
          ) {
            feature.properties[key] = JSON.stringify(feature.properties[key]);
          }
        }
        onSelectedFeature(feature);
      }
    }
  }, [mapStyle, selectedFeature, onSelectedFeature]);

  useEffect(() => {
    if (searchAddress) {
      const map = mapRef.current.getMap();
      // TODO: get features without having to stringify somehow
      const data = map.getSource("partition")._data.features;
      let features = data.filter((ele) => {
        return booleanPointInPolygon(
          point(searchAddress.center),
          ele.geometry.type === "Polygon"
            ? polygon(ele.geometry.coordinates)
            : multiPolygon(ele.geometry.coordinates)
        );
      });
      if (features.length > 0) {
        let feature = features[0];
        for (let key in feature.properties) {
          if (
            feature.properties.hasOwnProperty(key) &&
            typeof feature.properties[key] === "object"
          ) {
            feature.properties[key] = JSON.stringify(feature.properties[key]);
          }
        }
        setMarker(searchAddress.center);
        fitFeature(feature, viewport);
        onSelectedFeature(feature);
        setSelectedFeature(feature);
      }
    }
    // TODO better way to handle deps, here adding fitFeature, viewport is problematic
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchAddress, onSelectedFeature]);

  // Handle hover event
  const handleOnHover = (event) => {
    const {
      features,
      lngLat: [lng, lat],
    } = event;

    const hoveredFeature =
      features &&
      features.find((f) => interactiveLayerIds.includes(f.layer.id));
    setHoveredData({ hoveredFeature, lat, lng });
  };

  const fitFeature = (feature, v) => {
    if (feature) {
      Log.info(`Feature clicked: ${feature.properties["id"]}`, Map.name);

      // Highlight this feature
      setHighlightedFeature(feature);

      // calculate the bounding box of the feature
      const [minLng, minLat, maxLng, maxLat] = bbox(feature);
      // construct a viewport instance from the current state
      const viewport = new WebMercatorViewport(v);
      const { longitude, latitude, zoom } = viewport.fitBounds(
        [
          [minLng + offset, minLat],
          [maxLng + offset, maxLat],
        ],
        {
          padding: 100,
          offset: [0, 0],
          maxZoom: 10,
        }
      );

      setViewport({
        ...v, // Need the maxZoom
        ...viewport,
        longitude,
        latitude,
        zoom,
        transitionInterpolator: new FlyToInterpolator(),
        transitionDuration: 500,
        transitionEasing: d3.easeCubic,
      });
    }
  };

  // Handle click event
  const handleOnClick = (event, v) => {
    const { features } = event;
    const feature =
      features &&
      features.find((f) => interactiveLayerIds.includes(f.layer.id));

    if (feature) {
      ReactGA.event({
        category: "Map Click",
        action: "Click on one of features",
        label: feature.properties.label,
      });
    }

    fitFeature(feature, v);
    onSelectedFeature(feature);
    setSelectedFeature(feature);
  };

  // Handle load event
  const handleOnLoad = () => {
    setIsLoaded(true);
    Log.info("Map loaded", Map.name);
  };

  return (
    <div className={classes.map}>
      <MapGL
        {...viewport}
        ref={mapRef}
        mapStyle={mapStyle}
        onViewportChange={(v) => setViewport(v)}
        // mapOptions={{maxBounds: bounds}} // Sets bounds as max TODO does not play well with other gestures
        dragToRotate={false}
        mapboxApiAccessToken={process.env.REACT_APP_MAP_BOX_ACCESS_TOKEN}
        onHover={handleOnHover}
        onClick={(event) => handleOnClick(event, viewport)}
        onLoad={handleOnLoad}
      >
        <MapHoverPopup
          hoveredData={hoveredData}
          partitionType={partitionType}
        />

        <Hidden smDown implementation="css">
          <div className={classes.nav}>
            <NavigationControl onViewportChange={(v) => setViewport(v)} />
          </div>

          <GeolocateControl
            className={classes.geolocate}
            positionOptions={{ enableHighAccuracy: true }}
            trackUserLocation={true}
          />
        </Hidden>
      </MapGL>
    </div>
  );
};

Map.propTypes = {
  classes: PropTypes.object.isRequired,
  onSelectedFeature: PropTypes.func.isRequired,
  interactiveLayerIds: PropTypes.array.isRequired,
  partitionType: PropTypes.string.isRequired,
  searchAddress: PropTypes.object,
};

export default withStyles(styles)(Map);
