import React from 'react';
import Grid from '@material-ui/core/Grid';
import {withStyles} from "@material-ui/core/styles/index";
import HighchartsReact from 'highcharts-react-official'
import Typography from '@material-ui/core/Typography';
import Highcharts from "highcharts";

const styles = theme => ({
    container:{
        margin: '20px 0px',
        [theme.breakpoints.up('md')]:{
            margin: '2rem 0rem',
        }
    },
    subTitle:{
        fontFamily: 'Rambla',
        fontSize: '16px',
        fontWeight: '700',
        color: '#383838',
        letterSpacing: '1.2px',
        [theme.breakpoints.up('md')]:{
            display: 'none',
        }
    }
});

const CrimeChart = (props) => {

    const {chartData, classes, crimeType} = props;
    const trackerTitle = `Last 12 Months of ${crimeType}`;

    let data = chartData?chartData:{xAxis: [], yAxis: []};
    const chartOptions = {
        chart: {
            type: 'column',
            scrollablePlotArea: {
                minWidth: 200,
                scrollPositionX: 0
            }
        },
        title: {
            text: ''
        },
        xAxis: {
            categories: data.xAxis,
            scrollbar: {
                enabled: true
            }
        },
        yAxis: {
            min: 0,
            title: {
                text: data.yTitle
            }
        },
        plotOptions: {
            column: {
                pointPadding: 0.1,
                groupPadding: 0.1
            }
        },
        series: [{
            name: data.xTitle,
            data: data.yAxis,
            colorByPoint: true,
            colors: ['rgba(255,151,0,0.38)', 'rgba(255,151,0,0.44)', 'rgba(255,151,0,0.5)', 'rgba(255,151,0,0.56)',
                'rgba(255,151,0,0.62)','rgba(255,151,0,0.68)', 'rgba(255,151,0,0.74)', 'rgba(255,151,0,0.8)',
                'rgba(255,151,0,0.86)', 'rgba(255,151,0,0.92)','rgba(255,151,0,0.96)','rgba(255,151,0,1)'],
        }],
        showInLegend: true
    };

    return(
        <Grid container spacing={0} className={classes.container} alignItems={'flex-start'}>
            <Grid item xs={12}>
                <Typography variant={'subtitle2'} align={'left'} className={classes.subTitle}>
                    {trackerTitle}
                </Typography>
            </Grid>

            <Grid item xs={12} align={'left'}>
                <HighchartsReact highcharts={Highcharts} options={chartOptions}/>
            </Grid>
        </Grid>
    );
}


export default withStyles(styles, {withTheme: true})(CrimeChart)