import React from "react";
import PropTypes from "prop-types";
import {FormControl, FormLabel, Hidden, ListItemText, MenuItem, OutlinedInput, Select} from '@material-ui/core';
import CrimesIcon from '../icons/Crimes';
import useMapFilterStyles from "./MapFilter.style";

const BigBundlesFilter = (props) => {
    const classes = useMapFilterStyles();
    const {bigBundles, selected, onChange} = props;

    return (
        <FormControl variant="outlined"
                     className={classes.formControl}
                     fullWidth={true}>

            <Hidden smDown implementation="css">
                <FormLabel
                    className={classes.formLabel}
                    component="legend"
                    htmlFor="select-big-bundle-type">
                    Crime category
                </FormLabel>
            </Hidden>

            <Select
                value={selected}
                onChange={onChange}
                displayEmpty={true}
                IconComponent={CrimesIcon}
                input={
                    <OutlinedInput
                        name="big-bundle-type"
                        labelWidth={0}
                        id="select-big-bundle-type"
                        classes={{input: classes.input}}
                    />
                }
            >

                <MenuItem key="All" value="">
                    <ListItemText classes={{primary: classes.listItem}}
                                  primary="All"/>
                </MenuItem>
                {
                    bigBundles.map(bigBundleFilter => (
                        <MenuItem key={bigBundleFilter.key} value={bigBundleFilter.value}>
                            <ListItemText classes={{primary: classes.listItem}}
                                          primary={bigBundleFilter.key}/>
                        </MenuItem>
                    ))
                }
            </Select>
        </FormControl>
    );
};

BigBundlesFilter.propTypes = {
    bigBundles: PropTypes.arrayOf(PropTypes.object).isRequired,
    selected: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
};

export default BigBundlesFilter;