import React, {useEffect, useState} from 'react';
import {withStyles} from "@material-ui/core/styles/index";
import Typography from '@material-ui/core/Typography';
import Autocomplete from 'react-autocomplete';
import {
    IMAGE_PATH,
    THEME_COLOR,
} from "../constants";

const styles = theme => ({
    wrapperStyle: {
        position: 'relative',
        display: 'inline-block',
        margin: '1rem',
        [theme.breakpoints.up('md')]: {
            display: 'block',
        }
    },
    menuStyle: {
        position: 'absolute',
        width: '90%',
        top: '25px',
        left: '0px',
        zIndex: '1',
        backgroundColor: '#fff'
    },
    searchBarContainer: {
        position: 'relative',
        // margin: '0.5rem 0rem',
        height: '30px',
        [theme.breakpoints.up('md')]: {
            margin: '0',
            height: '100px',
        }
    },
    searchBarTitle: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex'
        }
    },
    searchBarTitleRoot: {
        padding: '14px 0',
        fontFamily: 'Rambla',
        fontSize: '16px',
        fontWeight: '700',
        color: '#383838',
        letterSpacing: '1.2px'
    },

    locationLogo: {
        // display: 'flex',
        position: 'relative',

        width: '20px',
        marginRight: '10px',
        [theme.breakpoints.up('md')]: {
            display: 'none'
        }
    },
    inputContainer: {
        width: '90%',
        marginLeft: '30px',
        [theme.breakpoints.up('md')]: {
            margin: '0'
        }
    },
    signUpButton: {
        [theme.breakpoints.up('md')]: {
            display: 'none'
        }
    },
    signUpButtonRoot: {
        backgroundColor: THEME_COLOR,
        marginLeft: '20px',
        fontFamily: 'Rambla',
        fontSize: '16px',
        fontWeight: '700',
        color: '#fff',
        letterSpacing: '0.9px',
        borderRadius: '10px',
        borderColor: '#383838',
        borderWidth: '1px',
        textTransform: 'none'
    },
    logoMobileContainer: {
        display: 'block',
        position: 'absolute',
        top: '5px'
    }
});

const SearchBar = (props) => {

    const {searchBarInfo, classes, mode, searchBarHandler, nameToIdMap} = props;

    const [prefix, setPrefix] = useState('');
    const [itemList, setItemList] = useState([]);
    const [searchBarTitle, setSearchBarTitle] = useState('');
    useEffect(()=>{
        if (searchBarInfo) {
            let neighborList;
            neighborList = searchBarInfo.findAll(prefix);
            // limit the dropdown menu length
            neighborList = neighborList.slice(0, 5);
            setItemList(neighborList)
        }
    }, [searchBarInfo, prefix]);

    useEffect(()=>{
        switch (mode) {
            case 'neighborhood':
                setSearchBarTitle('Search for a Neighborhood');
                break;
            case 'crime':
                setSearchBarTitle('Search for a Neighborhood');
                break;
            default:
                setSearchBarTitle('Search for Something');
        }
    }, [mode]);

    // style of the search input box
    // used as the inputProps of the AutoComplete component
    const inputProps = {
        style: {
            display: 'inline-block',
            width: '100%',
            height: '26px',
            color: '#9a9a9a',
            fontSize: '16px',
            fontWeight: '400',
            fontFamily: 'helvetica',
            letterSpacing: '0.9px',
            backgroundColor: '#f8f8f8',
            backgroundClip: 'padding-box',
            border: '1px solid #eaeaea',
            WebkitTransition: 'rgba(0, 123, 255, 0.25) .15s ease-in-out,box-shadow .15s ease-in-out',
            transition: 'rgba(0, 123, 255, 0.25) .15s ease-in-out,box-shadow .15s ease-in-out',
            borderRadius: '10px',
            textIndent: '15px',
        },
        placeholder: 'Search',
        onFocus() {
            let inputBar = document.getElementById('search').getElementsByTagName('input')[0];
            inputBar.style.boxShadow = '0 0 0 0.2rem rgba(0,123,255,.25)';
            inputBar.style.outline = 0;
        },
        onBlur() {
            let inputBar = document.getElementById('search').getElementsByTagName('input')[0];
            inputBar.style.boxShadow = '';
        }
    };

    let key = 0;     // rendered item key
    return (
        <div id={'search'} className={classes.searchBarContainer}>
            <Typography variant={'subtitle2'} className={classes.searchBarTitle}
                        classes={{root: classes.searchBarTitleRoot}}>
                {searchBarTitle}
            </Typography>
            <span className={classes.logoMobileContainer}>
                <img className={classes.locationLogo} src={IMAGE_PATH + '/location_logo.svg'} alt={'location'}/>
            </span>
            <div className={classes.inputContainer}>
                <Autocomplete
                    wrapperStyle={{styles: classes.wrapperStyle}}
                    menuStyle={{styles: classes.menuStyle}}
                    inputProps={inputProps}
                    getItemValue={item => item}
                    items={
                        itemList
                    }
                    renderItem={(neighbor, isHighlighted) => {
                        const itemStyle = {
                            background: isHighlighted ? 'rgb(255, 151, 0)' : 'white',
                            textAlign: 'left',
                            fontSize: '16px',
                            fontWeight: '400',
                            fontFamily: 'helvetica',
                            width: '100%',
                            zIndex: '99',
                            letterSpacing: '0.9px',
                            lineHeight: '30px',
                            textIndent: '10px',
                        };
                        return (
                            <div key={key++} style={itemStyle}>{neighbor}</div>
                        );
                    }
                    }
                    value={prefix}
                    onChange={(e) => {
                        setPrefix(e.target.value);
                    }}
                    onSelect={value => {
                        setPrefix(value);
                        searchBarHandler(nameToIdMap[value])
                    }}
                />
            </div>
        </div>
    );
};

export default (withStyles(styles, {withTheme: true})(SearchBar))