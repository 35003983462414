export function reportData(score){

    let colorCode = "#ffffff";
    if(score<=12) colorCode = "#34DD6D";
    else if(score<=35.4) colorCode = "#FBFB16";
    else if(score<=55.4) colorCode = "#F1A047";
    else if(score<=150) colorCode = "#D84254";
    else colorCode = "#8F203F";
    
    return colorCode;
}